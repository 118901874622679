import * as React from "react";

export const QR: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="M17.3,14.8h-2.2c-0.2,0-0.3,0.1-0.3,0.3v2.2c0,0.2,0.1,0.3,0.3,0.3h2.2c0.2,0,0.3-0.1,0.3-0.3v-2.2
	C17.6,14.9,17.5,14.8,17.3,14.8z M14.5,12.6h-1.7c-0.2,0-0.3,0.1-0.3,0.3v1.7c0,0.2,0.1,0.3,0.3,0.3h1.7c0.2,0,0.3-0.1,0.3-0.3v-1.7
	C14.8,12.7,14.7,12.6,14.5,12.6z M19.5,17.6h-1.7c-0.2,0-0.3,0.1-0.3,0.3v1.7c0,0.2,0.1,0.3,0.3,0.3h1.7c0.2,0,0.3-0.1,0.3-0.3v-1.7
	C19.8,17.7,19.7,17.6,19.5,17.6z M19.5,12.6h-1.1c-0.2,0-0.3,0.1-0.3,0.3V14c0,0.2,0.1,0.3,0.3,0.3h1.1c0.2,0,0.3-0.1,0.3-0.3v-1.1
	C19.8,12.7,19.7,12.6,19.5,12.6z M14,18.1h-1.1c-0.2,0-0.3,0.1-0.3,0.3v1.1c0,0.2,0.1,0.3,0.3,0.3H14c0.2,0,0.3-0.1,0.3-0.3v-1.1
	C14.2,18.3,14.1,18.1,14,18.1z M18.7,4.2h-5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v5c0,0.3,0.1,0.6,0.3,0.8
	c0.2,0.2,0.5,0.3,0.8,0.3h5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-5c0-0.3-0.1-0.6-0.3-0.8C19.3,4.3,19,4.2,18.7,4.2z
	 M17.6,8.9c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-2.2c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2V6.7
	c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h2.2c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2V8.9z M10.3,4.2h-5
	C5,4.2,4.7,4.3,4.5,4.5C4.3,4.7,4.2,5,4.2,5.3v5c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3h5c0.3,0,0.6-0.1,0.8-0.3
	c0.2-0.2,0.3-0.5,0.3-0.8v-5c0-0.3-0.1-0.6-0.3-0.8C10.9,4.3,10.6,4.2,10.3,4.2z M9.2,8.9c0,0.1,0,0.1-0.1,0.2
	C9.1,9.2,9,9.2,8.9,9.2H6.7c-0.1,0-0.1,0-0.2-0.1C6.4,9.1,6.4,9,6.4,8.9V6.7c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h2.2
	c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2V8.9z M10.3,12.6h-5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v5
	c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3h5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-5c0-0.3-0.1-0.6-0.3-0.8
	C10.9,12.7,10.6,12.6,10.3,12.6z M9.2,17.3c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H6.7c-0.1,0-0.1,0-0.2-0.1
	c-0.1-0.1-0.1-0.1-0.1-0.2v-2.2c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h2.2c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2V17.3
	z"
    />
  </svg>
);
