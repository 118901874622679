import { styled } from "@mui/system";

import { Breakpoints, media } from "../../../../../../css/breakpoints";

export const StyledCitySelectionFieldsSection = styled("div")`
  display: flex;
  gap: initial;
  width: 100%;
  max-width: 66%;

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    gap: 34px 16px;
    flex-wrap: wrap;
    max-width: initial;
  }
`;

export const StyledSwapCityBtn = styled("button")<{ disabled?: boolean }>`
  border-radius: 50%;
  color: ${({ disabled }) => (disabled ? "gray" : "#7F90B1")};
  margin: auto 4px;

  > * {
    width: 18px;
    height: 18px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`;
