import { Controller, useFormContext } from "react-hook-form";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { SelectBase } from "./SelectBase";

interface SelectBaseProps {
  style?: CSSProperties;
  className?: string;
  options: any;
  name: string;
  label: string;
  value?: string;
  noOptionsText?: string;
  disabled?: boolean;
  disableClearable?: boolean;
  blurOnSelect?: boolean;
  hideAutocomplete?: boolean;
  register?: any;
  filterOptions?(options: any, state: any): any;
  onChange(value: any): void;
  onClose?(event: React.SyntheticEvent, reason: string): void;
  onBlur?(value: any): void;
  onInputChange?(
    event: React.SyntheticEvent,
    value: string,
    reason: string,
  ): void;
}

export const SelectBaseController = ({
  style,
  className,
  options,
  name,
  label,
  value,
  noOptionsText,
  disabled,
  disableClearable,
  blurOnSelect,
  hideAutocomplete,
  register,
  filterOptions,
  onChange,
  onBlur,
  onClose,
  onInputChange,
}: SelectBaseProps) => {
  // console.log("=SelectBase=", label, value);
  const { control, getValues } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={(props) => (
        <SelectBase
          style={style}
          className={className}
          {...props}
          // value={innerValue}
          value={value}
          label={label}
          options={options}
          disabled={disabled}
          disableClearable={disableClearable}
          blurOnSelect={blurOnSelect}
          hideAutocomplete={hideAutocomplete}
          noOptionsText={noOptionsText}
          controllerProps={props}
          // open
          onInputChange={onInputChange}
          onClose={onClose}
          onBlur={onBlur}
          filterOptions={filterOptions}
          onChange={onChange}
        />
      )}
    />
  );
};
