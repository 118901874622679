import { Container } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { BasePage } from "../../components/BasePage";
import ManInCircleImage from "../../assets/images/man-in-circle.svg";
import { UiPageBackground } from "./components/UiPageBackground";
import { OurContacts } from "./components/OurContacts";

import styles from "./Contacts.scss";
import { Form } from "./components/Form";
import { Breakpoints, media } from "../../css/breakpoints";

const StyledTitleBlock = styled("div")(
  () => `
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 86px;
`,
);

const StyledAvatar = styled("img")(
  () => `
  width: 150px;
  margin-bottom: 20px;
`,
);

const StyledTitle = styled("div")`
  font-weight: 600;
  font-size: 60px;
  line-height: 120%;
  text-align: center;
  color: #284492;
  margin-bottom: 190px;

  @media ${media(Breakpoints.Mobile)} {
    font-size: 28px;
  }
`;

const Contacts = () => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");

  return (
    <BasePage>
      <Container style={{ overflow: "hidden" }}>
        <StyledTitleBlock>
          <StyledAvatar src={ManInCircleImage} alt="" />
          <StyledTitle>
            Worldwide <br />
            Door to Door Delivery!
          </StyledTitle>
        </StyledTitleBlock>

        <OurContacts />

        <section className={styles.form}>
          <Form />
        </section>

        <UiPageBackground />
      </Container>
    </BasePage>
  );
};

export default Contacts;
