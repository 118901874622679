export const refundPolicyContentPage = [
  {
    id: "#section0",
    title: "General",
    content: `As a company who seeks to be as transparent with our customers we provide you this clear guide of the circumstances in which we will or will not refund your money due to cancellation of the shipment. Thus said, our support team will be available for any additional questions.
      <br />
      <br />
      As a rule, all incurred costs would be attributed to the customer.
   `,
  },
  {
    id: "#section1",
    title: "International shipping",
    content: `There are cases in which a package is being stopped at customs and/or after the actual cost is given the customer decides to cancel the shipment and the package should be sent back to its pickup point. We will distinguish between customer accountability and Shipper accountability.`,
  },
  {
    id: "#section2",
    title: "Customer accountability",
    content: `
      <ol style="margin-left: 20px;">
        <li style="list-style: auto;">If the content of the package is illegal to send, the package will be sent back to its origin. All additional fees will be put on the customer. No refund will be made.</li>
        <li style="list-style: auto;">Misleading statement of the package content - If the content of the package is legal to send but the declaration of the content was not true, all additional fees will be put on the customer. No refund will be made.</li>
      </ol>
    `,
  },
  {
    id: "#section3",
    title: "Cancelation Policy",
    content: `
      A customer can cancel his shipment in different steps along the shipping process.
      <ol style="margin-left: 20px;">
        <li style="list-style: auto;">Before the package was picked up depending on the circumstances:</li>
          <ol style="margin-left: 20px;">
            <li style="list-style: lower-alpha;">the courier company didn’t show – a full refund will be made.</li>
            <li style="list-style: lower-alpha;">the customer asked to cancel – a 93% refund or charge of 15$ (the highest of the two) will be made.</li>
          </ol>
        <li style="list-style: auto;">After the package was picked up, and before it left the Origin country – depending on the additional costs, a refund will be made after deduction of all incurred costs+15% for handling the shipment.</li>
        <li style="list-style: auto;">After the package leaves its origin country – no refund will be made, additional costs will be attributed to the client.</li>
      </ol>
    `,
  },

  {
    id: "#section4",
    title: "Shipper accountability",
    content: `
      As the account holders we will reach to the curriers with concerns that are raised to us by customers. We will provide answers according to the curriers handling time- Approx. 10 working days.
      <br />
      <br />
      There are reasons in which the customer isn't accountable for the cancelation (for example; the courier didn't pick up the package, the destination point is unreachable and the costumer stated the address correctly) –each case will be considered by shippers' management team and after having the total cost of shipment (currier, taxes, etc.) a refund will be made.
   `,
  },
];
