import { FormCheckout } from "./components/Form/Form";
import { BaseLoading } from "../../components/BaseLoading";
import { useSelector } from "react-redux";
import { selectCheckoutLoading } from "./data/selectors";

interface WrapperCheckoutProps {
  shipmentId: string;
}

export const WrapperCheckout = ({ shipmentId }: WrapperCheckoutProps) => {
  const loading = useSelector(selectCheckoutLoading);

  return (
    <>{loading ? <BaseLoading /> : <FormCheckout shipmentId={shipmentId} />}</>
  );
};
