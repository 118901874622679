import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { styled } from "@mui/system";

import { HF } from "../../../../components";
import { BaseButton } from "../../../../components/BaseButton";
import { Name } from "../../../../components/BaseIcon";

import styles from "../../styles/Profile.scss";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../data/actions";

const StyledRoot = styled("form")(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`,
);

const StyledInputLabel = styled("label")(
  () => `
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #A8B6D2;
  margin-bottom: 20px;
`,
);

type Props = {
  onClose(): void;
};

const PasswordSidebar: React.FC<Props> = (props) => {
  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onBlur",
    // resolver: validationSchema,
    criteriaMode: "all",
  });
  const dispatch = useDispatch();

  const onSubmit = (formValues: any): void => {
    dispatch(updatePassword(formValues));
    props.onClose();
  };

  return (
    <FormProvider {...methods}>
      <StyledRoot onSubmit={methods.handleSubmit(onSubmit)}>
        <div>
          <BaseButton
            onClick={props.onClose}
            className={styles.btnBack}
            leftIcon={Name.LeftArrow}
            clear
          >
            Password
          </BaseButton>

          <StyledInputLabel>
            <span>Old Password</span>

            <HF.Input
              name="current_password"
              placeholder="Password"
              type="password"
            />
          </StyledInputLabel>

          <StyledInputLabel>
            <span>New Password</span>

            <HF.Input name="password" placeholder="Password" type="password" />
          </StyledInputLabel>

          <StyledInputLabel>
            <span>Confirm Password</span>

            <HF.Input
              name="password_confirmation"
              placeholder="Password"
              type="password"
            />
          </StyledInputLabel>
        </div>

        <BaseButton
          type="submit"
          // disabled={!isConfirmBtnDisabled()}
          fullWidth
        >
          Confirm
        </BaseButton>
      </StyledRoot>
    </FormProvider>
  );
};

export default PasswordSidebar;
