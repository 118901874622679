import { MessageProhibitedItemsTooltip } from "./MessageProhibitedItemsTooltip";
import styles from "./MessageProhibitedItems.scss";

interface MessageProhibitedItemsProps {
  className?: string;
  prohibitedItemsLink: string | undefined;
}
export const MessageProhibitedItems = ({
  className,
  prohibitedItemsLink,
}: MessageProhibitedItemsProps) => {
  return (
    <div className={className}>
      {/* {prohibitedItemsLink} */}
      <a
        className={styles.messageProhibitedItems}
        href={prohibitedItemsLink}
        target="_blank"
        rel="noreferrer"
      >
        List of prohibited items
      </a>

      <MessageProhibitedItemsTooltip />
    </div>
  );
};
