export const AppErrorPaymentMiddleware = () => {
  console.log("=AppErrorPaymentMiddleware=");

  globalThis.parent.postMessage(
    {
      key: "icredit_response",
      status: "error",
      icredit_response: "error",
    },
    "*",
  );

  return <></>;
};
