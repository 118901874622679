import { parcelTypes } from "../../../data/constants";

export const getConvertedFormValues = (methods: any) => {
  const shipment = methods.getValues();

  const result: any = {
    unit_of_measurement:
      shipment.unit_of_measurement.toString() === "false"
        ? "metric"
        : "imperial",
    parcels: shipment.packages.map((item: any) => {
      return {
        id: item.id,
        length: item.length,
        height: item.height,
        width: item.width,
        weight: item.weight,
      };
    }),
    is_customs_declarable: shipment.parcelType === parcelTypes[0].value ? 0 : 1,
    is_pallet: shipment.parcelType === parcelTypes[2].value,
    address_from: {
      city_name: shipment.locationFrom.city_name,
      country_code: shipment.locationFrom.country_code,
      state: shipment.locationFrom.state,
      lat: shipment.locationFrom.lat,
      lng: shipment.locationFrom.lng,
    },
    address_to: {
      city_name: shipment.locationTo.city_name,
      country_code: shipment.locationTo.country_code,
      state: shipment.locationTo.state,
      lat: shipment.locationTo.lat,
      lng: shipment.locationTo.lng,
    },
  };

  if (result.address_from.country_code === result.address_to.country_code) {
    shipment.locationFrom.street_1 &&
      (result.address_from.street_1 = methods.getValues(
        "locationFrom.street_1"
      ));
    shipment.locationFrom.postal_code &&
      (result.address_from.postal_code = methods.getValues(
        "locationFrom.postal_code"
      ));
    shipment.locationTo.street_1 &&
      (result.address_to.street_1 = shipment.locationTo.street_1);
    shipment.locationTo.postal_code &&
      (result.address_to.postal_code = methods.getValues(
        "locationTo.postal_code"
      ));
  }

  return result;
};
