import { styled } from "@mui/system";
import { Breakpoints, media } from "../../../../../../css/breakpoints";
import brandColors from "../../../../../../css/brandColors.scss";
import { paddingRightItem } from "../components/PackageItem/styles/PackageItem.styled";

export const StyledDescription = styled("p")`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #a8b6d2;
  margin-bottom: 24px;
`;

export const StyledLabel = styled("p")`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #000000;
  margin-top: 60px;

  @media (min-width: 600px) {
    margin-top: 40px;
  }
`;

export const StyledFooterWrapper = styled("div")`
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
`;

export const StyledTotalLabel = styled("p")`
  color: #284492;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
`;

export const StyledMessageCostRecalculated = styled("p")`
  font-size: 14px;
  line-height: 19px;
  color: ${brandColors.error};
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: ${paddingRightItem};
`;

export const StyledTotalValue = styled("span")`
  font-weight: 600;
  font-size: 18px;
  margin-left: 16px;
  text-transform: uppercase;
`;

export const StyledLabels = styled("span")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  padding-right: ${paddingRightItem};

  @media ${media(Breakpoints.Mobile)} {
    align-items: baseline;
  }
`;
