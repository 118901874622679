import { styled } from "@mui/system";
import brandColors from "../../../../css/brandColors.scss";
import { Breakpoints, media } from "../../../../css/breakpoints";
import { BaseIcon } from "../../../../components/BaseIcon";

export const Root = styled("div")`
  color: ${brandColors.mainBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 16px 0px 16px;
`;

export const StyledTitle = styled("div")`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 16px;
  color: #000000;

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    font-size: 16px;
  }

  /* @media ${media(Breakpoints.Mobile)} {
    margin-top: 32px;
  } */
`;

export const StyledSubtitle = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: ${brandColors.mainBlue};
  display: flex;
  align-items: center;
`;

export const StyledPencilIcon = styled(BaseIcon)`
  margin-left: 8px;
  width: 18px;
  height: 18px;
`;

export const StyledCardsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 32px;
  gap: 16px;
  width: 100%;
  max-width: 1200px;

  @media (min-width: 600px) {
    margin-top: 50px;
    gap: 24px;
  }
`;
