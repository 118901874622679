import { parcelTypes, EnvelopeSizes } from "../../../data/constants";
import { blockedCountriesCodes } from "../../../../../constants";
import { generateParcelFieldParameters } from "../services/generateParcelFieldParameters";
import { getCountryName } from "../../../../../services/getCountryName";

export const getDefaultFormValuesHome = (shipment: any, user: any) => {
  // console.log("=getDefaultFormValuesHome=", shipment);

  const outgoingData: any = {
    unit_of_measurement:
      shipment?.unit_of_measurement === "imperial" ? true : false,
    parcelType:
      shipment?.is_customs_declarable ||
      shipment?.is_customs_declarable === undefined
        ? shipment?.is_pallet
          ? parcelTypes[2].value
          : parcelTypes[1].value // ! default package
        : parcelTypes[0].value,
    locationTo: shipment
      ? {
          ...shipment?.address_to,
          // value: shipment?.address_to?.city_name // Не понятно, зачем было это нужно
        }
      : null,
  };

  if (shipment?.parcels) {
    outgoingData.packages = shipment.parcels.map((item: any) => ({
      ...item,
      envelopeSize: EnvelopeSizes.custom,
      // envelopeSize: outgoingData.parcelType === parcelTypes[0].value
      // ? EnvelopeSizes._default
      // : EnvelopeSizes.custom
    }));
  } else {
    outgoingData.packages = [
      {
        ...generateParcelFieldParameters(""),
      },
    ];
  }

  if (shipment?.address_from) {
    outgoingData.locationFrom = {
      ...shipment.address_from,
      // value: shipment.address_from?.city_name // Не понятно, зачем было это нужно
    };
  } else {
    outgoingData.locationFrom = generateLocationFrom(user);
  }

  outgoingData.domesticCountryCode = generateDomesticCountryCode(
    outgoingData,
    user,
  );

  return outgoingData;
};

export const generateLocationFrom = (user: any) => {
  if (
    user?.main_address_book_address?.country_code &&
    !blockedCountriesCodes.includes(user.main_address_book_address.country_code)
  ) {
    return {
      ...user.main_address_book_address,
      country: getCountryName(user.main_address_book_address.country_code),
    };
  }

  return null;
};

export const generateDomesticCountryCode = (data: any, user: any) => {
  if (
    data.locationFrom?.country_code &&
    data.locationTo?.country_code &&
    data.locationFrom.country_code === data.locationTo.country_code
  ) {
    return data.locationFrom.country_code;
  }

  if (
    user?.main_address_book_address?.country_code &&
    !blockedCountriesCodes.includes(user.main_address_book_address.country_code)
  ) {
    return user.main_address_book_address.country_code;
  }

  return undefined;
};
