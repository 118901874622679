import { useState } from "react";
import { CardAddressBook } from "./CardAddressBook";
import { BasePagination } from "../../components/BasePagination";
import { EmptyListAddressBook } from "./EmptyListAddressBook";
import styles from "./ListAddressBook.scss";

interface ListAddressBookProps {
  addressBook: any;
  onChangePage(page: any): void;
}

export const ListAddressBook = ({
  addressBook,
  onChangePage,
}: ListAddressBookProps) => {
  const [innserAddressBook, setInnserAddressBook] = useState(
    addressBook.collection,
  );

  const handleAfterDeletingAddress = (id: string) => {
    const _addressBook = innserAddressBook.filter(
      (item: any) => item.object_id !== id,
    );

    setInnserAddressBook(_addressBook);
  };

  return innserAddressBook.length ? (
    <>
      <ul className={styles.ListAddressBook}>
        {innserAddressBook.map((item: any) => (
          <CardAddressBook
            item={item}
            key={item.object_id}
            afterDeletingAddress={handleAfterDeletingAddress}
          />
        ))}
      </ul>

      {addressBook.meta.last_page > 1 && (
        <div className={styles.ListAddressBookPagination}>
          <BasePagination
            count={addressBook.meta.last_page}
            currentPage={addressBook.meta.current_page}
            onChange={onChangePage}
          />
        </div>
      )}
    </>
  ) : (
    <EmptyListAddressBook />
  );
};
