import { Box, styled } from "@mui/system";
import { BaseIcon, Name } from "../../../BaseIcon";
import { UserMenu } from "../UserMenu";
import TheHeaderExportVariables from "../../TheHeaderExportVariables.scss";
import { socialLinks } from "../../../../constants/SocialProviders";

const StyledSocialLinks = styled("div")`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledRoot = styled(Box)`
  display: none;
  align-items: center;
  gap: 24px;

  @media (min-width: ${TheHeaderExportVariables.mediaMinWidthMenuHeader}) {
    display: flex;
    padding-top: 1px;
  }
`;

type Props = {
  isAuth: boolean;
  userName: string;
};

const RightPanel: React.FC<Props> = (props) => {
  return (
    <StyledRoot>
      <StyledSocialLinks>
        <a href={socialLinks.instagram} target="_blank" rel="noreferrer">
          <BaseIcon name={Name.Instagram} isButton />
        </a>
        <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
          <BaseIcon name={Name.Facebook} isButton />
        </a>
        <a href={socialLinks.linkedin} target="_blank" rel="noreferrer">
          <BaseIcon name={Name.LinkedIn} isButton />
        </a>
      </StyledSocialLinks>

      <UserMenu isAuth={props.isAuth} userName={props.userName} />
    </StyledRoot>
  );
};

export default RightPanel;
