import getUrlAndLocation from "./getUrlAndLocation";
import goToVueInRoot from "./goToVueInRoot";

export const AppPayment = () => {
  // ! Без этого при переходе по ссылке (к примеру из почты на страницу Сброса пароля) вначале открывается страница 404 и потом только страница Сброса пароля
  // console.log("=AppPayment=", );

  const { _location, url } = getUrlAndLocation;

  goToVueInRoot(url, _location);

  return <></>;
};
