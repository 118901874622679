import { createAction } from "redux-actions";

import { REDUCER_NAME } from "./constants";

export const signIn = createAction(`${REDUCER_NAME}/SIGN_IN`);

export const signUp = createAction(`${REDUCER_NAME}/SIGN_UP`);

export const signOut = createAction(`${REDUCER_NAME}/SIGN_OUT`);

export const resetPassword = createAction(`${REDUCER_NAME}/RESET_PASSWORD`);

export const checkResetPassword = createAction(
  `${REDUCER_NAME}/CHECK_RESET_PASSWORD`,
);

export const setIsResetTokenValid = createAction(
  `${REDUCER_NAME}/SET_IS_RESET_TOKEN_VALID`,
);

export const setNewPassword = createAction(`${REDUCER_NAME}/SET_NEW_PASSWORD`);

export const signUpViaSocial = createAction(
  `${REDUCER_NAME}/SIGN_UP_VIA_SOCIAL`,
);

export const getGEOIP = createAction(`${REDUCER_NAME}/GET_GEO_CODE`);
