import * as React from "react";

export const Blog: React.FC = () => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.4688 6.9375C13.8237 6.93412 13.2037 7.20212 12.75 7.65625C11.3438 9.0595 8.2675 12.1415 7 13.4062C6.53875 13.8637 6.20375 14.4073 6.03125 15.0312L5.6875 16.2812C5.54125 16.8021 5.68 17.3675 6.0625 17.75C6.445 18.1325 7.01 18.2697 7.53125 18.125C7.95125 18.008 8.41375 17.884 8.78125 17.7812C9.40375 17.6083 9.94876 17.2688 10.4062 16.8125C11.6663 15.5558 14.7388 12.5083 16.1562 11.0938C17.1013 10.1472 17.125 8.61325 16.1875 7.65625L16.1562 7.65625C15.7062 7.20025 15.1062 6.94087 14.4688 6.9375ZM14.4688 8.4375C14.7125 8.43862 14.9213 8.5455 15.0938 8.71875L15.125 8.71875C15.4737 9.08812 15.4538 9.6698 15.0938 10.0312L14.4062 10.75C14.2488 10.4699 14.0612 10.2187 13.8438 10C13.6262 9.781 13.3713 9.56487 13.0938 9.40625L13.8125 8.71875C13.9812 8.547 14.2288 8.43637 14.4688 8.4375ZM11.9688 10.5312C12.2837 10.6468 12.5638 10.8119 12.7812 11.0312C12.9987 11.2509 13.1613 11.5521 13.2812 11.875L12.0312 13.0938L9.375 15.7812C9.0975 16.055 8.75 16.2398 8.375 16.3438L7.125 16.6875L7.46875 15.4375C7.57375 15.0632 7.78875 14.7433 8.0625 14.4688L11.9688 10.5312ZM12 0.75C9.015 0.75 6.13875 1.9215 4.03125 4.03125C1.92 6.141 0.75 9.0165 0.75 12L0.75 20.25C0.75 21.9067 2.0925 23.25 3.75 23.25L12 23.25C18.2137 23.25 23.25 18.2126 23.25 12C23.25 5.78662 18.2137 0.75 12 0.75ZM12 2.25C17.385 2.25 21.75 6.61538 21.75 12C21.75 17.3842 17.385 21.75 12 21.75L3.75 21.75C2.92125 21.75 2.25 21.0784 2.25 20.25L2.25 12C2.25 9.414 3.26375 6.92225 5.09375 5.09375C6.92 3.26525 9.4125 2.25 12 2.25Z" />
  </svg>
);
