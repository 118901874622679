import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  getConvertedSizeValue,
  getConvertedWeightValue,
  UnitSystem,
} from "../../../../../../services/convertUnitSystemValues";
import {
  MAX_HEIGHT_VALUE,
  MAX_WIDTH_VALUE,
  MAX_LENGTH_VALUE,
  MAX_WEIGHT_VALUE,
} from "../../../../data/constants";
import {
  getMaxValidationMessage,
  getMinValidationMessage,
} from "../../../../../../services/validation";

type Props = {
  unitSystem: UnitSystem;
  rtl: boolean;
};

export const validationSchema = (props: Props) => {
  // console.log("=validationSchema=", props);
  const getValidationSizeValue = (value: number) =>
    getConvertedSizeValue({
      value,
      unitSystem: props.unitSystem,
      returnOnlyNumber: true,
    });

  const getValidationWeightValue = (value: number) =>
    getConvertedWeightValue({
      value,
      unitSystem: props.unitSystem,
      returnOnlyNumber: true,
    });

  const dimensionSuffix =
    props.unitSystem.toString() === UnitSystem.METRIC ||
    props.unitSystem.toString() === "metric"
      ? props.rtl
        ? ' ס"מ'
        : "cm"
      : props.rtl
        ? " אינץ׳"
        : "inch";

  const weightSuffix =
    props.unitSystem.toString() === UnitSystem.METRIC ||
    props.unitSystem.toString() === "metric"
      ? props.rtl
        ? ' ק"ג'
        : "kg"
      : // : props.rtl ? ' פאונד' : 'lb'
        "lb";

  return yupResolver(
    yup.object().shape({
      packages: yup.array().of(
        yup.object().shape({
          length: yup
            .number()
            .typeError("")
            // .required()
            .max(
              getValidationSizeValue(MAX_LENGTH_VALUE),
              getMaxValidationMessage(
                getValidationSizeValue(MAX_LENGTH_VALUE),
                props.rtl,
              ) + dimensionSuffix,
            )
            .min(1, getMinValidationMessage(1) + dimensionSuffix),
          weight: yup
            .number()
            .typeError("")
            // .required()
            .max(
              getValidationWeightValue(MAX_WEIGHT_VALUE),
              getMaxValidationMessage(
                getValidationWeightValue(MAX_WEIGHT_VALUE),
                props.rtl,
              ) + weightSuffix,
            )
            .min(0.01, getMinValidationMessage(0.01) + weightSuffix),
          height: yup
            .number()
            .typeError("")
            // .required()
            .max(
              getValidationSizeValue(MAX_HEIGHT_VALUE),
              getMaxValidationMessage(
                getValidationSizeValue(MAX_HEIGHT_VALUE),
                props.rtl,
              ) + dimensionSuffix,
            )
            .min(1, getMinValidationMessage(1) + dimensionSuffix),
          width: yup
            .number()
            .typeError("")
            // .required()
            .max(
              getValidationSizeValue(MAX_WIDTH_VALUE),
              getMaxValidationMessage(
                getValidationSizeValue(MAX_WIDTH_VALUE),
                props.rtl,
              ) + dimensionSuffix,
            )
            .min(1, getMinValidationMessage(1) + dimensionSuffix),
        }),
      ),
    }),
  );
};
