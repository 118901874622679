import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { BaseInput } from "../../BaseInput";
import { onlyLetters } from "../../BaseInput/BaseInput";

const HFInput = (props: any) => {
  const {
    control,
    formState: { errors },
    getFieldState,
  }: any = useFormContext();

  const errorsObj = getFieldState(props.name)?.error?.types;

  let convertedErrors: any;

  if (errorsObj) {
    convertedErrors = Object.keys(errorsObj).map((key) => errorsObj[key]);
  }

  const handleChange = (e: any, onChange: any): void => {
    // console.log("=handleChange=", e.target.value);

    if (props.onlyLetters) {
      e.target.value = onlyLetters(e.target.value);
    }

    onChange(e);
  };

  return (
    <Controller
      control={control}
      name={props.name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <BaseInput
          label={props.label}
          multiline={props.multiline}
          onChange={(e: any) => handleChange(e, onChange)}
          value={props.value || value} // Иногда label поля не переходит на верх и происходит наложение занчения в поле и label. Value из Controller uk.xbn
          error={
            getFieldState(props.name)?.error?.message ||
            convertedErrors ||
            error
          }
          {...props} // FIXME: избавится от этого, потому что от расположения этого не корректно работает value. Все пропсы корректнее передавать явно!!!
          fullWidth
        />
      )}
    />
  );
};

export default React.memo(HFInput);
