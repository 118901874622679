export const getDefaultFormValues = (shipment: any) => ({
  address_from: {
    is_company:
      shipment?.address_from?.first_name && !shipment?.address_from?.passport,
    ...shipment?.address_from,
    company_name: shipment?.address_from?.company_name ?? "",
    passport: shipment?.address_from?.passport ?? "",
    company_ein: shipment?.address_from?.company_ein ?? "",
    instructions: shipment?.pickup_details?.instructions,
  },
});
