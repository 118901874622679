import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { reducers } from "./reducers";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import { rootSaga } from "./sagas";
import { authSaga } from "../scenes/auth/data/sagas";
import { profileSaga } from "../scenes/profile/data/sagas";
import { checkoutSaga } from "../scenes/checkout/data/sagas";

// const initialState: any = {
//   counter: 1
// }

const saga = createSagaMiddleware({
  onError: (error: Error) => {
    throw error.message;
  },
});
const middleware = [saga, thunk];

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware)),
);

saga.run(rootSaga);
saga.run(authSaga);
saga.run(checkoutSaga);
saga.run(profileSaga);

export default store;
