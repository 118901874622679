import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { selectUnitSystem } from "../../../../../../../data/selectors";
import { BaseButton } from "../../../../../../../components/BaseButton";
import { ToggleBtn } from "../../../../../../../components/ToggleBtn";
import {
  Accordion,
  AccordionCard,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryText,
} from "../../../../AccordionItems/Accordion";
import { Parcels } from "../../../../../../../components/Parcels/Parcels";
import { AddNewMobileButton } from "../../../../../../home/components/Form/components/AddNewMobileButton";
import {
  setAddressFromFormErrors,
  updateShipment,
} from "../../../../../data/actions";
import { changeUnitSystem } from "../../../../../../../data/actions";
import { setPackageValues } from "../../../../../../home/components/Form/services/setPackageValues";
import { updateParcelsShipment } from "../../../../../api";
import { StyledActionFooter } from "../styles/AccordionItems.styled";
import styles from "./ParcelEditing.scss";
import { generateDefaultFormValuesParcelEditing } from "./generateDefaultFormValuesParcelEditing";
import { maximumNumberOfItemsInParcel } from "../../../../../../home/components/Form/Form";
import { EnvelopeSizes } from "../../../../../../home/data/constants";
import { validationSchema } from "../../../../../../home/components/Form/services/validation";
import nextStepInCheckoutEventInfobip from "../../../../../../../plugins/infobip/nextStepInCheckoutEventInfobip";
import getShortShipmentIdInfobip from "../../../../../../../plugins/infobip/getShortShipmentIdInfobip";

export const ParcelEditing = (props: any) => {
  const dispatch = useDispatch();
  const unitSystem = useSelector(selectUnitSystem);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const methods = useForm({
    defaultValues: generateDefaultFormValuesParcelEditing(props.shipment),
    mode: "onChange",
    resolver: validationSchema({ unitSystem }),
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "packages",
  });

  const handleAddCount = (): void => {
    append({
      id: Date.now(),
      weight: "",
      height: "",
      length: "",
      width: "",
      envelopeSize: EnvelopeSizes.custom,
    });
  };

  const disabledNextBtn = (): boolean => {
    return methods.watch("packages").every((itemPackage: any) => {
      return (
        itemPackage.height &&
        itemPackage.length &&
        itemPackage.weight &&
        itemPackage.width
      );
    });
  };

  const onSubmit = async (formValues: any) => {
    // console.log('=onSubmit=', formValues);
    setLoadingSubmit(true);

    try {
      const res = await updateParcelsShipment(props.shipment.object_id, {
        unit_of_measurement: formValues.unit_of_measurement,
        parcels: formValues.packages,
      });
      // console.log("=res=", res);

      dispatch(
        updateShipment({
          parcels: res,
          unit_of_measurement: formValues.unit_of_measurement,
        })
      );

      props.onNextStep();
      process.env.REACT_APP_INFOBIP &&
        nextStepInCheckoutEventInfobip(
          "parcel_details",
          1,
          getShortShipmentIdInfobip(props.shipment.object_id)
        );
    } catch (err) {
    } finally {
      setLoadingSubmit(false);
    }
  };

  const formIsActive = props.expanded === props.name;

  return (
    <AccordionCard>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Accordion
            expanded={formIsActive}
            onChange={props.onChangeAccordion(props.name)}
            disabled={props.disabled}
          >
            <AccordionSummary disabled={props.disabled} expanded={formIsActive}>
              <AccordionSummaryText>Parcel details</AccordionSummaryText>
            </AccordionSummary>

            <AccordionDetails>
              <ToggleBtn
                selected={methods.getValues("unit_of_measurement")}
                options={[
                  {
                    label: "Metric",
                    value: "metric",
                  },
                  {
                    label: "Imperial",
                    value: "imperial",
                  },
                ]}
                onChange={(value: string) => {
                  console.log("=ToggleBtn onChange=", value);
                  dispatch(changeUnitSystem(value));

                  methods.setValue("unit_of_measurement", value);

                  methods
                    .getValues("packages")
                    .forEach((item: any, index: number) => {
                      // console.log("==", `${value}`, item.envelopeSize);

                      if (
                        item.envelopeSize === EnvelopeSizes.small ||
                        item.envelopeSize === EnvelopeSizes.large
                      ) {
                        const _sizes =
                          setPackageValues[`${value === "imperial"}`][
                            item.envelopeSize
                          ];

                        Object.keys(_sizes).forEach(
                          (parcelSizeTypeName: any) => {
                            methods.setValue(
                              `packages[${index}].${parcelSizeTypeName}`,
                              _sizes[parcelSizeTypeName]
                            );
                          }
                        );
                      }
                    });
                }}
              />

              <Parcels
                parcelType={methods.getValues("parcelType")}
                unitSystem={methods.watch("unit_of_measurement")}
                getFieldState={methods.getFieldState}
                register={methods.register}
                fields={fields}
                onChange={({ fieldName, value }: any) => {
                  // console.log("=onChange=", fieldName, value);
                  methods.setValue(fieldName, value);
                  methods.trigger();
                }}
                remove={remove}
              />

              {methods.getValues("packages").length <
                maximumNumberOfItemsInParcel && (
                <AddNewMobileButton
                  parcelType={methods.getValues("parcelType")}
                  onClick={handleAddCount}
                />
              )}

              <StyledActionFooter>
                <BaseButton
                  className={styles.submitBtn}
                  loading={loadingSubmit}
                  type="submit"
                  disabled={!disabledNextBtn()}
                >
                  NEXT STEP
                </BaseButton>
              </StyledActionFooter>
            </AccordionDetails>
          </Accordion>
        </form>
      </FormProvider>
    </AccordionCard>
  );
};
