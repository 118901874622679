import { format } from "date-fns";

import styles from "./styles.scss";

export const getPickupDateLabel: any = (date: any) => {
  // console.log('=getPickupDateLabel=', date);

  return (
    <span className={styles.label}>
      {date && (
        <>
          <span>{format(new Date(date), "MMMM d")}</span>
          <span>{format(new Date(date), "HH:mm")}</span>
        </>
      )}
      {/* {formValues?.time?.label && <span>{formValues.time.label}</span>} */}
    </span>
  );
};

export const getPickupDetailsLabel: any = (formValues: any) => {
  // console.log("=getPickupDetailsLabel=", formValues);

  return (
    <span className={styles.label}>
      {/* {formValues?.first_name && (
        <span>{formValues.first_name}</span>
      )}
      {formValues?.last_name && (
        <span>{formValues.last_name}</span>
      )}
      {formValues?.phone && (
        <span>{formValues.phone}</span>
      )}
      {formValues?.passport && (
        <span>{formValues.passport}</span>
      )} */}
      {formValues?.street_1 && (
        <span>
          {typeof formValues.street_1 === "string"
            ? formValues.street_1
            : formValues.street_1?.address}
        </span>
      )}
      {/* {formValues?.street_2 && (
        <span>{formValues.street_2}</span>
      )} */}
      {formValues?.city_name && <span>{formValues.city_name}</span>}
      {formValues?.state && <span>{formValues.state}</span>}
      {formValues?.postal_code && <span>{formValues.postal_code}</span>}
      {formValues?.country && <span>{formValues.country}</span>}
      {/* {formValues?.instructions && (
        <span>{formValues.instructions}</span>
      )} */}
    </span>
  );
};
