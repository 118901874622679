import { ParcelSizeTypes } from "../../../data/constants";
import { EnvelopeSizes } from "../../../data/constants";

export const setPackageValues: any = {
  true: {
    [EnvelopeSizes.small]: {
      [ParcelSizeTypes.width]:
        process.env.REACT_APP_MAX_WIDTH_OF_SMALL_LETTER_IMPERIAL,
      [ParcelSizeTypes.length]:
        process.env.REACT_APP_MAX_LENGTH_OF_SMALL_LETTER_IMPERIAL,
      [ParcelSizeTypes.height]:
        process.env.REACT_APP_MAX_THICKNESS_OF_SMALL_LETTER_IMPERIAL,
      [ParcelSizeTypes.weight]:
        process.env.REACT_APP_MAX_WEIGHT_OF_SMALL_LETTER_IMPERIAL,
    },
    [EnvelopeSizes.large]: {
      [ParcelSizeTypes.width]:
        process.env.REACT_APP_MAX_WIDTH_OF_LARGE_LETTER_IMPERIAL,
      [ParcelSizeTypes.length]:
        process.env.REACT_APP_MAX_LENGTH_OF_LARGE_LETTER_IMPERIAL,
      [ParcelSizeTypes.height]:
        process.env.REACT_APP_MAX_THICKNESS_OF_LARGE_LETTER_IMPERIAL,
      [ParcelSizeTypes.weight]:
        process.env.REACT_APP_MAX_WEIGHT_OF_LARGE_LETTER_IMPERIAL,
    },
    [EnvelopeSizes.custom]: {
      [ParcelSizeTypes.width]: "",
      [ParcelSizeTypes.length]: "",
      [ParcelSizeTypes.height]: "",
      [ParcelSizeTypes.weight]: "",
    },
  },
  false: {
    [EnvelopeSizes.small]: {
      [ParcelSizeTypes.width]: process.env.REACT_APP_MAX_WIDTH_OF_SMALL_LETTER,
      [ParcelSizeTypes.length]:
        process.env.REACT_APP_MAX_LENGTH_OF_SMALL_LETTER,
      [ParcelSizeTypes.height]:
        process.env.REACT_APP_MAX_THICKNESS_OF_SMALL_LETTER,
      [ParcelSizeTypes.weight]:
        process.env.REACT_APP_MAX_WEIGHT_OF_SMALL_LETTER,
    },
    [EnvelopeSizes.large]: {
      [ParcelSizeTypes.width]: process.env.REACT_APP_MAX_WIDTH_OF_LARGE_LETTER,
      [ParcelSizeTypes.length]:
        process.env.REACT_APP_MAX_LENGTH_OF_LARGE_LETTER,
      [ParcelSizeTypes.height]:
        process.env.REACT_APP_MAX_THICKNESS_OF_LARGE_LETTER,
      [ParcelSizeTypes.weight]:
        process.env.REACT_APP_MAX_WEIGHT_OF_LARGE_LETTER,
    },
    [EnvelopeSizes.custom]: {
      [ParcelSizeTypes.width]: "",
      [ParcelSizeTypes.length]: "",
      [ParcelSizeTypes.height]: "",
      [ParcelSizeTypes.weight]: "",
    },
  },
};
