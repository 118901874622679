import * as React from "react";

export const OutlinedBrandBox: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M8.963 14.75H4a.5.5 0 0 0-.5.5V20a.5.5 0 0 0 .5.5h4.963a.5.5 0 0 0 .5-.5v-4.75a.5.5 0 0 0-.5-.5ZM4 13.25a2 2 0 0 0-2 2V20a2 2 0 0 0 2 2h4.963a2 2 0 0 0 2-2v-4.75a2 2 0 0 0-2-2H4Z"
      clipRule="evenodd"
    />
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M3.5 4v4.75a.5.5 0 0 0 .5.5h7.524a3.5 3.5 0 0 1 3.5 3.5V20a.5.5 0 0 0 .5.5H20a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5H4a.5.5 0 0 0-.5.5ZM2 4v4.75a2 2 0 0 0 2 2h7.524a2 2 0 0 1 2 2V20a2 2 0 0 0 2 2H20a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"
      clipRule="evenodd"
    />
  </svg>
);
