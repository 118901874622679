import { styled } from "@mui/system";

import OopsImage from "../../../../../../assets/images/oops.svg";
import { BaseButton } from "../../../../../../components/BaseButton";

import styles from "./ErrorModalContent.scss";

const StyledRoot = styled("div")(
  () => `
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`,
);

const StyledErrorImage = styled("img")(
  () => `
  margin-bottom: 16px;
  min-height: 80px;
`,
);

const StyledTitle = styled("div")(
  () => `
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #284492;
  margin-bottom: 5px;
`,
);

const StyledDescription = styled("div")(
  () => `
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #284492;
`,
);

const ErrorModalContent = (props: any) => {
  return (
    <StyledRoot>
      <StyledErrorImage src={OopsImage} alt="" />

      <StyledTitle>Oops</StyledTitle>

      {props?.errorMessage ? (
        <StyledDescription
          dangerouslySetInnerHTML={{ __html: props?.errorMessage }}
        ></StyledDescription>
      ) : (
        <StyledDescription>
          The selected country is not supported
        </StyledDescription>
      )}

      <BaseButton className={styles.submitBtn} onClick={props.onClose}>
        Enter again
      </BaseButton>
    </StyledRoot>
  );
};

export default ErrorModalContent;
