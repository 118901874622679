import { IconButton } from "@mui/material";
import { BaseIcon, Name } from "../../../../../../../../components/BaseIcon";
import { StyledTooltip } from "../../../../../../../../components/TooltipBase.styled";

export const ExportDeclarationTooltipRightPanels = () => {
  return (
    <StyledTooltip title="An export declaration is an official customs document, issued by the courier in conjunction with customs, which provides the sender/exporter a formal approval that the goods have left the country.">
      <IconButton style={{ color: "#a8b6d2", cursor: "default" }} disableRipple>
        <BaseIcon name={Name.Info} size={24} />
      </IconButton>
    </StyledTooltip>
  );
};
