import { IconButton } from "@mui/material";
import { BaseIcon, Name } from "../../components/BaseIcon";
import { StyledTooltip } from "../../components/TooltipBase.styled";

export const CompanyEinTooltip = () => {
  return (
    <StyledTooltip title="Tax ID / VAT number is required for tax purposes and in order to clear shipments from customs at the destination">
      <IconButton style={{ color: "#757575", cursor: "default" }} disableRipple>
        <BaseIcon name={Name.Info} size={32} />
      </IconButton>
    </StyledTooltip>
  );
};
