import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../data/selectors";
import { BaseCard } from "../../../../components/BaseCard";
import { BasePage } from "../../../../components/BasePage";
import { BaseLoading } from "../../../../components/BaseLoading";

import { BaseDividerLine } from "../../../../components/BaseDividerLine";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import type { Location } from "@remix-run/router";
import { BaseIcon, Name } from "../../../../components/BaseIcon";
import { BaseButton } from "../../../../components/BaseButton";
import { DownloadCarrier } from "./DownloadCarrier";

import LogoIcon from "../../../../assets/logo.svg";

import styles from "./PaymentResult.scss";
import { Container } from "@mui/material";
import { ShipmentAPI } from "../../../../api";
import { socialLinks } from "../../../../constants/SocialProviders";
import {
  StyledDesktopBlock,
  StyledDocumentsList,
  StyledErrorText,
  StyledHelpText,
  StyledInfoText,
  StyledLogo,
  StyledOrderIdText,
  StyledSocialLinks,
} from "./styles/PaymentResult.styled";
import { goToVue } from "../../../../integrationWithVue/goToVue";
import { selectLocale } from "../../../../data/selectors";

const PaymentResult: React.FC<any> = (props) => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");
  const locale = useSelector(selectLocale);

  const location: Location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const user: any = useSelector(selectUser);

  let paymentFailed: boolean = false;
  let courierName: string = "";
  let innerSearchParams: any;
  let state: any;
  let shipment: any;

  if (props.isError) {
    // ! isError больше не передаётся в компонент, так как Транзила стало отдавать ссылку в подходящем формате
    // ! Это необходимо, так как Транзила при ошибке отдаёт квери параметры одной строкой. Транзила отдаёт ссылку при ошибке не корректную
    let allParams: any = searchParams.get("state");
    allParams = "state=" + allParams?.replace(/and/g, "&");

    innerSearchParams = new URLSearchParams(allParams);

    state = innerSearchParams.get("state");
  } else {
    innerSearchParams = searchParams;
    state = innerSearchParams.get("state");
    // console.log("=state=", state, innerSearchParams);
  }

  state = JSON.parse(state) || {};

  const handleGoToApp = () => {
    state.platform = "browser";

    const params = new URLSearchParams();

    params.append(
      "transaction_id",
      innerSearchParams.get("transaction_id") ?? "",
    );
    params.append(
      "tranzila_response",
      innerSearchParams.get("tranzila_response") ?? "",
    );
    params.append("state", JSON.stringify(state));

    window.location.href = `shipperglobalapp:/${location.pathname}?${params.toString()}`;

    globalThis.close();
    navigate(locale !== "en" ? `/${locale}` : "/");
  };

  let isRedirectToApp = false;
  if (state?.platform === "mobile") {
    isRedirectToApp = true;
    handleGoToApp();
  }

  const goOut = () => {
    if (paymentFailed) {
      window.open(
        `https://ego-digital.atlassian.net/servicedesk/customer/portal/7/group/15/create/77?summary=Payment%20failed%20-%20Reference%20number%20${
          shortShipmentID
        }&email=${user.email}`,
        "_blank",
      );
    } else {
      navigate(locale !== "en" ? `/${locale}` : "/");
    }
  };

  const [transaction, setTransaction] = useState<any>({});
  const [shortShipmentID, setShortShipmentID] = useState<any>("");

  const [isLoadingApi, setIsLoadingApi] = useState<boolean>(true);
  useEffect(() => {
    if (!isRedirectToApp) {
      (async () => {
        try {
          const res: any = await ShipmentAPI.getShipment(
            innerSearchParams.get("shipment_id"),
          );
          // console.log("==", res);

          if (!res) return;

          shipment = res;

          setTransaction(res.transaction);

          setShortShipmentID(res.object_id?.substr(-5) || "");

          courierName = transaction?.carrier?.name ?? "";

          const payment: any =
            res.payments.find((payment: any) => payment.type === "MAIN") || {};

          paymentFailed = payment.status !== "COMPLETED";

          if (process.env.REACT_APP_GOOGLE_TAG_MANAGER) {
            if (!paymentFailed) {
              dataLayer.push({ ecommerce: null });

              const ecommerce: any = {
                transaction_id: res.object_id,
                currency: payment.currency,
                value: payment.total_amount,
                var_uid: user.id,
                items: [
                  {
                    item_category: !shipment.is_customs_declarable
                      ? "Letters"
                      : shipment.is_pallet
                        ? "Pallets"
                        : "Parcels",
                    item_id:
                      `${res.chosen_rate.provider.name}_${res.chosen_rate.service_level.name}`
                        .toLocaleLowerCase()
                        .replace(/ /g, "_"),
                    item_brand: res.chosen_rate.provider.name,
                    item_name: res.chosen_rate.service_level.name,
                    item_variant: res.chosen_rate.service_level.token,
                    price: payment.total_amount,
                  },
                ],
              };

              res.coupon_code && (ecommerce.coupon = res.coupon_code);

              // console.log("=ecommerce=", ecommerce);

              dataLayer.push({
                event: "purchase",
                ecommerce,
              });
            }
          }
        } finally {
          setIsLoadingApi(false);
        }
      })();
    }
  }, []);

  const getPickupStatusInfoMessage = (status: string) => {
    switch (status) {
      case "CONFIRMED":
        return <StyledInfoText>Courier call confirmed</StyledInfoText>;
      case "ERROR":
        return (
          <StyledErrorText>
            Courier call not confirmed contact&ensp;
            <a
              href={process.env.REACT_APP_LINK_SHIPPER_DESK_SUPPORT}
              target="_blank"
              rel="noreferrer"
            >
              support
            </a>
          </StyledErrorText>
        );

      default:
        return "";
    }
  };

  return isLoadingApi ? (
    <BasePage className={styles.page} blueBackground>
      <BaseLoading />
    </BasePage>
  ) : (
    <BasePage className={styles.page} blueBackground>
      <Container className={styles.container}>
        <BaseCard className={styles.card}>
          <StyledDesktopBlock>
            <StyledLogo src={LogoIcon} alt="" />

            {paymentFailed ? (
              <>
                <StyledErrorText>
                  Payment failed - Shipment not created!
                </StyledErrorText>

                {transaction?.payment?.failed_reason && (
                  <StyledInfoText>
                    Reason: {transaction.payment.failed_reason}
                  </StyledInfoText>
                )}
              </>
            ) : (
              <StyledInfoText style={{ textAlign: "center" }}>
                Shipment created successfully and sent to {courierName}
              </StyledInfoText>
            )}

            {
              transaction?.pickup &&
                getPickupStatusInfoMessage(transaction.pickup.status)
              // TODO: Видимо в ответе не бывает pickup. Видимо стоит упразднить это.
            }

            <StyledOrderIdText>
              {paymentFailed ? "Reference number" : "Shipment number"}

              <span>{shortShipmentID}</span>
            </StyledOrderIdText>

            {transaction?.tracking_number && (
              <StyledOrderIdText>
                {courierName} tracking number
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(event) =>
                    goToVue(
                      `/tracking/?t=${transaction.tracking_number}`,
                      event,
                    )
                  }
                >
                  {transaction.tracking_number}
                </a>
              </StyledOrderIdText>
            )}

            <BaseDividerLine />
          </StyledDesktopBlock>

          {!paymentFailed && (
            <>
              <StyledHelpText>
                {transaction?.label_url || transaction?.invoice_url ? (
                  <>
                    <div>
                      Please download and{" "}
                      <span style={{ fontSize: "16px", fontWeight: "700" }}>
                        print out
                      </span>{" "}
                      the following pages.
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      You are required to provide them to the courier during
                      pick up.
                    </div>
                  </>
                ) : (
                  <>
                    In a few minutes you'll receive a confirmation email along
                    with a label attachment.
                    <br />
                    Please print the label and provide it to the courier during
                    pick up.
                  </>
                )}
              </StyledHelpText>

              <StyledDocumentsList>
                {transaction?.label_url && (
                  <DownloadCarrier
                    fileName={`${courierName} label`}
                    url={transaction?.label_url}
                  />
                )}

                {transaction?.invoice_url && (
                  <DownloadCarrier
                    fileName="Commercial invoice"
                    url={transaction?.invoice_url}
                  />
                )}
              </StyledDocumentsList>

              <BaseDividerLine />
            </>
          )}

          {transaction?.payment?.status === "FAILED" && (
            <>
              <BaseButton
                label="Retry Payment"
                onClick={() => {
                  console.log("=onClick transaction=", transaction);
                  goToVue(`/payments/${transaction.payment.id}`);
                  // window.open(transaction.payment?.payment_url, '_blank')
                }}
              />

              <StyledDesktopBlock>
                <div style={{ width: "200px" }}></div>
                <BaseDividerLine />
              </StyledDesktopBlock>
            </>
          )}

          <BaseButton
            onClick={goOut}
            label={
              paymentFailed ? "Contact support" : "Create another shipment"
            }
          />

          {/* <BaseDividerLine />
            <StyledHelpText className={styles.needHelpText}>
              Need help or tips on proper packaging?{' '}
              <BaseLink className={styles.clickHereLink} underline="hover" to="/">
                Click here
              </BaseLink>{' '}
            </StyledHelpText>*/}
          <BaseDividerLine />

          <StyledSocialLinks>
            <a href={socialLinks.instagram} target="_blank" rel="noreferrer">
              <BaseIcon name={Name.Instagram} isButton size={20} />
            </a>
            <a href={socialLinks.instagram} target="_blank" rel="noreferrer">
              <BaseIcon name={Name.Facebook} isButton size={20} />
            </a>
            <a href={socialLinks.instagram} target="_blank" rel="noreferrer">
              <BaseIcon name={Name.LinkedIn} isButton size={20} />
            </a>
          </StyledSocialLinks>
        </BaseCard>
      </Container>
    </BasePage>
  );
};

export default PaymentResult;
