import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Container, Grid } from "@mui/material";

import { OrderDataCard } from "./components/OrderDataCard";
import { RightPanels } from "./components/RightPanels";

import { validationSchema } from "./services/validation";

import styles from "./styles/BillingTab.scss";
import { useSelector } from "react-redux";
import { selectShipment } from "../../../../data/selectors";

type Props = {
  shipmentId: string;
  roundingRankOfItemWeightInParcelInPackageDetails: number;
  onPrevStep(cardName: string): void;
};

const BillingTab: React.FC<Props> = (props) => {
  const shipment = useSelector(selectShipment);

  const methods = useForm({
    defaultValues: {
      billing_address: {
        ...shipment.billing_address,
        // countrySelect: shipment?.billing_address?.country || ''
      },
    },
    mode: "onSubmit",
    resolver: validationSchema,
  });

  return (
    <FormProvider {...methods}>
      <Container>
        <Grid container spacing={{ xs: 0, md: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            {/* <Notification className={styles.notification} /> */}

            <OrderDataCard
              className={styles.card}
              roundingRankOfItemWeightInParcelInPackageDetails={
                props.roundingRankOfItemWeightInParcelInPackageDetails
              }
              onPrevStep={props.onPrevStep}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <RightPanels shipmentId={props.shipmentId} />
          </Grid>
        </Grid>
      </Container>
    </FormProvider>
  );
};

export default BillingTab;
