import * as React from "react";

export const Copy: React.FC = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.333 5.667v10A1.667 1.667 0 0 0 10 17.333h6.666a1.667 1.667 0 0 0 1.667-1.666V8.368a1.665 1.665 0 0 0-.502-1.191L15.07 4.475A1.666 1.666 0 0 0 13.904 4H10a1.667 1.667 0 0 0-1.667 1.667v0Z"
      stroke="#284492"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 17.333V19a1.666 1.666 0 0 1-1.667 1.667H6.667A1.667 1.667 0 0 1 5 19V9.833a1.667 1.667 0 0 1 1.667-1.666h1.666"
      stroke="#284492"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
