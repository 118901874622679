import * as React from "react";

export const RedSquareInCircle: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="10" fill="white" />
    <rect x="5" y="5" width="10" height="10" fill="#FF5C5C" />
  </svg>
);
