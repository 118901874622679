import * as React from "react";

export const USD: React.FC = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enable-background="new 0 0 1000 1000"
    xmlSpace="preserve"
  >
    <path d="M549.8,990h-79V881.7c-145.2-12.1-238.1-82.3-278.7-210.8l125.9-29.3c22.6,82.4,73.6,126.7,152.8,132.9V543.3c-103.4-23.8-170.8-54-202-90.4c-31.2-36.5-46.8-80.1-46.8-130.8c0-131.5,82.9-207.4,248.8-227.7V10h79v84.3c121.4,15.6,198.1,75.5,230.1,179.7L661,299.2c-20.3-60.1-57.4-92.5-111.2-97.2v204.3c76.5,21.9,128,38.6,154.6,50.3c26.5,11.7,50.4,34.5,71.7,68.5c21.3,34,31.9,73.8,31.9,119.4c0,64.8-22.1,118.6-66.2,161.6c-44.1,42.9-108.1,68.1-192,75.5V990L549.8,990z M470.7,202c-34,0.4-62.3,8.9-85.2,25.5c-22.8,16.6-34.2,41.1-34.2,73.5c0,54.6,39.8,87.2,119.4,97.8V202z M549.8,774.6c36.7-0.4,67.4-11.1,92.2-32.2c24.8-21.1,37.2-48.6,37.2-82.5c0-57-43.1-93.5-129.4-109.5V774.6z" />
  </svg>
);
