import { styled } from "@mui/system";
import brandColors from "../../../../../css/brandColors.scss";

export const StyledRoot = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const StyledErrorImage = styled("img")`
  margin-bottom: 16px;
  min-height: 80px;
`;

export const StyledTitle = styled("div")`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${brandColors.mainBlue};
  margin-bottom: 5px;
`;

export const StyledDescription = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${brandColors.mainBlue};
`;

export const StyledControl = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
`;

export const StyledBtnNotSave = styled("button")`
  border: 1px solid ${brandColors.mainBlue};
  color: ${brandColors.mainBlue};
  font-weight: 500;
  padding: 16px 35px;
  border-radius: 16px;
`;
