import { ChangeEvent, useState, useRef, forwardRef } from "react";

import { Name } from "../BaseIcon";

import { CSSTransition } from "react-transition-group";

import "../../css/animations.css";
import { InputAdornment } from "@mui/material";
import {
  StyledBaseIcon,
  StyledErrorFieldWrapper,
  StyledErrorMessage,
  StyledHint,
  StyledInput,
  StyledMainInputWrapper,
  StyledMoneyInput,
  StyledMoneyInputWrapper,
  StyledPrefix,
  StyledPrefixWrapper,
  StyledRoot,
  StyledSuffix,
  StyledSuffixWrapper,
  StyledSuffixV2Wrapper,
} from "./BaseInputForPhoneInput.styled";

const duration = 500;

type ErrorProps = {
  error?: string | boolean;
  moneyInput?: boolean;
};

export const ErrorField: React.FC<ErrorProps> = (props) => {
  return (
    <CSSTransition
      onClick={(e: any) => e?.stopPropagation()}
      in={!!props.error}
      timeout={duration}
      classNames="fade-up"
    >
      {Array.isArray(props.error) ? (
        <div>
          {props.error.map((el: any, index: any) => (
            <StyledErrorMessage moneyInput={props.moneyInput} key={index}>
              {el}
            </StyledErrorMessage>
          ))}
        </div>
      ) : (
        <StyledErrorMessage moneyInput={props.moneyInput}>
          {props.error}
        </StyledErrorMessage>
      )}
    </CSSTransition>
  );
};

export const digitsAndDecimal =
  (maxDecimalScale: number, maxDigitsCount: number) => (value: string) => {
    if (!value) {
      return value;
    }

    let formattedValue = value.replace(/[^\d.,]/g, "").replace(/,/g, ".");

    const dotExists = value.includes(".");
    const commaExists = value.includes(","); // for number copy/paste
    if (dotExists || commaExists) {
      // delete extra dots
      const output = formattedValue.split(".");
      formattedValue = output.shift() + "." + output.join("");

      // allow only {maxDecimalScale} numbers after dot
      const indexDot = formattedValue.indexOf(".");

      return formattedValue.slice(0, indexDot + maxDecimalScale + 1);
    }

    return formattedValue.slice(0, maxDigitsCount);
  };

export const onlyLetters = (value: string): string => {
  return value?.replace(/[^ a-zA-Z\u0590-\u05fe-]+/g, "");
};

const BaseInputForPhoneInput = forwardRef((props: any, ref: any) => {
  const {
    error,
    className,
    hint,
    type: initialType,
    suffix,
    prefix,
    moneyInput,
    label,
    withoutErrors,
    isSearch,
    withoutBorder,
    rightIcon,
    autocomplete,
    withBackground,
    multiline,
    placeholder,
    onSuffixClick,
    minlength,
    maxlength,
    pattern,
    title,
    ...otherProps
  } = props;

  const inputRef = useRef<HTMLDivElement>(null);
  const [type, setType] = useState(initialType);

  const isPassword = initialType === "password";

  const handleSuffixClick = (event: any): void => {
    if (isPassword) {
      if (type === "password") {
        setType("text");
      } else {
        setType("password");
      }
    } else {
      if (onSuffixClick) {
        onSuffixClick();
        event.preventDefault();
      }
    }
  };

  const handlePrefixClick = (): void => {
    props.onPrefixClick();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log("=handleChange=", e, e.target.value, otherProps.onChange);

    if (otherProps.onChange) {
      otherProps.onChange(e);
    }
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log("=handleBlur=", e);

    setIsFocus(false);

    if (e.target.value[e.target.value.length - 1] === ".") {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1);

      handleChange(e);
    }

    otherProps.onBlur && otherProps.onBlur(e);
  };

  const handleKeyPress = (event: any): boolean | undefined => {
    if (props.onKeyPress) {
      if (props.onKeyPress(event.key, event.target.value)) return true;
      event.preventDefault();
      return false;
    }
  };

  const [isFocus, setIsFocus] = useState(false);

  const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log("=handleFocus=");
    setIsFocus(true);
    otherProps.onFocus && otherProps.onFocus(e);
  };

  return (
    <StyledRoot className={className} moneyInput={moneyInput}>
      <label>
        {/* {label && <StyledLabel>{label}</StyledLabel>} */}

        {moneyInput ? (
          <StyledMoneyInputWrapper withSuffix={!!suffix}>
            {suffix && <div></div>}

            {prefix && (
              <StyledPrefixWrapper onClick={handlePrefixClick} type="button">
                {<StyledPrefix>{prefix}</StyledPrefix>}
              </StyledPrefixWrapper>
            )}

            <StyledMoneyInput
              ref={inputRef}
              type={props.type}
              inputMode={props.inputMode}
              tabindex={otherProps.tabindex}
              error={error}
              prefix={prefix}
              suffix={suffix}
              minlength={minlength}
              maxlength={maxlength}
              pattern={pattern}
              title={title}
              onKeyDown={handleChange}
              // decimalScale={props.decimalScale}
              placeholder={isFocus ? undefined : placeholder}
              {...otherProps}
              {...(props.register && props.register(props.name, props.rules))}
              autocomplete={autocomplete ? "on" : "off"}
              onBlur={handleBlur}
              onFocus={handleFocus}
              value={
                props.normalize
                  ? props.normalize(otherProps.value?.toString())
                  : otherProps.value
              }
            />

            {suffix && (
              <StyledSuffixV2Wrapper>
                {<StyledSuffix>{suffix}</StyledSuffix>}
              </StyledSuffixV2Wrapper>
            )}
          </StyledMoneyInputWrapper>
        ) : (
          <StyledMainInputWrapper>
            {prefix && (
              <StyledPrefixWrapper onClick={handlePrefixClick} type="button">
                {<StyledPrefix>{prefix}</StyledPrefix>}
              </StyledPrefixWrapper>
            )}

            <StyledInput
              inputRef={ref}
              label={props.label}
              variant="standard"
              multiline={multiline}
              onChange={handleChange}
              fullWidth
              error={error}
              withoutBorder={withoutBorder}
              type={type}
              placeholder={isFocus ? undefined : placeholder}
              inputProps={{
                inputMode: props.inputMode,
                step: props.step,
                minlength: minlength,
                maxlength: maxlength,
                pattern: pattern,
                title: title,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {(isPassword || isSearch || rightIcon) && (
                      <StyledSuffixWrapper
                        onClick={handleSuffixClick}
                        type="button"
                      >
                        {isPassword && (
                          <StyledBaseIcon
                            name={
                              type === "password"
                                ? Name.EyeOpen
                                : Name.EyeClosed
                            }
                          />
                        )}
                        {rightIcon && (
                          <StyledBaseIcon name={rightIcon} isButton />
                        )}
                      </StyledSuffixWrapper>
                    )}
                    {suffix && (
                      <StyledSuffixV2Wrapper
                        onClick={(event) => handleSuffixClick(event)}
                      >
                        {<StyledSuffix>{suffix}</StyledSuffix>}
                      </StyledSuffixV2Wrapper>
                    )}
                  </InputAdornment>
                ),
              }}
              {...otherProps}
              onKeyPress={handleKeyPress}
              onBlur={(e) => {
                setIsFocus(false);
                otherProps.onBlur && otherProps.onBlur(e);
              }}
              onFocus={handleFocus}
              value={
                props.normalize
                  ? props.normalize(otherProps.value?.toString())
                  : otherProps.value
              }
            />
          </StyledMainInputWrapper>
        )}
      </label>

      {hint && !error && <StyledHint>{hint}</StyledHint>}

      {!withoutErrors && (
        <StyledErrorFieldWrapper isMoneyInput={moneyInput}>
          <ErrorField error={error} />
        </StyledErrorFieldWrapper>
      )}
    </StyledRoot>
  );
});

export default BaseInputForPhoneInput;
