import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LayoutWithLeftMenu } from "../../layouts/leftMenu/LayoutWithLeftMenu";
import { BaseLoading } from "../../components/BaseLoading";
import { FormAppAddressBookEditor } from "./FormAppAddressBookEditor";
import { getAddressFromBook } from "./_apiRequests.AppAddressBookEditor";

interface AppAddressBookEditorProps {
  isEdit?: boolean;
}

export const AppAddressBookEditor = ({
  isEdit = false,
}: AppAddressBookEditorProps) => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");

  const params = useParams();

  const addressId = params.id || "";
  const [loading, setLoading] = useState(isEdit);
  const [address, setAddress] = useState<any>();

  useEffect(() => {
    isEdit && loadAddress();
  }, []);

  const loadAddress = async () => {
    try {
      setLoading(true);

      const res = await getAddressFromBook(addressId);

      setAddress(res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LayoutWithLeftMenu title={isEdit ? "Edit address" : "New address"}>
      {loading ? (
        <BaseLoading text="Waiting for address to load" />
      ) : (
        <FormAppAddressBookEditor address={address} />
      )}
    </LayoutWithLeftMenu>
  );
};
