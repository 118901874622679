import * as React from "react";

export const Question: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M12.38 16.906h1.49a6.9 6.9 0 1 0 0-13.802h-3.865a6.9 6.9 0 0 0-6.9 6.901c0 4.45 2.537 7.111 5.205 8.715a17.33 17.33 0 0 0 4.07 1.753v-3.567Zm1.103 4.969s-.421-.071-1.104-.256C9.503 20.84 2 18.038 2 10.005A8.005 8.005 0 0 1 10.005 2h3.865a8.005 8.005 0 0 1 0 16.01h-.387v3.865Z"
      clipRule="evenodd"
    />
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M13.975 5.579c.568.377.997.99.997 1.81 0 .872-.351 1.512-.92 1.904a2.496 2.496 0 0 1-.735.34v.845a.828.828 0 1 1-1.656 0V8.934a.828.828 0 0 1 .773-.827c.306-.02.538-.08.677-.177a.386.386 0 0 0 .133-.15.864.864 0 0 0 .072-.39c0-.174-.068-.306-.258-.433-.217-.143-.565-.25-.982-.262-.414-.014-.815.067-1.11.21-.296.143-.405.305-.435.423a.83.83 0 0 1-1.605-.411c.19-.74.745-1.224 1.318-1.502.574-.279 1.249-.397 1.884-.376.631.02 1.304.179 1.847.54Z"
      clipRule="evenodd"
    />
    <path
      fill="#284492"
      d="M13.593 13.594a1.104 1.104 0 1 1-2.208 0 1.104 1.104 0 0 1 2.208 0Z"
    />
  </svg>
);
