import React from "react";

import { styled } from "@mui/system";

import brandColors from "../../../../css/brandColors.scss";

const StyledWrapper = styled("div")(
  () => `
  border: 1px solid ${brandColors.lightestBlue};
  border-radius: 5px;
  padding: 24px 24px 32px 24px;
`,
);

const StyledTitle = styled("div")(
  () => `
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: ${brandColors.mainBlue};
`,
);

type Props = {
  children: React.ReactNode;
  title: string;
  className?: string;
};

const CardWrapper: React.FC<Props> = (props) => {
  return (
    <StyledWrapper className={props.className}>
      <StyledTitle>{props.title}</StyledTitle>

      {props.children}
    </StyledWrapper>
  );
};

export default CardWrapper;
