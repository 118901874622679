import React, { useState } from "react";
import Lottie from "lottie-react";
import { SwitchBtnDomestic } from "./SwitchBtnDomestic";
import startHomepage from "../../../../assets/lottie/start-homepage.json";
import subsequentHomepage from "../../../../assets/lottie/subsequent-homepage.json";
import styles from "./formHome.scss";

import cup from "../../../../assets//home-page/cup.png";
import ChipsByHand from "../../../../assets//home-page/chips-by-hand.png";

import "./styles/styles.css";

interface TopHomePageProps {
  onChangeDomesticShipping(): void;
}

export const TopHomePage = ({ onChangeDomesticShipping }: TopHomePageProps) => {
  const [lottieProps, setLottieProps] = useState({
    loop: false,
    animationData: startHomepage,
  });

  return (
    <div className={styles["top-home-page"]} dir="rtl">
      <div className={styles.top}>
        <div className={styles.topLeft}>
          <h1 className={styles.topTitle}>
            היי! תראו אותנו - אנחנו הכי גלובליים שיש!
          </h1>
          {/* <h1 className={styles.topTitle2}>
            היי! תראו אותנו - אנחנו הכי גלובליים שיש!
          </h1> */}

          <div className={styles.subTitle}>
            השוו תעריפים והשתמשו בשילוח מהיר בכל רחבי העולם!
          </div>

          <div className={styles.topSubTitle}>
            <div className={styles.topItem}>
              <img className={styles.topImg} src={ChipsByHand} />

              <div className={styles.topItemText}>
                <div>פלטפורמה אוטומטית לחלוטין</div>
                <div>המופעלת באמצעות בינה מלאכותית</div>
              </div>
            </div>

            <div className={styles.topItem}>
              <img className={styles.topImg} src={cup} />

              <div className={styles.topItemText}>
                <div>פלטפורמה מבוססת</div>
                <div>AI עם מגע אנושי</div>
              </div>
            </div>
          </div>
        </div>

        <Lottie
          className={styles.topLottie}
          {...lottieProps}
          onComplete={() => {
            setLottieProps({
              loop: true,
              animationData: subsequentHomepage,
            });
          }}
        />
      </div>

      <SwitchBtnDomestic onChangeDomesticShipping={onChangeDomesticShipping} />
    </div>
  );
};
