import { Name } from "../components/BaseIcon";

export const FIELD_IS_REQUIRED = "Field is required";

export const SPECIAL_CHARS = "!@#$%^&*+=";

export interface IMenuItem {
  label: any;
  icon?: Name;
  to?: string;
  value?: string;
  color?: string;
  id?: string;
  goTo?: Function;
}

export const blockedCountriesCodes = [
  "AF",
  "BY",
  "BI",
  "CK",
  "CU",
  "KI",
  "KP",
  "MS",
  "MM",
  "NR",
  "RE",
  "RU",
  "SC",
  "TC",
  "VA",
  "VG",
];

export const domesticCountriesCodes = [
  "US",
  "IL",
  "CA",
  "AD",
  "AT",
  "BE",
  "CY",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "MC",
  "ME",
  "NL",
  "PT",
  "SM",
  "SK",
  "SI",
  "ES",
  "VA",
];

export const countriesWithStates = ["AU", "CA", "US"];

export const defaultCountryNameHongKong = "Hong Kong SAR China";
