import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/system";

import { BaseButton } from "../../../../components/BaseButton";
import { BaseInput } from "../../../../components/BaseInput";
import { Name } from "../../../../components/BaseIcon";

import { selectIsLoading } from "../../../../data/selectors";
import { resetPassword } from "../../data/actions";

import styles from "./styles/ResetPasswordTabContent.scss";

const StyledRoot = styled("div")(
  () => `
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`,
);

const StyledText = styled("div")(
  () => `
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #284492;
  margin-bottom: 24px;
`,
);

const StyledBtnBack = styled(BaseButton)(
  () => `
  margin-top: 24px;
  margin-left: -8px;
`,
);

type Props = {
  onBack(): void;
};

const ResetPasswordTabContent: React.FC<Props> = (props) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);

  const validEmail = (value: any) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    );
  };

  const onSubmit = (): void => {
    // console.log("=onSubmit=", validEmail(email));

    if (validEmail(email)) {
      dispatch(resetPassword(email));

      return;
    }

    setEmailError("Email name entered incorrectly");
  };

  return (
    <StyledRoot>
      <StyledText>
        Bad happens… Enter the email address you registered with and we will
        send you a link to reset your password
      </StyledText>

      <BaseInput
        value={email}
        error={emailError}
        onChange={(e: any) => setEmail(e.target.value)}
        onBlur={() => {
          validEmail(email) && setEmailError("");
        }}
        name="email"
        type="email"
        placeholder="Email"
      />

      <BaseButton
        onClick={onSubmit}
        loading={loading}
        className={styles.sendBtn}
      >
        Send
      </BaseButton>

      <StyledBtnBack clear leftIcon={Name.LeftArrow} onClick={props.onBack}>
        Back
      </StyledBtnBack>
    </StyledRoot>
  );
};

export default ResetPasswordTabContent;
