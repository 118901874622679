import * as React from "react";

export const DropdownArrow: React.FC = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.814 14.86a1 1 0 0 1-1.628 0L8.13 10.582A1 1 0 0 1 8.943 9h6.114a1 1 0 0 1 .814 1.581l-3.057 4.28Z"
    />
  </svg>
);
