import { ModalBase } from "../../../../../../components/ModalBase";
import { BaseButton } from "../../../../../../components/BaseButton";
import Banner from "../../../../../../assets/images/group-631901.svg";
import FlashingLight from "../../../../../../assets/images/flashing-light.png";
import IndexFingerUp from "../../../../../../assets/images/index-finger-up.png";
import styles from "./ModalOnNextStepPackageDetailsCheckout.scss";

export const ModalOnNextStepPackageDetailsCheckout = ({
  visible,
  onSuccess,
  onHide,
}: any) => {
  return (
    <ModalBase
      rootClassName={styles.rootModal}
      className={styles.modal}
      open={visible}
      onClose={() => {
        onHide();
      }}
    >
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.titleSection}>
            <div className={styles.title}>
              <img className={styles.FlashingLight} src={FlashingLight} />

              <div>Important!</div>
            </div>

            <div className={styles.subtitle}>
              Before proceeding, please ensure your customs declaration is
              accurate.
            </div>
          </div>

          <div className={styles.banner}>
            <img src={Banner} />
          </div>
        </div>

        <div className={styles.importantMessages}>
          <div className={styles.IndexFingerUp}>
            <img src={IndexFingerUp} />

            <div className={styles.text}>
              Properly declaring items with their actual value and correct
              description can expedite the customs process.
            </div>
          </div>

          <div className={styles.IndexFingerUp}>
            <img src={IndexFingerUp} />

            <div className={styles.text}>
              Additionally, if you attached your own invoice, verify that the
              declaration matches the invoice details.
            </div>
          </div>
        </div>

        <BaseButton
          className={styles.updateBtn}
          onClick={() => {
            onSuccess();
          }}
        >
          I understand
        </BaseButton>
      </div>
    </ModalBase>
  );
};
