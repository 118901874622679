import * as React from "react";

export const Check: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
  >
    <path
      stroke="#284492"
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M5 10.461 10.727 16 19 8"
    />
  </svg>
);
