import { useDispatch, useSelector } from "react-redux";
import styles from "./SwitchBtnDomestic.scss";
import { selectIsDomesticShipping } from "../../../../data/selectors";
import { setDomesticShipping } from "../../../../data/actions";
import switchDomesticEventInfobip from "../../../../plugins/infobip/switchDomesticEventInfobip";

interface SwitchBtnDomesticProps {
  onChangeDomesticShipping(): void;
}

const btnsProps = [
  {
    label: "International",
    isDomestic: false,
  },
  {
    label: "Domestic",
    isDomestic: true,
  },
];

export const SwitchBtnDomestic = ({
  onChangeDomesticShipping,
}: SwitchBtnDomesticProps) => {
  const dispatch = useDispatch();
  const isDomesticShipping = useSelector(selectIsDomesticShipping);

  const handleChangeDomesticShipping = (isDomestic: boolean) => {
    dispatch(setDomesticShipping(isDomestic));
    onChangeDomesticShipping();

    process.env.REACT_APP_INFOBIP && switchDomesticEventInfobip(isDomestic);
  };

  return (
    <div className={styles["switch-btn-domestic"]}>
      {btnsProps.map((btnProps) => (
        <button
          className={`${styles["switch-btn-domestic__btn"]}${isDomesticShipping === btnProps.isDomestic ? ` ${styles["switch-btn-domestic__btn__active"]}` : ""}`}
          onClick={() => handleChangeDomesticShipping(btnProps.isDomestic)}
          type="button"
        >
          {btnProps.label}
        </button>
      ))}
    </div>
  );
};
