import { useState, useEffect } from "react";
import { LayoutWithLeftMenu } from "../../layouts/leftMenu/LayoutWithLeftMenu";
import { AddNewAddressBtn } from "./AddNewAddressBtn.AppAddressBook";
import { BaseLoading } from "../../components/BaseLoading";
import { ListAddressBook } from "./ListAddressBook";
import { getAddressBookList } from "../../api/commonRequests";

export const AppAddressBook = () => {
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [addressBook, setAddressBook] = useState<any>({
    collection: [],
    meta: {},
  });

  useEffect(() => {
    loadAddressBook();
  }, []);

  const loadAddressBook = async (page: any = 1) => {
    const html = document.getElementsByTagName("html");
    html[0].setAttribute("dir", "ltr");

    try {
      setLoading(true);

      const res = await getAddressBookList({
        page,
        search: searchValue,
      });

      setAddressBook(res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChageSearchValue = (value: string) => {
    setSearchValue(value);
  };

  return (
    <LayoutWithLeftMenu
      searchValue={searchValue || ""}
      title="My addresses"
      suffixToTop={AddNewAddressBtn()}
      onChangeSearchValue={handleChageSearchValue}
      onSearch={loadAddressBook}
    >
      {loading ? (
        <BaseLoading text="Getting a list of the address book" />
      ) : (
        <ListAddressBook
          addressBook={addressBook}
          onChangePage={loadAddressBook}
        />
      )}
    </LayoutWithLeftMenu>
  );
};
