import { useEffect, useState } from "react";

import { BaseButton } from "../../../../../../../components/BaseButton";
import {
  Accordion,
  AccordionCard,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryText,
  StyledAccordionPreviewText,
} from "../../../../AccordionItems/Accordion";

import { getPickupDetailsLabel } from "../PreviewCardLabel";
import { ContactForm } from "../../../../ContactForm/ContactForm";

import { FormProvider, useForm } from "react-hook-form";
import { getDefaultFormValues } from "./services/getDefaultFormValues";
import { validationSchema } from "./services/validation";
import { useDispatch } from "react-redux";
import {
  saveAddressForm,
  setAddressToFormErrors,
  updateShipment,
  setHasSkippedValidationToAddressTo,
} from "../../../../../data/actions";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../../../../../../data/selectors";
import { setFormErrors } from "../../../../../../../services/formErrors/setFormErrors";
import { StyledActionFooter } from "../styles/AccordionItems.styled";
import { BaseCheckbox } from "../../../../../../../components/BaseCheckbox";
import {
  selectAddressToFormErrors,
  selectHasSkippedValidationFromAddressTo,
  selectPostalCodeFromAddressTo,
} from "../../../../../data/selectors";

import { getTransformedAddressFormValesToSubmit } from "../../../../../services/getTransformedAddressFormValesToSubmit";

import formStyles from "../../../Form.scss";
import styles from "./DropOffDetails.scss";
import { ShipmentFormNames } from "../../../../../data/constants";
import { createAddressToBook } from "../../../../../../../api/commonRequests";
import { submitToGoogleAnalyticsOnSubmitStep } from "../../../submitToGoogleAnalytics.FormCheckout";
import { countriesWithStates } from "../../../../../../../constants";
import nextStepInCheckoutEventInfobip from "../../../../../../../plugins/infobip/nextStepInCheckoutEventInfobip";
import getShortShipmentIdInfobip from "../../../../../../../plugins/infobip/getShortShipmentIdInfobip";
import { geocodeByAddressHandler } from "../../../../../../../services/geocodeByAddressHandler";

const DropOffDetails: React.FC<any> = (props) => {
  const [isSaveAddress, setIsSaveAddress] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: getDefaultFormValues(props.shipment),
    resolver: validationSchema,
  });

  // console.log("=methods", methods.getValues("address_to"));

  const dispatch = useDispatch();

  const disabledNextBtn = (): boolean => {
    if (
      methods.watch("address_to.first_name") &&
      methods.watch("address_to.last_name") &&
      methods.getValues("address_to.city_name") &&
      methods.getValues("address_to.street_1") &&
      methods.watch("address_to.postal_code") &&
      // methods.watch('address_to.street_2') &&
      methods.watch("address_to.phone") &&
      methods.getValues("address_to.email") &&
      (methods.watch("address_to.is_company")
        ? methods.watch("address_to.company_name") &&
          methods.watch("address_to.company_ein")
        : methods.watch("address_to.passport"))
    ) {
      if (
        countriesWithStates.includes(
          methods.getValues("address_to.country_code")
        )
      ) {
        return methods.getValues("address_to.state");
      }

      return true;
    }

    return false;
  };

  const isCompanyChecked = methods.watch("address_to.is_company");
  const loading = useSelector(selectIsLoading);
  const postalCodeValidation = useSelector(
    selectHasSkippedValidationFromAddressTo
  );
  const postalCode = useSelector(selectPostalCodeFromAddressTo);
  const formErrors = useSelector(selectAddressToFormErrors);
  const [
    showPostalCodeValidationCheckbox,
    setShowPostalCodeValidationCheckbox,
  ] = useState(postalCodeValidation);

  const postalCodeError = formErrors?.find(
    (el: any) => el.fieldName === "address_to.postal_code"
  );

  useEffect(() => {
    setFormErrors({
      formErrors,
      methods,
      formName: ShipmentFormNames.AddressTo,
    });

    if (postalCodeError) {
      setShowPostalCodeValidationCheckbox(true);
    }
  }, [formErrors]);

  useEffect(() => {
    postalCode !== methods.watch("address_to.postal_code") &&
      dispatch(setHasSkippedValidationToAddressTo(false));
  }, [methods.watch("address_to.postal_code")]);

  useEffect(() => {
    if (postalCodeValidation) {
      methods.clearErrors("address_to.postal_code");
    } else {
      setFormErrors({
        formErrors,
        methods,
        formName: ShipmentFormNames.AddressTo,
      });
    }
  }, [postalCodeValidation]);

  const saveAddressFormFn = () =>
    new Promise((resolve, reject) => {
      setShowPostalCodeValidationCheckbox(postalCodeValidation);

      dispatch(
        saveAddressForm({
          shipmentId: props.shipmentId,
          addressForm: getTransformedAddressFormValesToSubmit({
            methods,
            formName: ShipmentFormNames.AddressTo,
          }),
          skipValidation: postalCodeValidation,
          setFormErrorAction: setAddressToFormErrors,
          resolve,
          reject,
        })
      );
    });

  const onSubmit = async (formValues: any) => {
    // console.log("=onSubmit=", formValues);

    const coordinates: any = await geocodeByAddressHandler(
      formValues["address_to"]
    );
    // console.log("=coordinates=", coordinates);

    formValues["address_to"].lat = coordinates.lat;
    formValues["address_to"].lng = coordinates.lng;

    // console.log("=formValues=", formValues);

    let requests = [saveAddressFormFn()];

    isSaveAddress &&
      requests.push(createAddressToBook(formValues["address_to"]));

    Promise.all(requests).then(() => {
      dispatch(updateShipment(formValues));

      setIsSaveAddress(false);

      process.env.REACT_APP_GOOGLE_TAG_MANAGER &&
        submitToGoogleAnalyticsOnSubmitStep({
          shipment: props.shipment,
          isCompany: formValues.address_to.is_company,
          event: "begin_checkout",
          var_step: "Drop off address",
        });

      props.onNextStep();
      process.env.REACT_APP_INFOBIP &&
        nextStepInCheckoutEventInfobip(
          "drop_address",
          3,
          getShortShipmentIdInfobip(props.shipment.object_id)
        );
    });
  };

  const handleSelectAddressBook = (address: any) => {
    methods.setValue("address_to.first_name", address.first_name);
    methods.setValue("address_to.last_name", address.last_name);
    methods.setValue("address_to.phone", address.phone);
    methods.setValue("address_to.email", address.email);
    methods.setValue("address_to.passport", address.passport);
    methods.setValue("address_to.company_name", address.company_name);
    methods.setValue("address_to.company_ein", address.company_ein);
    methods.setValue("address_to.state", address.state);
    methods.setValue("address_to.city_name", address.city_name);
    methods.setValue("address_to.street_1", address.street_1);
    methods.setValue("address_to.street_2", address.street_2);
    methods.setValue("address_to.postal_code", address.postal_code);
    methods.setValue("address_to.lat", address.lat);
    methods.setValue("address_to.lng", address.lng);

    methods.setValue(
      "address_to.is_company",
      !!methods.getValues("address_to.company_name")
    );
  };

  const formIsActive = props.expanded === props.name;

  return (
    <AccordionCard>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Accordion
            expanded={formIsActive}
            onChange={props.onChangeAccordion(props.name)}
            disabled={props.disabled}
          >
            <AccordionSummary disabled={props.disabled} expanded={formIsActive}>
              <AccordionSummaryText>
                Drop off address
                {methods.watch("address_to.street_1") ||
                methods.watch("address_to.city_name") ||
                methods.watch("address_to.state") ||
                methods.watch("address_to.postal_code") ||
                methods.watch("address_to.country")
                  ? ":"
                  : ""}
                <StyledAccordionPreviewText expanded={formIsActive}>
                  {getPickupDetailsLabel(methods.watch("address_to"))}
                </StyledAccordionPreviewText>
              </AccordionSummaryText>
            </AccordionSummary>

            <AccordionDetails>
              <ContactForm
                formValues={methods.watch()}
                methods={methods}
                formIsActive={formIsActive}
                // prefixToSearchString={props.shipment.address_to?.city_name + (props.shipment.address_to?.state ? `, ${props.shipment.address_to.state}` : '')}
                change={(fieldName: any, value: any) => {
                  methods.setValue(fieldName, value);
                }}
                isCompanyChecked={isCompanyChecked}
                formName="address_to"
                withEmail
                countryLocked
                onSelectAddressBook={handleSelectAddressBook}
              />

              <StyledActionFooter>
                <div>
                  <BaseCheckbox
                    style={{ marginRight: "0" }}
                    checked={isSaveAddress}
                    onChange={(e: any) => {
                      setIsSaveAddress(e.target.checked);
                    }}
                  />

                  <span>
                    Save this address in the{" "}
                    <a
                      style={{
                        color: "#284492",
                        fontWeight: "600",
                        textDecoration: "none",
                      }}
                      href="/address-book"
                    >
                      address book
                    </a>
                  </span>
                </div>

                {showPostalCodeValidationCheckbox && (
                  <BaseCheckbox
                    className={formStyles.formCheckboxWrapper}
                    checked={postalCodeValidation}
                    onChange={(e: any) =>
                      dispatch(
                        setHasSkippedValidationToAddressTo(
                          !postalCodeValidation
                        )
                      )
                    }
                    label="The postal code of this address cannot be validated, but I know it is correct. Please use this address for shipping."
                  />
                )}

                <BaseButton
                  className={styles.submitBtn}
                  loading={loading}
                  type="submit"
                  // disabled={!disabledNextBtn()}
                >
                  NEXT STEP
                </BaseButton>
              </StyledActionFooter>
            </AccordionDetails>
          </Accordion>
        </form>
      </FormProvider>
    </AccordionCard>
  );
};

export default DropOffDetails;
