import LogoIcon from "../../../assets/logo.svg";

import React from "react";
import { styled } from "@mui/system";
import { AppBar, Toolbar } from "@mui/material";

import brandColors from "../../../css/brandColors.scss";
import TheHeaderExportVariables from "../TheHeaderExportVariables.scss";

type TheHeaderWrapperProps = {
  children: React.ReactNode;
};

export const headerHeightWithMargin =
  TheHeaderExportVariables.headerHeightWithMargin;

export const TheHeaderWrapper = styled(
  ({ children }: TheHeaderWrapperProps) => (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: "#fff" }}
    >
      {children}
    </AppBar>
  ),
)(
  () => `
   color: ${brandColors.mainBlue};
   background: ${brandColors.whiteColor};
 `,
);

const transformedHamburgerIconStyles = `
 & span {
  &:nth-of-type(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  &:nth-of-type(2) {
    width: 16px;
    opacity: 0;
  }

  &:nth-of-type(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
 }
`;

type StyledMobileHamburgerProps = {
  isOpen?: boolean;
};

export const StyledMobileHamburger = styled("div")<StyledMobileHamburgerProps>(
  ({ isOpen }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;

  @media (min-width: ${TheHeaderExportVariables.mediaMinWidthMenuHeader}) {
   display: none;
 }

  ${isOpen && transformedHamburgerIconStyles};
`,
);

export const StyledHamburgerLine = styled("span")`
  width: 24px;
  height: 2px;
  background-color: #284492;
  margin: 3px;
  transition: all 0.2s ease;
`;

type StyledToolbarProps = {
  children: React.ReactNode;
};

export const StyledToolbar = styled(({ children }: StyledToolbarProps) => (
  <Toolbar
    sx={{ display: "flex", justifyContent: "space-between" }}
    disableGutters
  >
    {children}
  </Toolbar>
))(
  () => `
   display: flex;
   justify-content: space-between;
   align-items: center;
`,
);

export const Logo = styled(() => (
  <img
    style={{ paddingTop: "3px" }}
    src={LogoIcon}
    alt="Shipper Global, Israel parcel shipping service"
  />
))(
  () => `
    @media(max-width: 768px) {
      flex-grow: 1;
    }
  `,
);
