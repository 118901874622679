import * as React from "react";

export const UserInSquare: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
  >
    <path
      fill="#284492"
      fillRule="evenodd"
      stroke="#A8B6D2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      d="M7.055 16.055A2.75 2.75 0 0 1 9 15.25h6A2.75 2.75 0 0 1 17.75 18a.75.75 0 0 1-1.5 0A1.25 1.25 0 0 0 15 16.75H9A1.25 1.25 0 0 0 7.75 18a.75.75 0 0 1-1.5 0c0-.73.29-1.429.805-1.945Z"
      clipRule="evenodd"
    />
    <path
      fill="#284492"
      fillRule="evenodd"
      stroke="#A8B6D2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      d="M5 4.75c-.69 0-1.25.56-1.25 1.25v14c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25V6c0-.69-.56-1.25-1.25-1.25H5ZM2.25 6A2.75 2.75 0 0 1 5 3.25h14A2.75 2.75 0 0 1 21.75 6v14A2.75 2.75 0 0 1 19 22.75H5A2.75 2.75 0 0 1 2.25 20V6Z"
      clipRule="evenodd"
    />
    <path
      fill="#284492"
      fillRule="evenodd"
      stroke="#A8B6D2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      d="M12 8.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM9.25 10a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM8 1.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0V2A.75.75 0 0 1 8 1.25Zm8 0a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
