import { createTheme } from "@mui/material";
// import { teal, grey } from '@mui/material/colors'
import brandColors from "./brandColors.scss";
import breakpoints from "./breakpoints.scss";

export const theme = createTheme({
  palette: {
    primary: {
      main: brandColors.mainBlue,
    },
  },
  breakpoints: {
    values: {
      xs: +breakpoints.xs,
      sm: +breakpoints.sm,
      md: +breakpoints.md,
      lg: +breakpoints.lg,
      xl: +breakpoints.xl,
    },
  },
  typography: {
    fontFamily: "Open San, sans-serif",
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "lg",
      },
      styleOverrides: {
        root: {
          "@media (max-width: 600px)": {
            padding: "0 20px",
          },
          "@media (max-width: 400px)": {
            padding: "0 14px",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "inherit",
        },
        root: {
          justifyContent: "center",
          minHeight: "72px",
          boxShadow: "none",
          borderBottom: "1px solid #E5E9F3;",

          "@media (max-width: 768px)": {
            minHeight: "72px",
          },
        },
      },
    },
  },
});
