import { styled } from "@mui/system";
import { BaseInput } from "../BaseInput";

type StyledRootProps = {
  positionStatic?: boolean;
};

export const StyledRoot = styled("div")<StyledRootProps>(
  ({ positionStatic }) => `
  position: ${positionStatic ? "static" : "relative"};
`,
);

export const StyledRootSelectionRequired: any = styled("div")`
  width: 100%;
  height: 100%;
`;

export const StyledBaseInput: any = styled(BaseInput)(
  ({ rtl }) => `
  height: 100%;

  & .MuiInput-underline:before {
    border-bottom: none !important;
  }

  & .MuiInput-underline:after {
    border-bottom: none !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
    padding: 0;
  }

  & .MuiOutlinedInput-input {
    padding-left: 0;
  }

  & .MuiInputLabel-formControl {
    ${rtl ? "right: 14px" : "left: -14px"};
    ${rtl ? "left: initial" : ""};
    padding: 0 10px;
    padding-left: 10px !important;
    border-radius: 8px;
  }

  & .MuiInputLabel-shrink {
    background-color: #e5e9f3;
    transition: background-color 0.2 cubic-bezier(0.0, 0, 0.2, 1);
    padding-bottom: 4px;
  }
`,
);
