import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./data/store";
import { history } from "./services/history";

import Root from "./Root";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./services/notifications/Notifications";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./ReCaptcha.css";

const startApp = () => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    process.env.REACT_APP_IS_MOBILE_APP === "true" ? (
      // <React.StrictMode>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <SnackbarUtilsConfigurator />
          <Root />
        </SnackbarProvider>
      </Provider>
    ) : (
      // </React.StrictMode>
      // <React.StrictMode>
      <GoogleReCaptchaProvider
        reCaptchaKey="6LfJYEIjAAAAAGdtsvWHwVMs_iXSvmnOcx8zASXA"
        scriptProps={{ async: true }}
      >
        <Provider store={store}>
          <SnackbarProvider maxSnack={3}>
            <SnackbarUtilsConfigurator />
            <Root />
          </SnackbarProvider>
        </Provider>
      </GoogleReCaptchaProvider>
    )
    // </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};

function handleOpenURL(url: string) {
  setTimeout(function () {
    url = url.replace("shipperglobalapp:/", "");

    history.push(url);
  }, 0);
}

// if ("cordova" in globalThis) {
if (process.env.REACT_APP_IS_MOBILE_APP === "true") {
  document.addEventListener("deviceready", startApp, false);

  cordova.addStickyDocumentEventHandler("handleopenurl");
  globalThis.handleOpenURL = handleOpenURL;
} else {
  startApp();
}
