import { handleActions } from "redux-actions";

import * as actions from "./actions";

import { REDUCER_NAME } from "./constants";

const initialState = {
  isResetTokenValid: false,
};

const reducer = {
  [actions.showLoader.toString()]: (state: any) => {
    return {
      ...state,
      loading: true,
    };
  },
  [actions.hideLoader.toString()]: (state: any) => {
    return { ...state, loading: false };
  },
};

export const profileReducer = {
  [REDUCER_NAME]: handleActions(reducer, initialState),
};
