import { Container } from "@mui/material";
import { styled } from "@mui/system";

import { Breakpoints, media } from "../../../../../css/breakpoints";

export const StyledTab = styled("div")(
  () => `
   width: 100%;
   overflow-x: hidden;
`,
);

type StyledContainerProps = {
  children: React.ReactNode;
};

export const StyledContainer = styled(({ children }: StyledContainerProps) => (
  <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // maxWidth: '1350px !important',
      position: "relative",
      paddingLeft: "24px !important",
      paddingRight: "24px !important",
    }}
  >
    {children}
  </Container>
))(
  () => `
`,
);

export const StyledForm = styled("form")(
  () => `
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   margin: auto;
 `,
);

export const StyledInputGroupWrapper = styled("div")`
  display: flex;
  justify-content: center;
  gap: 34px 16px;

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    flex-direction: column;
    width: 100%;
    max-width: 382px;
    flex-wrap: wrap;
  }
`;

export const TextInCard = styled("div")(
  () => `
//  color: #1D4596;
  font-size: 16px;
`,
);

export const StyledParcelSizesWrapper = styled("ul")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media ${media(Breakpoints.Tablet)} {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media ${media(Breakpoints.Mobile)} {
    padding: 0;
  }

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;
export const StyledDeleteIcon = styled("button")<{ isVisible?: boolean }>`
  margin-top: 2px;
  width: 16.25px;
  height: 16.25px;
  border-radius: 50%;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  color: #e5e9f3;
`;
