import { useState, useEffect } from "react";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { SelectBaseController } from "../../../components/SelectBaseController";
import auStates from "./AU-states.json";
import caStates from "./CA-states.json";
import usStates from "./US-states.json";

interface StateSelectProps {
  style?: CSSProperties;
  disableClearable?: boolean;
  name: string;
  countryCode: string;
  value: string;
  disabled?: Boolean;
  register?: any;
  onChange(state: string): void;
}

const states: any = {
  AU: auStates,
  CA: caStates,
  US: usStates,
};

const getState = (countryCode: string, value: string) => {
  // console.log("=getState=", countryCode, value, states?.[countryCode]
  // ?.find((state: any) => state.value === value)
  // );

  return states?.[countryCode]?.find((state: any) => state.value === value);
};

export const StateSelect = ({
  style,
  disableClearable,
  name,
  countryCode,
  value,
  disabled,
  register,
  onChange,
}: StateSelectProps) => {
  // console.log("=StateSelect=", countryCode, value);

  const [optionsSelect, setOptionsSelect] = useState(states[countryCode]);
  const [innerValue, setInnerValue] = useState(getState(countryCode, value));

  useEffect(() => {
    // console.log("=useEffect states=", states);

    setOptionsSelect(states[countryCode]);
  }, [countryCode]);

  useEffect(() => {
    // console.log("=useEffect innerValue state=", states);

    setInnerValue(getState(countryCode, value));
  }, [value]);

  // console.log("=optionsSelect=", optionsSelect, value, innerValue);

  return (
    <SelectBaseController
      style={style}
      name={name}
      value={innerValue}
      options={optionsSelect}
      disableClearable={disableClearable}
      register={register}
      label="State"
      noOptionsText="No states"
      onChange={(value) => {
        // console.log("=onChange=", value);
        onChange(value?.value || null);
      }}
    />
  );
};
