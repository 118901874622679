import React from "react";

import { styled } from "@mui/system";

import brandColors from "../../css/brandColors.scss";
import { BaseButton } from "../BaseButton";

const StyledRoot = styled("div")(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
`,
);

const StyledImage = styled("img")(
  () => `
  max-width: 220px;
  margin-bottom: 40px;
  width: 100%;
`,
);

const StyledTitle = styled("div")(
  () => `
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: ${brandColors.mainBlue};
  margin-bottom: 8px;
`,
);

const StyledDescription = styled("div")(
  () => `
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: ${brandColors.lightBlueColor};
  margin-bottom: 40px;
`,
);

type Props = {
  img: string;
  title: string;
  description?: string;
  btnLabel: string;
  onClick(): void;
};

const BaseZeroscreen: React.FC<Props> = (props) => {
  return (
    <StyledRoot>
      <StyledImage src={props.img} />
      <StyledTitle>{props.title}</StyledTitle>
      <StyledDescription>{props.description}</StyledDescription>

      <BaseButton onClick={props.onClick}>{props.btnLabel}</BaseButton>
    </StyledRoot>
  );
};

export default BaseZeroscreen;
