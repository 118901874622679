import { history } from "../services/history";
import { getStore } from "./handleBrowserStore";

export default {
  _location:
    process.env.REACT_APP_IS_MOBILE_APP === "true"
      ? globalThis.location
      : history.location,
  url: getStore(),
};
