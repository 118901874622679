import { useDispatch, useSelector } from "react-redux";
import { selectRtl } from "../../../../data/selectors";
import styles from "./SwitchBtnDomestic.scss";
import { selectIsDomesticShipping } from "../../../../data/selectors";
import { setDomesticShipping } from "../../../../data/actions";

interface SwitchBtnDomesticProps {
  onChangeDomesticShipping(): void;
}

export const SwitchBtnDomestic = ({
  onChangeDomesticShipping,
}: SwitchBtnDomesticProps) => {
  const dispatch = useDispatch();
  const rtl = useSelector(selectRtl);
  const isDomesticShipping = useSelector(selectIsDomesticShipping);

  const btnsProps = [
    {
      label: rtl ? "בינלאומי" : "International",
      isDomestic: false,
    },
    {
      label: rtl ? "מקומי" : "Domestic",
      isDomestic: true,
    },
  ];

  const handleChangeDomesticShipping = (isDomestic: boolean) => {
    dispatch(setDomesticShipping(isDomestic));
    onChangeDomesticShipping();
  };

  return (
    <div className={styles["switch-btn-domestic"]}>
      {btnsProps.map((btnProps) => (
        <button
          className={`${styles["switch-btn-domestic__btn"]}${isDomesticShipping === btnProps.isDomestic ? ` ${styles["switch-btn-domestic__btn__active"]}` : ""}`}
          onClick={() => handleChangeDomesticShipping(btnProps.isDomestic)}
          type="button"
        >
          {btnProps.label}
        </button>
      ))}
    </div>
  );
};
