import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormGetBestPricePopup } from "./FormGetBestPricePopup";
import { AfterSendingGetBestPricePopup } from "./AfterSendingGetBestPricePopup";
import { BaseModal } from "../BaseModal";
import { selectRtl } from "../../data/selectors";
import { useIsResponsive } from "../../services/breakpoint/useIsResponsive";
import Banner from "../../assets/home-page/shipper-man-with-box.svg";
import BannerMobile from "../../assets/home-page/Group 631785.svg";
import HeTr from "../../assets/home-page/he-tr.svg";
import HeTrMobile from "../../assets/home-page/he-tr-mobile.svg";
import HeTrRtl from "../../assets/home-page/he-tr-rtl.svg";
import HeTrRtlMobile from "../../assets/home-page/he-tr-rtl-mobile.svg";
// import Turk from '../../assets/home-page/Frame 81513748.svg'
// import Is from '../../assets/home-page/Frame.svg'
import styles from "./GetBestPricePopup.scss";

export const checkTurkeyAndIsrael = (shipment: any) => {
  const selectedCountryCodes: string[] = [
    shipment?.locationFrom?.country_code,
    shipment?.locationTo?.country_code,
  ];

  // console.log("=checkTurkeyAndIsrael=", shipment, selectedCountryCodes);

  return (
    selectedCountryCodes.includes("IL") && selectedCountryCodes.includes("TR")
  );
};

export const GetBestPricePopup = (props: any) => {
  let rtl = useSelector(selectRtl);
  const { innerWidth } = useIsResponsive();
  const [bannerFromTo, setBannerFromTo] = useState("");
  const [isSent, setIsSent] = useState(false);
  // const [isSent, setIsSent] = useState(true)

  const onSuccess = () => {
    // console.log("=onSuccess=",);

    setIsSent(true);
  };

  useEffect(() => {
    // console.log("=console=", rtl, innerWidth);

    if (rtl) {
      setBannerFromTo(innerWidth < 850 ? HeTrRtlMobile : HeTrRtl);
    } else {
      setBannerFromTo(innerWidth < 850 ? HeTrMobile : HeTr);
    }
  }, [rtl, innerWidth]);

  return (
    <BaseModal
      className={styles.modal + " " + (isSent ? styles.isSentModal : "")}
      open={props.open}
      onClose={props.onModalHide}
    >
      {isSent ? (
        <AfterSendingGetBestPricePopup />
      ) : (
        <div className={styles.container}>
          <FormGetBestPricePopup
            shipment={props.shipment}
            onSuccess={onSuccess}
          />

          <div className={styles.bannerSection}>
            <img className={styles.fromToSection} src={bannerFromTo} />
            {/* <div className={styles.fromToSection}>
              <img className={styles.fromToSectionFlag} src={Is} />

              <div>
                <div className={styles.fromToText}>
                  FROM <svg xmlns="http://www.w3.org/2000/svg" width="42" height="12" viewBox="0 0 42 12" fill="none">
                    <path d="M41.4301 6.46124C41.6991 6.19221 41.6991 5.75603 41.4301 5.487L37.046 1.10294C36.777 0.833913 36.3408 0.833913 36.0718 1.10294C35.8028 1.37197 35.8028 1.80815 36.0718 2.07718L39.9688 5.97412L36.0718 9.87107C35.8028 10.1401 35.8028 10.5763 36.0718 10.8453C36.3408 11.1143 36.777 11.1143 37.046 10.8453L41.4301 6.46124ZM0.942993 6.66301H40.943V5.28523H0.942993V6.66301Z" fill="#284492"/>
                  </svg> TO
                </div>

                <div className={styles.fromToText}>
                  TO <svg xmlns="http://www.w3.org/2000/svg" width="41" height="11" viewBox="0 0 41 11" fill="none">
                    <path d="M0.455753 4.80915C0.186726 5.07817 0.186726 5.51435 0.455753 5.78338L4.83982 10.1674C5.10884 10.4365 5.54502 10.4365 5.81405 10.1674C6.08308 9.89842 6.08308 9.46224 5.81405 9.19321L1.91711 5.29626L5.81405 1.39932C6.08308 1.13029 6.08308 0.694112 5.81405 0.425085C5.54502 0.156056 5.10884 0.156056 4.83982 0.425085L0.455753 4.80915ZM40.9429 4.60738L0.942871 4.60738V5.98515L40.9429 5.98515V4.60738Z" fill="#284492"/>
                  </svg> FROM
                </div>
              </div>

              <img className={styles.fromToSectionFlag} src={Turk} />
            </div> */}

            <img className={styles.banner} src={Banner} />

            <img className={styles.BannerMobile} src={BannerMobile} />
          </div>
        </div>
      )}
    </BaseModal>
  );
};
