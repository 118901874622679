const initialState = {
  parcelType: "envelope",
  errorModalOpen: false,
};

export const homeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_HOME_DATA":
      return { ...state, home: action.payload };
    case "SET_ERROR_MODAL":
      return { ...state, errorModalOpen: action.payload };
    default:
      return state;
  }
};
