import { IMenuItem } from ".";

import { Name } from "../components/BaseIcon";
import { goToVue } from "../integrationWithVue/goToVue";

export const AUTH_MENU_ITEMS: IMenuItem[] = [
  // {
  //   label: 'Billing address',
  //   icon: Name.UserInSquare,
  //   to: ''
  // },
  {
    label: {
      true: "המשלוחים שלי",
      false: "My shipments",
    },
    icon: Name.Box,
    color: "#284492",
    goTo: (event: any) => goToVue("/my-shipments", event),
  },
  {
    label: {
      true: "פנקס הכתובות",
      false: "Address book",
    },
    icon: Name.Location,
    to: "/address-book",
  },
  // {
  //   label: 'Support',
  //   icon: Name.Question,
  //   to: ''
  // },
  {
    label: {
      true: "להתנתק",
      false: "Log out",
    },
    icon: Name.Exit,
    value: "logOut",
  },
];
