import { Container, Grid, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";

// import { Notification } from './components/Notification'
import { ParcelEditing } from "./components/AccordionItems/ParcelEditing/ParcelEditing";
import { PickupDateCard } from "./components/AccordionItems/PickupDateCard";
import { PickupDetails } from "./components/AccordionItems/PickupDetails";
import { RightPanel } from "../RightPanel";
import { DropOffDetails } from "./components/AccordionItems/DropOffDetails";
import { PackageDetails } from "../AccordionItems/PackageDetails";

import { useSelector, useDispatch } from "react-redux";
import { ShipmentAPI } from "../../../../api";
import { updateShipment } from "../../data/actions";
import { selectShipment } from "../../data/selectors";
import { useSearchParams } from "react-router-dom";
import { BillingTab } from "./components/BillingTab";
import { BillingInfoCard } from "./components/BillingTab/components/BillingInfoCard";
import { selectIsDomesticShipping } from "../../../../data/selectors";
import { submitToGoogleAnalyticsOnSubmitStep } from "./submitToGoogleAnalytics.FormCheckout";
import { ProviderId } from "../../data/models";
import styles from "./Form.scss";

interface FormCheckoutProps {
  shipmentId: string;
}

export const parcelEditingCard = "parcelEditingCard";
const PICKUP_DETAILS_CARD = "pickupDetailsCard";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

export const FormCheckout = ({ shipmentId }: FormCheckoutProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const shipment = useSelector(selectShipment);
  // console.log("=shipment=", shipment);

  const itemWeightMinInPackageDetails =
    shipment.chosen_rate.provider.id === ProviderId.DHL ? 0.002 : 0.001;
  const roundingRankOfItemWeightInParcelInPackageDetails =
    itemWeightMinInPackageDetails.toString().split(".")[1].length; // ! Количество разрядов дробной части. Нужно для округления в дальнейшем
  const getWeightItemErrorMessageInPackageDetails = (
    weight: string | number = 0,
  ) => {
    // console.log("=getWeightItemErrorMessageInPackageDetails=",
    //   weight,
    //   +weight < itemWeightMinInPackageDetails && weight?.toString()?.split('.')?.[1]?.length > roundingRankOfItemWeightInParcelInPackageDetails,
    //   +weight < itemWeightMinInPackageDetails,
    //   weight?.toString()?.split('.')?.[1]?.length,
    //   roundingRankOfItemWeightInParcelInPackageDetails,
    //   weight?.toString()?.split('.')?.[1]?.length > roundingRankOfItemWeightInParcelInPackageDetails
    // );

    if (!weight) return;
    if (+weight < itemWeightMinInPackageDetails)
      return `Min value is ${itemWeightMinInPackageDetails}`;
    if (
      weight.toString().split(".")[1]?.length >
      roundingRankOfItemWeightInParcelInPackageDetails
    )
      return `Limit decimal places to ${roundingRankOfItemWeightInParcelInPackageDetails}`;

    return;
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [isHidePackageDetailsCard, setIsHidePackageDetailsCard] = useState(
    !shipment?.is_customs_declarable,
  );

  const [expanded, setExpanded] = React.useState<any>(
    // 'pickupDateCard' // Для Теста

    searchParams.get("current_tab")
      ? ""
      : searchParams.get("expanded") || PICKUP_DETAILS_CARD,
  );

  const [enabledAccordionCards, setEnabledAccordionCards] = React.useState<any>(
    [PICKUP_DETAILS_CARD],
  );

  const isDomesticShipping = useSelector(selectIsDomesticShipping);

  useEffect(() => {
    !shipment?.address_from?.first_name &&
      process.env.REACT_APP_GOOGLE_TAG_MANAGER &&
      submitToGoogleAnalyticsOnSubmitStep({
        shipment,
        event: "view_cart",
      });
  }, []);

  useEffect(() => {
    setIsHidePackageDetailsCard(!shipment?.is_customs_declarable);

    const array = [parcelEditingCard, PICKUP_DETAILS_CARD];

    if (shipment?.address_from?.first_name) {
      array.push("dropOffDetailsCard");
    }

    if (shipment?.address_to?.first_name) {
      array.push("billingAddress");
    }

    if (shipment?.billing_address?.first_name) {
      isDomesticShipping || isHidePackageDetailsCard
        ? array.push("pickupDateCard")
        : array.push("packageDetailsCard");
    }

    if (shipment?.pickup_details?.ready_time) {
      array.push("pickupDateCard");
    }

    setEnabledAccordionCards(array);
  }, [shipment]);

  const handleExpanded = (
    innerExpanded: string | boolean,
    cardName: string,
  ) => {
    setExpanded(innerExpanded);
    setSearchParams({ expanded: cardName });
  };

  const [packageDetailsFormChanged, setPackageDetailsFormChanged] =
    useState<boolean>(false);
  const [showPackageDetailsClosingModal, setShowPackageDetailsClosingModal] =
    useState<any>({
      show: false,
      goToAccordion: "",
    });

  const handleAccordionChange =
    (cardName: string) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (!newExpanded) return;

      if (packageDetailsFormChanged) {
        setShowPackageDetailsClosingModal({
          show: true,
          goToAccordion: cardName,
        });
        return;
      }

      handleExpanded(newExpanded ? cardName : false, cardName);
    };

  const handleNextStep = (cardName: string): void => {
    setEnabledAccordionCards((oldArray: any) => [...oldArray, cardName]);
    handleExpanded(cardName, cardName);
  };

  const [currentTab, setCurrentTab] = React.useState(
    Number(searchParams.get("current_tab")) || 0,
  );

  const handleChangeIndex = async (index: number): Promise<void> => {
    setCurrentTab(index);

    index === 1 &&
      setSearchParams({
        current_tab: index.toString(),
      });
  };

  useEffect(() => {
    if (currentTab === 1) {
      (async () => {
        const shipmentAdditionalPayments =
          await ShipmentAPI.getShipmentAdditionalPayments(shipmentId);

        dispatch(
          updateShipment({
            additional_payments: shipmentAdditionalPayments,
          }),
        );
      })();
    }
  }, [currentTab, dispatch, shipmentId]);

  return (
    <div style={{ marginTop: "24px" }}>
      {currentTab === 0 ? (
        <TabPanel
          className={styles.tab}
          value={currentTab}
          index={0}
          dir={theme.direction}
        >
          <Container>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={8}
                order={{ xs: 2, md: 1 }}
              >
                {/* <Notification className={styles.notification} /> */}

                <div className={styles.AccordionItemsWrapper}>
                  <ParcelEditing
                    name={parcelEditingCard}
                    expanded={expanded}
                    disabled={
                      !enabledAccordionCards.includes(parcelEditingCard)
                    }
                    onChangeAccordion={handleAccordionChange}
                    shipment={shipment}
                    shipmentId={shipmentId}
                    onNextStep={() => handleNextStep(PICKUP_DETAILS_CARD)}
                  />

                  <PickupDetails
                    name={PICKUP_DETAILS_CARD}
                    expanded={expanded}
                    disabled={
                      !enabledAccordionCards.includes(PICKUP_DETAILS_CARD)
                    }
                    onChangeAccordion={handleAccordionChange}
                    shipment={shipment}
                    // formValues={methods.getValues()}
                    shipmentId={shipmentId}
                    // change={(fieldName: any, fieldValue: any) =>
                    //   methods.setValue(fieldName, fieldValue)
                    // }
                    onNextStep={() => handleNextStep("dropOffDetailsCard")}
                  />

                  <DropOffDetails
                    name="dropOffDetailsCard"
                    expanded={expanded}
                    disabled={
                      !enabledAccordionCards.includes("dropOffDetailsCard")
                    }
                    shipment={shipment}
                    shipmentId={shipmentId}
                    onChangeAccordion={handleAccordionChange}
                    // change={(fieldName: any, fieldValue: any) =>
                    //   methods.setValue(fieldName, fieldValue)
                    // }
                    // formValues={methods.getValues()}
                    onNextStep={() => handleNextStep("billingAddress")}
                  />

                  <BillingInfoCard
                    name="billingAddress"
                    shipment={shipment}
                    shipmentId={shipmentId}
                    expanded={expanded}
                    disabled={!enabledAccordionCards.includes("billingAddress")}
                    onChangeAccordion={handleAccordionChange}
                    onNextStep={() => {
                      isDomesticShipping || isHidePackageDetailsCard
                        ? handleNextStep("pickupDateCard")
                        : handleNextStep("packageDetailsCard");
                    }}
                  />

                  {!(isDomesticShipping || isHidePackageDetailsCard) && (
                    <PackageDetails
                      name="packageDetailsCard"
                      expanded={expanded}
                      disabled={
                        !enabledAccordionCards.includes("packageDetailsCard")
                      }
                      shipment={shipment}
                      onChangeAccordion={handleAccordionChange}
                      // formValues={methods.getValues()}
                      // change={(fieldName: any, fieldValue: any) =>
                      //   methods.setValue(fieldName, fieldValue)
                      // }
                      customsDeclarationId={
                        shipment.customs_declaration?.object_id
                      }
                      showClosingModal={showPackageDetailsClosingModal}
                      getWeightItemErrorMessageInPackageDetails={
                        getWeightItemErrorMessageInPackageDetails
                      }
                      roundingRankOfItemWeightInParcelInPackageDetails={
                        roundingRankOfItemWeightInParcelInPackageDetails
                      }
                      onNextStep={() => {
                        handleNextStep("pickupDateCard");
                      }}
                      onFormChanged={(value: boolean) => {
                        setPackageDetailsFormChanged(value);
                      }}
                      onClosingModal={(goToAccordion: string | undefined) => {
                        goToAccordion &&
                          handleExpanded(goToAccordion, goToAccordion);
                        setShowPackageDetailsClosingModal({
                          show: false,
                          goToAccordion: "",
                        });
                      }}
                    />
                  )}

                  <PickupDateCard
                    name="pickupDateCard"
                    expanded={expanded}
                    disabled={!enabledAccordionCards.includes("pickupDateCard")}
                    onChangeAccordion={handleAccordionChange}
                    // setValue={(fieldName: any, fieldValue: any) =>
                    //   methods.setValue(fieldName, fieldValue)
                    // }
                    shipment={shipment}
                    locationFromCountryCode={
                      shipment?.address_from?.country_code
                    }
                    shipmentId={shipmentId}
                    // formValues={methods.getValues()}
                    onNextStep={() => {
                      handleChangeIndex(1);
                    }}
                  />
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                order={{ xs: 1, md: 2 }}
              >
                <RightPanel shipment={shipment} shipmentId={shipmentId} />
              </Grid>
            </Grid>
          </Container>
        </TabPanel>
      ) : (
        <TabPanel
          className={styles.tab}
          value={currentTab}
          index={1}
          dir={theme.direction}
        >
          <BillingTab
            shipmentId={shipmentId}
            roundingRankOfItemWeightInParcelInPackageDetails={
              roundingRankOfItemWeightInParcelInPackageDetails
            }
            onPrevStep={(cardName: string) => {
              handleNextStep(cardName);
              handleChangeIndex(0);
            }}
          />
        </TabPanel>
      )}
    </div>
  );
};
