// import { createSelector } from 'reselect';

import { IFormErrors, IRoot, IUser } from "../../interfaces";
import { UnitSystem } from "../../services/convertUnitSystemValues";
import { REDUCER_NAME } from "../constants";

export interface IModuleState {
  [REDUCER_NAME]: IRoot;
}

export const selectRootModule = (state: IModuleState): IRoot =>
  state[REDUCER_NAME];

export const selectIsLoading = (state: IModuleState): boolean =>
  selectRootModule(state).loading;

export const selectFormErrors = (state: IModuleState): IFormErrors[] =>
  selectRootModule(state).formErrors;

export const selectUser = (state: IModuleState): IUser =>
  selectRootModule(state).user;

export const selectFirstName = (state: IModuleState): string =>
  selectRootModule(state).user?.first_name;

export const selectLastName = (state: IModuleState): string =>
  selectRootModule(state).user?.last_name;

export const selectFullName = (state: IModuleState): string =>
  selectRootModule(state).user.first_name +
  " " +
  selectRootModule(state).user.last_name;

export const selectEmail = (state: IModuleState): string =>
  selectRootModule(state).user?.email;

export const selectPhone = (state: IModuleState): string =>
  selectRootModule(state).user?.phone;

export const selectIsEmailVerified = (state: IModuleState): boolean =>
  selectRootModule(state).user?.is_email_verified;

export const selectSystemCurrency = (state: IModuleState): string =>
  selectRootModule(state).user?.system_currency;

export const selectUnitSystem = (state: IModuleState): UnitSystem =>
  selectRootModule(state).unitSystem;

export const selectCouponLoading = (state: IModuleState): boolean =>
  selectRootModule(state).couponLoading;

export const selectIsDomesticShipping = (state: IModuleState): boolean =>
  selectRootModule(state).isDomesticShipping;

export const selectRtl = (state: IModuleState): boolean =>
  selectRootModule(state).rtl;

export const selectLocale = (state: IModuleState): string =>
  selectRootModule(state).locale;
