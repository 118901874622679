export const socialProviders = {
  google: "google",
  facebook: "facebook",
  apple: "apple",
} as const;

type SocialProvidersKeys = keyof typeof socialProviders;

export type SocialProvidersValuesType =
  (typeof socialProviders)[SocialProvidersKeys];

export const socialProvidersValues = Object.values(socialProviders);

export const socialLinks = {
  facebook: process.env.REACT_APP_LINK_FACEBOOK,
  instagram: process.env.REACT_APP_LINK_INSTAGRAM,
  linkedin: process.env.REACT_APP_LINK_LINKEDIN,
};
