import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BaseLoading } from "../../../../components/BaseLoading";
import { BaseZeroscreen } from "../../../../components/BaseZeroscreen";

import { selectIsEmailVerified } from "../../../../data/selectors";
import { confirmVerifyProfile } from "../../data/actions";
import { selectProfileLoading } from "../../data/selectors";

import ManError from "../../../../assets/images/man_error.svg";
import ManSuccess from "../../../../assets/images/man_success.svg";
import { selectLocale } from "../../../../data/selectors";

const Verify: React.FC = () => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");
  const locale = useSelector(selectLocale);

  const [searchParams] = useSearchParams();
  const confirmationCode = searchParams.get("confirmation_code");
  const dispatch = useDispatch();
  const loading = useSelector(selectProfileLoading);
  const isEmailVerified = useSelector(selectIsEmailVerified);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      confirmVerifyProfile({
        type: "email",
        confirmation_code: confirmationCode,
      }),
    );
  }, []);

  return (
    <div>
      {loading ? (
        <BaseLoading />
      ) : isEmailVerified ? (
        <BaseZeroscreen
          title="Email successfully verified!"
          description=""
          btnLabel="Go to Home Page"
          onClick={() => navigate(locale !== "en" ? `/${locale}` : "/")}
          img={ManSuccess}
        />
      ) : (
        <BaseZeroscreen
          title="Oops!"
          description="Email we were unable to verify the mail, let's try again"
          btnLabel="Try again"
          onClick={() => navigate("/profile")}
          img={ManError}
        />
      )}
    </div>
  );
};

export default Verify;
