import { styled } from "@mui/system";

import brandColors from "../../../../css/brandColors.scss";
import { Breakpoints, media } from "../../../../css/breakpoints";

const StyledRoot = styled("div")(
  () => `
  background: ${brandColors.whiteColor};
  padding: 40px 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  border-radius: 24px 0px 0px 24px;
  height: 100%;
  min-height: 789px;

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    border-radius: 24px !important;
    padding: 24px 20px;
    min-height: auto;
  }
`,
);

const StyledTitle = styled("div")(
  () => `
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: ${brandColors.mainBlue};
  margin-bottom: 24px;
`,
);

const FormWrapper = (props: any) => {
  return (
    <StyledRoot {...props}>
      <StyledTitle>{props.title}</StyledTitle>

      {props.children}
    </StyledRoot>
  );
};

export default FormWrapper;
