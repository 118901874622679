import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { BaseModal } from "../../../../../../../components/BaseModal";
import { BaseInput } from "../../../../../../../components/BaseInput";
import { SelectBase } from "../../../../../../../components/SelectBase";
import styles from "./CommodityCodeSearchModal.scss";
import {
  getCommodityCodes,
  getCommodityCategories,
  getCodesByCategory,
} from "./commodityCodeSearchModal.api";

interface CommodityCodeSearchModalProps {
  open: boolean;
  packageItem: any;
  countryCode: string;
  onClose(payload: any): void;
}

export const CommodityCodeSearchModal = ({
  open,
  packageItem,
  countryCode,
  onClose,
}: CommodityCodeSearchModalProps) => {
  const [category, setCategory] = useState<any>(
    packageItem?.hs_code?.selected?.category
      ? {
          value: packageItem.hs_code.selected.category,
        }
      : null,
  );
  const [subcategory, setSubcategory] = useState<any>(
    packageItem?.hs_code?.selected?.sub_category
      ? {
          value: packageItem.hs_code.selected.sub_category,
        }
      : null,
  );
  const [subcategory_2, setSubcategory_2] = useState<any>(
    packageItem?.hs_code?.selected?.sub_of_sub_category
      ? {
          value: packageItem.hs_code.selected.sub_of_sub_category,
        }
      : null,
  );
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [subcategory2Options, setSubcategory2Options] = useState([]);

  let _subcategoryOptions: any = [];
  let _subcategory2Options: any = [];

  const [description, setDescription] = useState(packageItem.description || "");
  const [hsCodeList, setHsCodeList] = useState([]);
  const [isCodesNotFound, setIsCodesNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchHint, setIsSearchHint] = useState(false);

  useEffect(() => {
    // console.log("=useEffect=", description, subcategory_2);
    (async () => {
      setIsCodesNotFound(false);

      if (subcategory_2) {
        const categories = await handleGetCommodityCategories();
        const _categoryOptions = generateCategoryOptions(categories);

        setCategoryOptions(_categoryOptions);
        // console.log("=useEffect categoryOptions=", categories, categoryOptions);

        await handleSelectedCategory(
          _categoryOptions.find((item: any) => item.value === category.value),
        );
        await handleSelectedSubcategory(subcategory);
        await handleSelectedSubcategory2(subcategory_2);
      } else if (description.trim().length > 2) {
        getWithDescription();
      } else {
        const categories = await handleGetCommodityCategories();
        setCategoryOptions(generateCategoryOptions(categories));
      }
    })();
  }, []);

  const getWithDescription = async () => {
    // console.log("=getWithDescription=", description);
    if (description.trim().length > 2) {
      setIsLoading(true);
      resetData();

      const categories = await getCommodityCodes(countryCode, description);

      setCategoryOptions(generateCategoryOptions(categories.branch.categories));
      setSubcategoryOptions(
        generateCategoryOptions(categories.branch.sub_categories),
      );
      setSubcategory2Options(
        generateCategoryOptions(categories.branch.sub_of_sub_categories),
      );

      // console.log("=categories.selected.category && categories.selected.sub_category && categories.selected.sub_of_sub_category=", categories.selected.category && categories.selected.sub_category && categories.selected.sub_of_sub_category, categories.selected.category, categories.selected.sub_category, categories.selected.sub_of_sub_category);
      if (
        categories.selected.category &&
        categories.selected.sub_category &&
        categories.selected.sub_of_sub_category
      ) {
        setCategory(
          generateCategoryOptions([
            categories.branch.categories.find(
              (item: any) => item.object_id === categories.selected.category,
            ),
          ])[0],
        );
        setSubcategory(
          generateCategoryOptions([
            categories.branch.sub_categories.find(
              (item: any) =>
                item.object_id === categories.selected.sub_category,
            ),
          ])[0],
        );
        setSubcategory_2(
          generateCategoryOptions([
            categories.branch.sub_of_sub_categories.find(
              (item: any) =>
                item.object_id === categories.selected.sub_of_sub_category,
            ),
          ])[0],
        );
      }

      generateHsCodeList(categories.codes);

      setIsLoading(false);
    } else {
      setIsSearchHint(true);
    }
  };

  const handleGetCommodityCategories = async (parentId: string = "") => {
    // console.log("=handleGetCommodityCategories=", parentId);
    setIsLoading(true);

    const categories = await getCommodityCategories({
      countryCode,
      search: description,
      parentId,
    });
    // console.log("=categories=", categories);
    setIsLoading(false);

    return categories;
  };

  const generateHsCodeList = (codes: any = []) => {
    // console.log("=generateHsCodeList=", codes);

    if (codes?.length) {
      setHsCodeList(codes);
    } else {
      setIsCodesNotFound(true);
    }
  };

  const generateCategoryOptions = (categories: any) => {
    // console.log("=generateCategoryOptions=", categories);

    return categories.map((category: any) => ({
      label: category.name,
      value: category.object_id,
      parent_id: category.parent_id,
    }));
  };

  const handleChangeDescription = (event: any) => {
    // console.log("=handleChangeDescription=", payload.target.value);
    resetData();
    setDescription(event.target.value);
  };

  const handleSelectedCategory = async (selectedCategory: any) => {
    // console.log("=handleSelectedCategory=", selectedCategory);
    resetData();
    setCategory(selectedCategory);

    const categories = await handleGetCommodityCategories(
      selectedCategory.value,
    );
    _subcategoryOptions = generateCategoryOptions(categories);
    // console.log("=handleSelectedCategory _subcategoryOptions=", _subcategoryOptions);

    setSubcategoryOptions(_subcategoryOptions);
  };

  const handleSelectedSubcategory = async (selectedCategory: any) => {
    setHsCodeList([]);
    setIsCodesNotFound(false);
    setSubcategory2Options([]);
    setSubcategory_2(null);
    setSubcategory(
      _subcategoryOptions.length
        ? _subcategoryOptions.find(
            (category: any) => category.value === selectedCategory.value,
          )
        : subcategoryOptions.find(
            (category: any) => category.value === selectedCategory.value,
          ),
    );

    const categories = await handleGetCommodityCategories(
      selectedCategory.value,
    );
    _subcategory2Options = generateCategoryOptions(categories);
    // console.log("=handleSelectedSubcategory categories=", categories);

    setSubcategory2Options(_subcategory2Options);
  };

  const handleSelectedSubcategory2 = async (selectedCategory: any) => {
    setHsCodeList([]);
    setIsCodesNotFound(false);
    setSubcategory_2(
      _subcategory2Options.length
        ? _subcategory2Options.find(
            (category: any) => category.value === selectedCategory.value,
          )
        : subcategory2Options.find(
            (category: any) => category.value === selectedCategory.value,
          ),
    );
    setIsLoading(true);

    const codes = await getCodesByCategory(countryCode, selectedCategory.value);
    // console.log("=handleSelectedSubcategory2 codes=", codes);
    generateHsCodeList(codes);
    setIsLoading(false);
  };

  const resetData = () => {
    setHsCodeList([]);
    setIsCodesNotFound(false);
    setSubcategoryOptions([]);
    setSubcategory2Options([]);
    setCategory(null);
    setSubcategory(null);
    setSubcategory_2(null);
  };

  const handleOnClose = (hsCode: any = undefined) => {
    // console.log("=handleOnClose=", subcategory, subcategory_2);

    const outgoingData: any = {
      description,
      hs_code: {
        category_id: subcategory_2?.value,
        selected: {
          category: category?.value,
          sub_category: subcategory?.value,
          sub_of_sub_category: subcategory_2?.value,
        },
      },
    };

    if (hsCode) {
      outgoingData.hs_code.value = hsCode.hsCode;
      outgoingData.hs_code.id = hsCode.hsCodeId;
    }

    onClose(outgoingData);
  };

  return (
    <BaseModal
      className={styles.modal}
      open={open}
      onClose={() => handleOnClose()}
    >
      <h6 className={styles.title}>HS Code Search</h6>

      <BaseInput
        value={description}
        label="Description"
        withoutErrors
        onChange={handleChangeDescription}
      />

      {isSearchHint && (
        <p className={styles.searchHint}>
          Enter at least 3 letters in the description.
          <br />
          Then the search will start.
        </p>
      )}

      <button className={styles.findBtn} onClick={getWithDescription}>
        Start search by Description
      </button>

      <p className={styles.searchByCategoryText}>or search by category</p>

      <SelectBase
        className={styles.indentBetweenFields}
        value={category?.label ?? ""}
        options={categoryOptions}
        label="Select category"
        noOptionsText="No categories"
        disableClearable
        onChange={handleSelectedCategory}
      />

      <SelectBase
        className={styles.indentBetweenFields}
        value={subcategory?.label ?? ""}
        options={subcategoryOptions}
        disabled={!subcategoryOptions.length}
        label="Select subcategory"
        noOptionsText="No subcategories"
        disableClearable
        onChange={handleSelectedSubcategory}
      />

      <SelectBase
        className={styles.indentBetweenFields}
        value={subcategory_2?.label ?? ""}
        options={subcategory2Options}
        disabled={!subcategory2Options.length}
        label="Select sub of subcategory"
        noOptionsText="No sub of subcategories"
        disableClearable
        onChange={handleSelectedSubcategory2}
      />

      <div className={styles.hsCodeList}>
        {isLoading ? (
          <div className={styles.spinner}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {!!hsCodeList.length && (
              <>
                <div className={styles.messageSelectHsCode}>
                  Please select the HS code that best described your product
                </div>

                <table className={styles.tableHsCodeList}>
                  <thead>
                    <tr>
                      <th>HS code</th>
                      <th>Description</th>
                    </tr>
                  </thead>

                  <tbody>
                    {hsCodeList.map((hsCode: any) => (
                      <tr
                        onClick={() => {
                          handleOnClose({
                            hsCode: hsCode.code,
                            hsCodeId: hsCode.object_id,
                          });
                        }}
                      >
                        <td>{hsCode.code}</td>
                        <td>{hsCode.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}

            {isCodesNotFound && (
              <div className={styles.hrCodesNotFound}>HS codes not found</div>
            )}
          </>
        )}
      </div>
    </BaseModal>
  );
};
