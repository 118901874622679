import * as React from "react";
import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/base/ButtonUnstyled";
import { styled } from "@mui/system";
// import { ButtonBase } from '@mui/material'

import { CircularProgress } from "@mui/material";
import { BaseIcon, Name } from "../BaseIcon";

const isBtnSmall = (size: btnSizes | undefined) => size === "sm";

// TODO: скорее всего border лучше сделать через
// ::after {
//       content: "";
//       position: absolute;
//       border: 1px solid $border-color;
// }

const StyledMainButton = styled(ButtonUnstyled)(
  ({ size, fullWidth, clear, padding, loading }: any) => `
  font-weight: 600;
  background-color: ${clear ? "none" : "#284492"};
  padding: ${
    padding
      ? padding
      : clear
        ? "0"
        : isBtnSmall(size)
          ? "11px 24px"
          : "16px 35px"
  };
  border-radius: ${isBtnSmall(size) ? "8px" : "16px"};
  font-size: ${isBtnSmall(size) ? "14px" : "16px"};
  color: ${clear ? "#284492" : "#fff"};;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  ${!loading && `gap: ${isBtnSmall(size) ? "10px" : "12px"}`};
  text-transform: ${clear ? "initial" : "uppercase"};
  position: relative;
  width: ${fullWidth ? "100%" : "auto"};

  &:hover {
    background: ${clear ? "none" : isBtnSmall(size) ? "#3054B6" : "#284492"}
  }

  &.${buttonUnstyledClasses.active} {
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
  }

  &.${buttonUnstyledClasses.disabled} {
    ${!loading ? "background-color: #DDE4F2 !important" : ""};
    opacity: 1;
  }
`,
);

const StyledSecondaryButton = styled(ButtonUnstyled)(
  () => `
  font-weight: 600;
  background-color: transparent;
  padding: 14px 120px;
  border-radius: 16px;
  color: #fff;
  transition: all 150ms ease;
  cursor: pointer;
  border: 2px solid #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: relative;

  &:hover {
    background-color: #fff;
    color: #284492;
  }

  &.${buttonUnstyledClasses.active} {
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
  }

  &.${buttonUnstyledClasses.disabled} {
  }
`,
);

const StyledRightIcon: any = styled(({ loading, ...props }: any) => {
  return (
    <BaseIcon
      {...props}
      name={props.clear ? props.name : props.name || Name.BrandBox}
      color={
        props.name
          ? props.iconColor
            ? props.iconColor
            : "#fff"
          : props.disabled
            ? "#B7C2DE"
            : "#FF5C5C"
      }
    />
  );
})(
  ({ size, loading, iconSize }: any) => `
  transform:  rotate(${isBtnSmall(size) ? "0deg" : "45deg"});
  position: ${loading ? "absolute" : "static"};
  transition: all 0.1s ease-in;
  cursor: pointer;

  width:${iconSize ? iconSize + "px" : isBtnSmall(size) ? "13px" : "18px"};
  height: ${iconSize ? iconSize + "px" : isBtnSmall(size) ? "13px" : "18px"};
`,
);

const StyledLeftIcon: any = styled(({ loading, ...props }: any) => (
  <BaseIcon {...props} name={props.leftIcon} />
))(
  ({ size, loading, iconSize }: any) => `
  ${!loading && `margin-right: ${isBtnSmall(size) ? "10px" : "12px"}`};
  position: ${loading ? "absolute" : "static"};
  transition: all 0.1s ease-in;
  cursor: pointer;

  width:${iconSize ? iconSize + "px" : isBtnSmall(size) ? "13px" : "18px"};
  height: ${iconSize ? iconSize + "px" : isBtnSmall(size) ? "13px" : "18px"};
`,
);

const StyledSpinner: any = styled(CircularProgress)(
  () => `
  color: #fff;
  // width: 36px !important;
  // height: 36px !important;
  width: 1.3em !important;
  height: 1.3em !important;
  position: absolute;
`,
);

type btnSizes = "md" | "sm";

// interface IProps extends ButtonUnstyledProps {
//   secondaryBtn?: boolean
//   size?: btnSizes
//   loading?: boolean
//   type?: any
//   children?: any
//   onClick?: any
//   disabled?: boolean
//   clear?: boolean
// }

const BaseButton: React.FC<any> = ({ secondaryBtn, loading, ...props }) => {
  // loading = true

  return (
    <>
      {secondaryBtn ? (
        <StyledSecondaryButton {...props}>
          {props.children && <span>{props.label || props.children}</span>}
        </StyledSecondaryButton>
      ) : (
        <StyledMainButton
          {...props}
          disabled={props.disabled || loading}
          loading={loading}
        >
          {props.leftIcon && !loading && (
            <StyledLeftIcon
              loading={loading}
              size={props.size}
              iconColor={props.iconColor}
              iconSize={props.iconSize}
              leftIcon={props.leftIcon}
            />
          )}

          <span style={{ visibility: loading ? "hidden" : "visible" }}>
            {props.label || props.children}
          </span>

          {(props.rightIcon || !props.clear) && !loading && (
            <StyledRightIcon
              loading={loading}
              size={props.size}
              clear={props.clear}
              iconColor={props.iconColor}
              iconSize={props.iconSize}
              name={props.rightIcon}
              disabled={props.disabled}
            />
          )}

          {loading && <StyledSpinner />}
        </StyledMainButton>
      )}
    </>
  );
};

BaseButton.defaultProps = {
  disabled: false,
  loading: false,
  secondaryBtn: false,
  size: "md",
};

export default BaseButton;
