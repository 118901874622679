export const PRIVACY_POLICY_SECTIONS = [
  {
    id: "#section0",
    content: `We respect the privacy of the visitors and/or Users of our Properties and Services, and are committed to protect the personal information that our Users share with us. We believe that you have a right to know our practices regarding the information we may collect and use when you visit and/or use our Services.
    <br />
    <br />
   Capitalized terms which are not defined herein, shall have the meaning ascribed to them in our <a href="/contacts">Terms of Use</a> at (<b>“PRIVACY “TOU”</b>), into which this Privacy Policy is incorporated by reference.
   `,
  },
  {
    id: "#section1",
    title: "Your Consent",
    content: `BY ENTERING INTO, CONNECTING TO, ACCESSING TO, REGISTERING TO AND/OR USING THE PROPERTIES AND/OR SERVICES, YOU AGREE, ON BEHALF OF YOURSELF, WHETHER AS A USER ,SHIPPER OR RECEIVER OF A DELIVERY, AND TO EXTENT PERMITTED BY LAW, ON BEHALF OF OTHERS, SUCH AS A SHIPPER OR A RECEIVER OF A DELIVERY (IF YOU ARE NOT THE SHIPPER OR THE RECEIVER)TO THE TERMS AND CONDITIONS SET FORTH IN THIS PRIVACY POLICY (THE <b>“PRIVACY POLICY”</b>), INCLUDING TO THE COLLECTION, PROCESSING AND TRANSFER OF YOUR AND OTHERS PERSONAL INFORMATION (AS DEFINED BELOW). IF YOU DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT ENTER, CONNECT, REGISTER, ACCESS AND/OR USE THE PROPERTIES AND/OR SERVICES AND YOU ARE REQUESTED TO PROMPTLY ERASE YOUR PROFILE OR ACCOUNT (IF OPENED).`,
  },
  {
    id: "#section2",
    title: "Which information we may collect on our Users?",
    content: `We may collect two types of data and information from our Users:
    <br />
    <br />
   The first type of information is non-identifiable and anonymous information (“<b>Non-personal Information</b>”). <b>We are not aware of the identity of the User from whom we have collected the Non-personal Information.</b> Non-Personal Information is any unconcealed and/or aggregated information which does not enable identification of an individual User, and which is available to us while such User is entering and/or using the Services. Non-personal Information which is being gathered consists of technical information, behavioral information and aggregated information, and may contain, among other things, the activity of the User on the Properties, the type and version of the User’s operating system, computer, mobile device or other device, User's 'click-stream' on the Properties, type of browser, keyboard language, the hardware specifications of such device (including screen resolution), pages visited by Users, searches made by Users, time spent on certain pages of the Properties, etc. 
   The second type of information is personally identifiable information (“<b>Personal Information</b>”). <b>This information may identify an individual, whether you and/or other individual(s) (such as a receiver of a delivery), or may be of a private and/or sensitive nature to you and/or other individuals.</b> This information may include name, address, email address, phone number, geo-location, ID or passport number, company name, tax identification numbers, etc. It may also include financial information, such as your bank account, credit card or debit card details, however, currently we do not collect or store this kind of information. It may also include information about a delivery ordered through our Properties, such as, its nature, type, value, size and weight, or its collection and/or delivery addresses, Taxes’ obligations and payments related thereto and communication with the Third-Party Couriers, competent authorities, the shipper and/or receiver of a delivery or any other person or entity connected to the delivery.
   <br />
    <br />
   For the avoidance of doubt, any Non-Personal Information connected or linked to any Personal Information shall be deemed as Personal Information as long as such connection or linkage exists.
   <br />
    <br />
   <b>We do not collect any Personal Information from you or related to you without your approval, which is obtained, inter alia, through your active acceptance to TOU, including the Privacy Policy and using our services.</b>`,
  },
  {
    id: "#section3",
    title: "How Do We Collect Information on Our Users?",
    content: `There are two main methods we use: 
   <ul>
     <li>
     <b>We collect information through your entry, connection, access and/or use of the Services.</b> In other words, when you are using the Services, we are aware of it and may gather, collect and store the information relating to such usage, either independently or through the help of third-party service providers as specified herein.
     </li>
     <br />
     <br />
     <li>
     <b>We collect information which you provide us voluntarily.</b> For example, we collect Personal information which you voluntarily provide, for example when you register and open an account, whether manually or by using your social media account, when you order a delivery or when you communicate with us. We may gather, collect and store the Personal Information either independently or through the help of other third-parties, such as the Third-Party Couriers. 
     </li>
     <br />
     <br />
     <li>
     <b>We collect information through or from third parties.</b> This may include communications with a shipper and/or receiver of a delivery you have booked through the Properties (if you are not the shipper or the receiver). This may also include, communication with the Third-Party Couriers, Customs administration, or any other competent authority in any applicable jurisdiction, in connection with your delivery and the related services we provide you with. As noted above, we may do so, either independently or by our service providers, such as the Third-Party Couriers
     </li>
   </ul>
  `,
  },
  {
    id: "#section4",
    title: "What are the Purposes of the Collection of Information?",
    content: `Non-personal Information is collected in order to:
   <ul>
     <li>
     Use it for statistical, analysis and research purposes and for customization and improvement of our Services.
     </li>
     <br />
     <li>
     Provide and improve the Services and enable future commercial related services.
     </li>
     <br />
     <li>
     Improve the quality of the Services by formulating, customizing and enhancing the User's experience.
     </li>
     <br />
     <li>
     Learn about the preferences of Users and general trends of using the Services (e.g. understand which features are more popular than others).
     </li>
   </ul>
   <br />
   Personal Information is collected in order to: 
   <br />
   <br />
   <ul>
     <li>
     Operate the Properties and/or provide the Services, including to collect, store and transport a delivery, complete Taxes’ documents and pay Taxes related to a delivery or communicate with any relevant party about a delivery, including the shipper's and/or receiver's of a delivery, Third-Party Courier, Customs administration or any other competent authority;
     </li>
     <br />
     <li>
     Open an account or profile for using the Service.
     </li>
     <br />
     <li>
     Determine geo-location information.
     </li>
     <br />
     <li>
     Enable us to contact Users, including for the purpose of providing them with information about the Services, new features, products or services, advertising, technical assistance and support.
     </li>
     <br />
     <li>
     Direct marketing activities.
     </li>
     <br />
     <li>
     Verify the User's identity, including when a User signs into the Services, as well as to verify the User's identity for the purpose of dealing with inappropriate interactions of Users and/or fraudulent use of our Services.
     </li>
     <br />
     <li>
     Personalize your experience on the Services. 
     </li>
     <br />
     <li>
     Provide and improve the Services and enable future commercial related services.
     </li>
     <br />
     <li>
     Improve the quality of the Services by formulating, customizing and enhancing the User's experience (for example, we may monitor and/or record telephone calls or emails with you, for quality assurance and customer satisfaction purposes).
     </li>
     <br />
     <li>
     To provide you with information about other services that may be of interest to you.
     </li>
     <br />
     <li>
     Allow users to register for special offers, contests and premium services.
     </li>
     <br />
     <li>
     To encourage safe online experiences and enforce our policies.
     </li>
     <br />
     <li>
     Enable payments through Online Payment Processors, as set forth in the TOU. 
     </li>
   </ul>
   <br />
   <br />
   Please note, we neither receive nor collect nor granted with access to your bank, credit card or debit card details, and these details are only processed through our Online Payment Processors, and we only receive your payment status. The terms of use and privacy policies of the Online Payment Processors, not ours, govern your use of their services and should provide details about information they collect about you. It is your responsibility to abide by all the terms specified by these Online Payment Processors and your use of such payment services is at your own risk.
  `,
  },
  {
    id: "#section5",
    title: "Sharing Information with Third Parties",
    content: `As explicitly written in the TOU, we provide delivery and other related services, by Third-Party Couriers. We may also be required to communicate with the customs administrations and/or or other competent authorities and/or the shipper and/or the receiver, in connection with your delivery
    <br />
    <br />
   By entering into, connecting to, accessing to, registering to and/or using the Properties and/or Services, you agree, on behalf of yourself, whether as a User ,shipper or receiver of a certain delivery, and to extent permitted by law, on behalf of others, such as the shipper or receiver of a delivery (if you are not the shipper or receiver), that, in connection with that delivery, we may share with, disclose and transfer to the Third-Party Couriers and/or other services providers and/or competent authorities and/or the shipper or receiver of that delivery, the Personal Information related to that delivery, whether such Personal Information is about the shipper and/or the receiver and/or about the dimensions, type, nature or value of the delivery and/or any other matter related thereto, provided that such sharing, disclosure or transfer of information is necessary or required for the provision of our Services in connection with that delivery. 
   <br />
   <br />
   We may also share and disclose Personal Information in the following cases: (a) to satisfy any applicable law, regulation, legal process, subpoena or governmental request; (b) to enforce the TOU, including this Privacy Policy and/or the Additional Programs Terms, including investigation of potential violations thereof; (c) to detect, prevent, or otherwise address fraud, security or technical issues; (d) to respond to User's support requests; (e) to respond to claims that any content available on the Services violates the rights of third-parties; (f) to respond to claims that contact information (e.g. name, e-mail address, phone number, etc.) of a third-party has been posted or transmitted without their consent or as a form of harassment; (g) to protect the rights, property, or personal safety of us, our Users, or the general public; (h) when we are undergoing any change in control, including by means of merger, acquisition or purchase of all or substantially all of its assets; (i) to collect, hold and/or manage your Personal Information through our authorized third parties service providers (including, as applicable, their affiliates as necessary to provide us with the requested services), as reasonable for performance of services necessary for our operation, which may be located in a country that does not have the same data protection laws as your jurisdiction; (j) to store or process your Personal Information through our affiliated companies, which may be located in a country that does not have the same data protection laws as your jurisdiction; (k) pursuant to your explicit approval prior to the disclosure. 
   <br />
   <br />
   For the avoidance of doubt, we may transfer and disclose Non-personal Information to third parties at our own discretion.
  `,
  },
  {
    id: "#section6",
    title: "International Data Transfer",
    content: `The information collected about you, including Personal Information, may be transferred to, stored at, or processed by, our employees or staff, our affiliated entities, Third-Party Couriers or our other third party service provides, competent authorities (including customs administration) or the shipper and/or receiver of the a delivery you have ordered through our Properties (as provided herein) across borders and from your country or jurisdiction to other countries or jurisdictions around the world. Please note that such information may be transferred to, stored at, or processed in a country and jurisdiction that does not have the same data protection laws as your jurisdiction, and you consent thereto.
  `,
  },
  {
    id: "#section7",
    title: "Deletion or Modification of Personal Information",
    content: `In the event that you wish to modify or cancel your account and/or profile you may do so within the Properties, or you can send us an e-mail of your request to support@shipper-global.com and we will make reasonable efforts to modify or cancel any such account or profile, pursuant to any applicable privacy laws. We may retain and use your Personal Information for a reasonable time after the termination of your account and/or profile, as necessary for future analytics and analysis, or in order to comply with our legal or business requirements or obligations, to protect the full payment of our fees (including to prevent chargebacks) or any other payment to which we may be entitled, to prevent abusive, deceptive, fraudulent actions or behaviors, to resolve disputes, to enforce our TOU and/or to enable the User to reinstate his/her account and/or profile, all as permitted under any applicable privacy laws. 
    <br />
    <br />
   Aggregate and/or anonymous information if derived from your account and/or profile and/or use of the Services may remain on our servers indefinitely. 
   <br />
   <br />
   In any event, we cannot ensure that third parties who gained access to your Personal Information deleted your Personal Information (including if it was removed from the Properties) neither can we monitor their use of such information.
  `,
  },
  {
    id: "#section8",
    title: "Minors",
    content: `We do not intend and do not knowingly collect Personal Information from Users not over the age of eighteen (18) or the age of majority in User's jurisdiction, whichever is greater (“Age of Majority”) and do not wish to do so. We reserve the right to request proof of age at any stage so that we can verify that minors under the Age of Majority are not using the Services. If we learn that we collected Personal Information from children under the Age of Majority we will delete that information as quickly as possible. Please contact us at support@shipper-global.com, if you have reasons to suspect that we collected Personal Information from children under the Age of Majority. 
  `,
  },
  {
    id: "#section9",
    title: "Security ",
    content: `We take reasonable measures to maintain the security and integrity of our Properties and User information and prevent unauthorized access to it or use thereof through generally accepted industry standard technologies and internal procedures. Please note, however, that there are inherent risks in transmission of information over the Internet or other methods of electronic storage and we cannot guarantee that unauthorized access or use of your Personal Information will never occur.
  `,
  },
  {
    id: "#section10",
    title: "Third Party Service Providers",
    content: `We may be using third party software or service in order to collect, host, store and/or process the information detailed herein. We use commercially reasonable efforts to engage with third parties that post and enforce a legal and compliant privacy policy governing their collection, processing and use of non-personal and personal information. Such software and service include without limitation, Hetzner, our servers hosting service provider, that its privacy policy can be found at https://www.hetzner.com/legal/privacy-policy, DHL, one of our Third-Party Couriers, that its privacy policy can be found at https://developer.dhl.com/privacy-notice, FEDEX, one of our Third-Party Couriers, that its privacy policy can be found at https://www.fedex.com/en-us/trust-center/global-privacy-policy.html, Stripe, one of Online Payment Processors, that its privacy policy can be found at https://stripe.com/privacy. However, we do not control such third parties. Please read their terms of use and privacy policies to understand their privacy practices.
  `,
  },
  {
    id: "#section11",
    title: "Cookies and Other Tracking Technologies",
    content: `When you access or use the Properties, we and/or our third party service providers may use industry-wide technologies such as "cookies" or other similar technologies, which store certain local information on your device or on the Site ("Cookies"), which may enable, inter alia, automatic activation of certain features and make the User’s experience and usage simpler, more relevant, convenient and effortless. Such information is locally stored on your computer or on your device. We and/or our third-party service providers may access such information. We and/or our third-party service providers may both use session cookies (which usually expire once you close the Properties or 24 hours thereafter). Such Cookies may store non-personal information (such as the different pages viewed by a User within the Properties). It is easy to prohibit and/or delete the Cookies, inter alia, through the settings option of your device and/or browser. Most platforms will allow you to erase cookies from your computer's hard drive or from your device, block acceptance of cookies, or receive a warning before a cookie is stored. In order to erase or disable the Cookies you may use the settings option of your device and/or browser or according to the specific instructions provided by the third-party service provider's privacy policy and terms of use. However, if you block or erase cookies, or change the settings of your device and/or browser, your online and/or Services experience may be limited.
    <br />
    <br />
   We may also use certain third-party cookies and web-tracking technologies. These are different kind of cookies and web-tracking technologies, stored on your computer and/or devices by third parties, usually containing only a Non-personal Information, such as the web pages you have visited, the duration of your browsing, etc. We do not control such third-party service providers.
  `,
  },
  {
    id: "#section12",
    title: "Direct Marketing",
    content: `You hereby agree that we may use your contact details you provided us (including, without limitation, address, e-mail address and phone number (landline or mobile)), for the purpose of providing you with commercial and/or non-commercial materials related to us or our third-party service providers (such as the Third-Party Curriers), our or our third-party service providers (such as the Third-Party Curriers) current and/or future products and services, and to send to you other marketing materials. You may withdraw your consent by sending email to the following address support@shipper-global.com, or alternatively following the instructions for removing yourself from the mailing list which are available in the message transmitted to you. 
  `,
  },
  {
    id: "#section13",
    title: "Got any Questions? ",
    content: `If you have any questions (or comments) concerning this Privacy Policy, you are most welcome to send us an email to the following address support@shipper-global.com, and we will make an effort to reply within a reasonable timeframe. 
  `,
  },
];
