import { useTheme } from "@mui/material";
import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { history } from "../../../../../../services/history";
import {
  StyledContainer,
  StyledInputGroupWrapper,
  StyledParcelSizesWrapper,
  StyledTab,
  TextInCard,
} from "../Form.styled";

import styles from "./MobileForm.scss";
import formStyles from "../../styles/Form.scss";
import stylesFormHome from "../../formHome.scss";
import { BaseCard } from "../../../../../../components/BaseCard";
import { HF } from "../../../../../../components";
import { BrandPlates } from "../../../../../../components/BrandPlates";
import { BaseSearchInput } from "../../../../../../components/BaseSearchInput/BaseSearchInput";
import {
  getParcelTypeOptions,
  SEARCH_FROM_INPUT_PLACEHOLDER,
  SEARCH_TO_INPUT_PLACEHOLDER,
  SEARCH_FROM_INPUT_PLACEHOLDER_DOMESTIC,
  SEARCH_TO_INPUT_PLACEHOLDER_DOMESTIC,
} from "../../../../data/constants";
import { Container } from "@mui/system";
import { BaseButton } from "../../../../../../components/BaseButton";
import { ArrayComponent } from "../ArrayComponent";
import { AddNewMobileButton } from "../AddNewMobileButton";
import { useFormContext } from "react-hook-form";
import { Name } from "../../../../../../components/BaseIcon";
import { getDisabledSubmitBtn } from "../../services/getDisabledSubmitBtn";
import { MetricSystemBtns } from "../MetricSystemBtns";
import { TopHomePage } from "../../TopHomePage";
import { useIsResponsive } from "../../../../../../services/breakpoint/useIsResponsive";
import { CountrySelect } from "../../../../../../components/CountrySelect";
import { selectIsDomesticShipping } from "../../../../../../data/selectors";
import { domesticCountriesCodes } from "../../../../../../constants";
import { EnvelopeSizes } from "../../../../data/constants";
import { setPackageValues } from "../../services/setPackageValues";
import { maximumNumberOfItemsInParcel } from "../../Form";
import shipmentTypeEventInfobip from "../../../../../../plugins/infobip/shipmentTypeEventInfobip";
import addressFromEventInfobip from "../../../../../../plugins/infobip/addressFromEventInfobip";
import addressToEventInfobip from "../../../../../../plugins/infobip/addressToEventInfobip";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <StyledTab
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </StyledTab>
  );
}

const MobileForm = forwardRef((props: any, ref) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState(true);
  const [currentTab, setCurrentTab] = useState(() => {
    return Number(searchParams.get("step")) || 0;
  });
  const methods = useFormContext();

  const theme = useTheme();
  const { isMobileDevice } = useIsResponsive();

  useEffect(() => {
    const step = Number(searchParams.get("step"));
    // console.log("=useEffect step=", step);

    onSetCurrentTab(step > -1 ? step : 0);
  }, [history.location.search]);

  const onSetCurrentTab = (index: number): void => {
    // console.log("=onSetCurrentTab=", index);
    setCurrentTab(index);

    const query: any = {};

    searchParams.forEach((value, key) => {
      query[key] = value;
    });
    query.step = String(index);

    setSearchParams(query);
  };

  const handleChangeIndex = (index: number): void => {
    // console.log("=handleChangeIndex=", index);
    setCurrentTab(index);
  };

  useImperativeHandle(ref, () => ({
    onSetCurrentTab(index: number) {
      // console.log("=useImperativeHandle=", index);
      return handleChangeIndex(index);
    },
  }));

  const disabledSubmitBtn = (): void => {
    // console.log("=disabledSubmitBtn=", currentTab, isDomesticShipping, methods.watch('locationFrom.country_code'), methods.watch('locationTo.country_code'));
    if (currentTab === 0) {
      setIsDisabledSubmitBtn(
        !(
          methods.watch("locationFrom.country_code") &&
          methods.watch("locationTo.country_code") &&
          methods.watch("locationFrom.city_name") &&
          methods.watch("locationTo.city_name") &&
          (!isDomesticShipping
            ? methods.watch("locationFrom.country_code") !==
              methods.watch("locationTo.country_code")
            : true)
        )
      );
    } else {
      setIsDisabledSubmitBtn(
        !getDisabledSubmitBtn({
          addressFromInputError: props.addressFromInputError,
          addressToInputError: props.addressToInputError,
          methods,
        })
      );
    }
  };

  useEffect(() => {
    disabledSubmitBtn();
  }, [methods, currentTab]);

  const handleMobileNextBtn = (): void => {
    currentTab === 0 && scrollToTop();

    setTimeout(() => {
      onSetCurrentTab(1);
    }, 100);
  };

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const isDomesticShipping = useSelector(selectIsDomesticShipping);

  return (
    <>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={currentTab}
        onChangeIndex={handleChangeIndex}
        style={{ width: "100%", minHeight: "100%" }}
        disabled
      >
        <TabPanel
          role="tabpanel"
          value={currentTab}
          className={styles.firstMobileTab}
          index={0}
          dir={theme.direction}
        >
          <StyledContainer>
            <TopHomePage
              onChangeDomesticShipping={
                props.onSwitchingBetweenInternationalAndDomestic
              }
            />

            <div className={stylesFormHome.inputSection}>
              <StyledInputGroupWrapper>
                <BaseCard
                  className={`${formStyles.card} ${stylesFormHome.shadowToCitySelectionField}`}
                  padding={"3px 18px"}
                >
                  <TextInCard>Göndermek istiyorum</TextInCard>

                  <HF.Select
                    name="parcelType"
                    options={getParcelTypeOptions(props.count > 1)}
                    className={formStyles.select}
                    defaultValue={methods.getValues("parcelType")}
                    renderSelectedComponentWithIcon
                    onChange={(payload: any) => {
                      // console.log("=onChange=", payload, methods.getValues('parcelType'));

                      methods
                        .getValues("packages")
                        .forEach((item: any, index: number) => {
                          methods.setValue(
                            `packages[${index}].envelopeSize`,
                            EnvelopeSizes.custom
                          );
                        });

                      process.env.REACT_APP_INFOBIP &&
                        shipmentTypeEventInfobip(payload);
                    }}
                  />
                </BaseCard>

                {isDomesticShipping && (
                  <BaseCard
                    className={`${formStyles.card} ${stylesFormHome.shadowToCitySelectionField}`}
                    padding="0"
                  >
                    <CountrySelect
                      className={formStyles.CountrySelect}
                      countryCode={methods.watch("domesticCountryCode")}
                      onSelectedCountry={props.onChangeCountry}
                      allowedCountriesCodes={domesticCountriesCodes}
                    />
                  </BaseCard>
                )}

                <BaseCard
                  className={
                    formStyles.card +
                    ` ${stylesFormHome.shadowToCitySelectionField}` +
                    (props.addressFromInputError
                      ? ` ${stylesFormHome.shadowToCitySelectionFieldError}`
                      : "")
                  }
                  padding="0"
                >
                  <BaseSearchInput
                    className={formStyles.searchAddress}
                    address={methods.getValues("locationFrom")}
                    searchOptions={props.searchOptions}
                    label={
                      isDomesticShipping
                        ? SEARCH_FROM_INPUT_PLACEHOLDER_DOMESTIC
                        : SEARCH_FROM_INPUT_PLACEHOLDER
                    }
                    positionStatic
                    withoutBorder
                    selectionRequired
                    showFullAddress
                    dropdownUp={isMobileDevice}
                    // handleError={(isError: boolean) => setIsErrorFromCity(isError)}
                    error={props.addressFromInputError}
                    disabled={props.isAddressFieldsDisabled}
                    onSelect={(selectedAddress: any) => {
                      methods.setValue("locationFrom", selectedAddress);
                      props.resetErrorFrom();
                      process.env.REACT_APP_INFOBIP &&
                        addressFromEventInfobip(selectedAddress);
                    }}
                  />
                </BaseCard>

                <BaseCard
                  className={
                    formStyles.card +
                    ` ${stylesFormHome.shadowToCitySelectionField}` +
                    (props.addressToInputError
                      ? ` ${stylesFormHome.shadowToCitySelectionFieldError}`
                      : "")
                  }
                  padding="0"
                >
                  <BaseSearchInput
                    className={formStyles.searchAddress}
                    address={methods.getValues("locationTo")}
                    searchOptions={props.searchOptions}
                    label={
                      isDomesticShipping
                        ? SEARCH_TO_INPUT_PLACEHOLDER_DOMESTIC
                        : SEARCH_TO_INPUT_PLACEHOLDER
                    }
                    positionStatic
                    withoutBorder
                    selectionRequired
                    showFullAddress
                    dropdownUp={isMobileDevice}
                    error={props.addressToInputError}
                    disabled={props.isAddressFieldsDisabled}
                    onSelect={(selectedAddress: any) => {
                      methods.setValue("locationTo", selectedAddress);
                      props.resetErrorTo();
                      process.env.REACT_APP_INFOBIP &&
                        addressToEventInfobip(selectedAddress);
                    }}
                  />
                </BaseCard>
              </StyledInputGroupWrapper>
            </div>

            <BaseButton
              className={styles.mobileNextBtnTabFirst}
              onClick={handleMobileNextBtn}
              disabled={isDisabledSubmitBtn}
              loading={props.loading}
              type="button"
            >
              Teklif alın ve rezervasyon yapın
            </BaseButton>
          </StyledContainer>

          <BrandPlates />
        </TabPanel>

        <TabPanel value={currentTab} index={1} dir={theme.direction}>
          <Container>
            <BaseButton
              className={styles.backMobileBtn}
              leftIcon={Name.LeftArrow}
              onClick={() => {
                onSetCurrentTab(0);
                scrollToTop();
              }}
              clear
            >
              Back
            </BaseButton>

            {/* <SizeHint
              handleNextStep={() => setCurrentTab(2)}
              handlePrevStep={() => setCurrentTab(1)}
            /> */}

            <div style={{ marginTop: "36px" }}>
              {!!props.count && (
                <MetricSystemBtns
                  selected={methods.getValues("unit_of_measurement")}
                  onClick={(value: boolean) => {
                    methods.setValue("unit_of_measurement", value);
                    methods
                      .getValues("packages")
                      .forEach((item: any, index: number) => {
                        // console.log("==", `${value}`, item.envelopeSize);
                        // console.log("==", setPackageValues, setPackageValues[`${value}`]);
                        // console.log("==", setPackageValues[`${value}`][item.envelopeSize]);

                        if (
                          item.envelopeSize === EnvelopeSizes.small ||
                          item.envelopeSize === EnvelopeSizes.large
                        ) {
                          const _sizes =
                            setPackageValues[`${value}`][item.envelopeSize];

                          Object.keys(_sizes).forEach(
                            (parcelSizeTypeName: any) => {
                              methods.setValue(
                                `packages[${index}].${parcelSizeTypeName}`,
                                _sizes[parcelSizeTypeName]
                              );
                            }
                          );
                        }
                      });
                  }}
                />
              )}
            </div>

            <StyledParcelSizesWrapper>
              <ArrayComponent
                fields={props.fields}
                parcelType={methods.getValues("parcelType")}
                remove={props.remove}
                onChange={({ fieldName, value }: any) => {
                  // console.log("=onChange=", fieldName, value);

                  methods.setValue(fieldName, value);
                  disabledSubmitBtn();
                }}
                getFieldState={methods.getFieldState}
                register={methods.register}
                unitSystem={methods.watch("unit_of_measurement")}
              />
            </StyledParcelSizesWrapper>

            {methods.getValues("packages").length >=
              maximumNumberOfItemsInParcel || (
              <AddNewMobileButton
                parcelType={methods.getValues("parcelType")}
                onClick={props.onChangeCounterAdd}
              />
            )}

            <BaseButton
              className={styles.mobileNextBtnTabSecond}
              disabled={isDisabledSubmitBtn}
              loading={props.loading}
              type="submit"
            >
              Alıntı ve kitap
            </BaseButton>
          </Container>
        </TabPanel>
        {/* <TabPanel value={currentTab} index={2} dir={theme.direction}>
          <Container>
            <BaseButton
              className={styles.backMobileBtn}
              leftIcon={Name.LeftArrow}
              onClick={() => setCurrentTab(1)}
              clear
            >
              Back
            </BaseButton>

            <ModalContent onClose={() => setCurrentTab(1)} />
          </Container>
        </TabPanel> */}
      </SwipeableViews>
    </>
  );
});

export default MobileForm;
