import { styled } from "@mui/system";
import { SnackbarContent, useSnackbar } from "notistack";
import React from "react";
import { BaseIcon, Name, Size } from "../../../../components/BaseIcon";
import styles from "./Notification.scss";

const StyledSnackbarContent = styled(({ type, children, ...props }: any) => (
  <div {...props}>{children}</div>
))(
  ({ type }: any) => `
  background: ${type === "error" ? "#FEEEEE" : "#EEFEFB"};
  color: ${type === "error" ? "#FF5C5C" : "#4AB6BD"};
  padding: 16px 24px;
  border-radius: 10px;
  font-size: 14px;
  position: relative;
  min-width: 345px;
  z-index: 999999999999;
  `,
);

const StyledMessage = styled("p")`
  max-width: 300px;
  word-wrap: break-word;
`;

const ReportComplete = React.forwardRef<HTMLDivElement, any>(
  (props, ref: any) => {
    const { id, message, type, ...other } = props;
    const { closeSnackbar } = useSnackbar();

    return (
      <SnackbarContent ref={ref} role="alert" {...other}>
        <StyledSnackbarContent type={type}>
          <StyledMessage
            dangerouslySetInnerHTML={{ __html: message }}
          ></StyledMessage>

          <button
            className={styles.closeBtn}
            onClick={() => {
              closeSnackbar(id);
            }}
          >
            <BaseIcon
              name={Name.Close}
              color={type === "error" ? "#FF5C5C" : "#4AB6BD"}
              size={Size.small}
            />
          </button>
        </StyledSnackbarContent>
      </SnackbarContent>
    );
  },
);

export default ReportComplete;
