import * as React from "react";
import { IconButton } from "@mui/material";
import { BaseIcon, Name } from "../../../../../../../components/BaseIcon";
import { StyledTooltip } from "../../../../../../../components/TooltipBase.styled";

export const AmountTooltip = () => {
  return (
    <StyledTooltip
      title={
        <React.Fragment>
          Please enter the declared value of a single item.
          <br />
          Notes:
          <br />
          &nbsp;&nbsp;- It is important to be accurate as much as possible and
          provide the true value. Customs officials may inspect the package to
          verify its contents and value.
          <br />
          &nbsp;&nbsp;- Generally, the best rule of thumb is to declare the
          amount the goods were sold for.
          <br />
          &nbsp;&nbsp;- If you’re sending a product sample, or if there was no
          sale, or no transaction value - you should assign a value of similar
          goods or what the goods would otherwise be valued at.
          <br />
          &nbsp;&nbsp;- When shipping samples or gifts it is recommended to
          mention this in the item description.
          <br />
          &nbsp;&nbsp;- A high declared value does not affect shipping cost. It
          may however affect the insurance cost if you choose to purchase
          insurance.
        </React.Fragment>
      }
    >
      <IconButton style={{ color: "#757575", cursor: "default" }} disableRipple>
        <BaseIcon name={Name.Info} size={32} />
      </IconButton>
    </StyledTooltip>
  );
};
