import { handleActions } from "redux-actions";
import { setUser } from "../../../data/actions";

import * as actions from "./actions";

import { REDUCER_NAME } from "./constants";

const initialState = {
  isAuth: false,
  isResetTokenValid: false,
};

const reducer = {
  [actions.signOut.toString()]: (state: any) => {
    return {
      ...state,
      isAuth: false,
    };
  },
  [setUser.toString()]: (state: any) => {
    return { ...state, isAuth: true };
  },
  [actions.setIsResetTokenValid.toString()]: (state: any, { payload }: any) => {
    return { ...state, isResetTokenValid: payload };
  },
};

export const authReducer = {
  [REDUCER_NAME]: handleActions(reducer, initialState),
};
