import { Container } from "@mui/material";
import { styled } from "@mui/system";
import { BaseIcon } from "../../../components/BaseIcon";

import { Breakpoints, media } from "../../../css/breakpoints";
import brandColors from "../../../css/brandColors.scss";

const backgroundMixin = `
  content: '';
  display: block;
  width: 50vw;
  height: 100%;
  position: absolute;
  // transition: all 0.8s ease-in;
  z-index: -2;
  top: 0;
`;

export const StyledRoot = styled("div")(
  () => `
 display: flex;
 justify-content: center;
 align-items: center;
 min-height: 100vh;
 overflow: hidden;
 position: relative;

 @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
   width: 100%;
   padding-top: 50px;
   padding-bottom: 50px;
 }

 &::before {
   ${backgroundMixin}

   left: 0;

   @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
     width: 100%;
   }
 }

 &::after {
   ${backgroundMixin}

   right: 0;

   @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
     display: none;
   }
 }
`,
);

export const StyledContainer = styled(Container)(
  () => `
   display: grid;
   grid-template-columns: 1fr 1fr;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;

   @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
     grid-template-columns: 1fr;
   }
`,
);

const StyledBaseIcon = styled(BaseIcon)(
  () => `
   color: ${brandColors.error};
   transform: rotate(45deg);
   position: absolute;
   z-index: -1;

   @media ${media(Breakpoints.Desktop, Breakpoints.Desktop2K)} {
     display: none;
   }
`,
);

export const StyledMobileBrandArrowLeft = styled(StyledBaseIcon)(
  () => `
   top: 0;
   left: 0;
   width: 115px;
   height: 115px;
 `,
);

export const StyledMobileBrandArrowRight = styled(StyledBaseIcon)(
  () => `
   top: 0;
   right: 0;
   width: 72px;
   height: 72px;
`,
);
