import { CSSProperties } from "@mui/material/styles/createTypography";
import { SelectBaseController } from "./SelectBaseController";
import styles from "./CountrySelect.scss";
import {
  blockedCountriesCodes,
  defaultCountryNameHongKong,
} from "../constants";
import { getCountryName } from "../services/getCountryName";

const { getName, getCodes } = require("country-list");

interface CountrySelectProps {
  style?: CSSProperties;
  className?: string;
  countryCode: string;
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  disableClearable?: boolean;
  allowedCountriesCodes?: string[];
  required?: boolean;
  onChange(value: any): void;
}

const generateOptionsSelect = (countryCodesProps: string[]) => {
  // === Удаляем Антарктиду ===
  const antarcticaIndex = countryCodesProps.findIndex(
    (countryCode) => countryCode === "AQ",
  );
  antarcticaIndex > -1 && countryCodesProps.splice(antarcticaIndex, 1);
  // ===END ===

  const filtredCountryCodes = countryCodesProps.filter(
    (countryCode) => !blockedCountriesCodes.includes(countryCode),
  );

  return filtredCountryCodes.map((isoCode) => {
    return {
      value: isoCode,
      label: getName(isoCode),
    };
  });
};

export const CountrySelectV2 = ({
  style,
  className,
  countryCode = "",
  name,
  placeholder,
  label,
  disabled,
  disableClearable,
  allowedCountriesCodes,
  required,
  onChange,
}: CountrySelectProps) => {
  // console.log("=CountrySelect=", countryCode);

  const optionsSelect = generateOptionsSelect(
    allowedCountriesCodes ?? getCodes(),
  );

  // === Изменяем имя страны Гонгконг ===
  const hongKongIndex = optionsSelect.findIndex(
    (countryCode) => countryCode.value === "HK",
  );
  hongKongIndex > -1 &&
    (optionsSelect[hongKongIndex].label = defaultCountryNameHongKong);
  // ===END ===

  !allowedCountriesCodes &&
    optionsSelect.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
    );

  const innerPlaceholder =
    placeholder ?? `Select country${required ? " *" : ""}`;

  return (
    <SelectBaseController
      style={style}
      className={`${styles.CountrySelect} ${className}`}
      name={name}
      disabled={disabled}
      options={optionsSelect}
      value={getCountryName(countryCode)}
      disableClearable={disableClearable}
      // placeholder={innerPlaceholder}
      label={label ?? "Select country"}
      noOptionsText="No countries"
      onChange={(value) => {
        // console.log("=onChange=", value);
        onChange(value ?? null);
      }}
    />
  );
};
