import isEqual from "lodash.isequal";
import { BaseButton } from "../../components/BaseButton";
import styles from "./ControlFormAppAddressBookEditor.scss";

interface ControlFormAppAddressBookEditorProps {
  className?: string;
  methods: any;
  originalForm: any;
  isCompany: boolean;
  loadingSubmit: boolean;
}

export const ControlFormAppAddressBookEditor = ({
  className = "",
  methods,
  originalForm,
  isCompany,
  loadingSubmit,
}: ControlFormAppAddressBookEditorProps) => {
  const disabledSubmitBtn = (): boolean => {
    // console.log(isEqual(originalForm, methods.watch()), originalForm, methods.watch());
    if (isEqual(originalForm, methods.watch())) return true;

    return !(
      methods.watch("first_name") &&
      methods.watch("last_name") &&
      methods.watch("country_code") &&
      methods.watch("city_name") &&
      methods.watch("phone") &&
      methods.watch("email") &&
      (isCompany
        ? methods.watch("company_name") && methods.watch("company_ein")
        : methods.watch("passport")) &&
      methods.watch("street_1") &&
      methods.watch("postal_code")
    );
  };

  return (
    <div className={`${className} ${styles.ControlFormAppAddressBookEditor}`}>
      {/* <button
        className={`${styles.ControlFormAppAddressBookEditorBtn} ${styles.ControlFormAppAddressBookEditorBtnCancel}`}
      >
        Cancel
      </button> */}

      <BaseButton
        className={`${styles.ControlFormAppAddressBookEditorBtn} ${styles.ControlFormAppAddressBookEditorBtnSubmit}`}
        loading={loadingSubmit}
        type="submit"
        clear
        disabled={disabledSubmitBtn()}
      >
        Save
      </BaseButton>
    </div>
  );
};
