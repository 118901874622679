export const REDUCER_NAME = "checkout";

export const EXPANDED_SHIPMENT_CARD_LS = "checkoutExpandedShipmentCard";

export enum ShipmentFormNames {
  DefaultBillingAddress = "default_billing_address",
  BillingAddress = "billing_address",
  AddressFrom = "address_from",
  AddressTo = "address_to",
}
