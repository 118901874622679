import { Name } from "../../../components/BaseIcon";

export const IMPERIAL_SYSTEM_TYPE = "imperial";

export const METRIC_SYSTEM_TYPE = "metric";

export const HOME_FORM = "HOME_FORM";

export const COUNTRIES_DO_NOT_HAVE_TO_MATCH = "Countries do not have to match";

export const MAX_WIDTH_VALUE = 120;

export const MAX_LENGTH_VALUE = 120;

export const MAX_HEIGHT_VALUE = 210;

export const MAX_WEIGHT_VALUE = 1000;

export const MAX_WEIGHT_VALUE_LB = 2205;

export const SEARCH_FROM_INPUT_PLACEHOLDER = "From city";

export const SEARCH_FROM_INPUT_PLACEHOLDER_RTL = "מעיר";

export const SEARCH_TO_INPUT_PLACEHOLDER = "To city";

export const SEARCH_TO_INPUT_PLACEHOLDER_RTL = "לעיר";

export const SEARCH_FROM_INPUT_PLACEHOLDER_DOMESTIC = "From address";

export const SEARCH_FROM_INPUT_PLACEHOLDER_DOMESTIC_RTL = "מכתובת";

export const SEARCH_TO_INPUT_PLACEHOLDER_DOMESTIC = "To address";

export const SEARCH_TO_INPUT_PLACEHOLDER_DOMESTIC_RTL = "לכתובת";

export const enum EnvelopeSizes {
  small = "small",
  large = "large",
  custom = "custom",
  _default = "small",
}

export enum ParcelSizeTypes {
  weight = "weight",
  length = "length",
  height = "height",
  width = "width",
}

export const defaultLetters: any = {
  [ParcelSizeTypes.width]: process.env.REACT_APP_MAX_WIDTH_OF_SMALL_LETTER,
  [ParcelSizeTypes.length]: process.env.REACT_APP_MAX_LENGTH_OF_SMALL_LETTER,
  [ParcelSizeTypes.height]: process.env.REACT_APP_MAX_THICKNESS_OF_SMALL_LETTER,
  [ParcelSizeTypes.weight]: process.env.REACT_APP_MAX_WEIGHT_OF_SMALL_LETTER,
};

export const parcelTypesHe = [
  {
    label: "מסמכים",
    value: "envelope",
  },
  {
    label: "חבילות",
    value: "package",
  },
  {
    label: "פלטים",
    value: "pallet",
  },
];

export const getParcelTypeOptions = () => [
  {
    label: `${parcelTypesHe[0].label}`,
    value: parcelTypesHe[0].value,
    leftIcon: Name.Envelope,
    leftIconColor: "#FF5C5C",
  },
  {
    label: `${parcelTypesHe[1].label}`,
    value: parcelTypesHe[1].value,
    leftIcon: Name.Box,
    leftIconColor: "#FF5C5C",
  },
  {
    label: `${parcelTypesHe[2].label}`,
    value: parcelTypesHe[2].value,
    leftIcon: Name.Pallet,
    leftIconColor: "#FF5C5C",
  },
];
