import { useSelector } from "react-redux";
import { selectFirstName, selectLastName } from "../../../../data/selectors";
import { CardWrapper } from "../CardWrapper";
import { EditedInput } from "../EditedInput";
import { InfoRow } from "../InfoRow";

const BasicInformation = () => {
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);

  return (
    <CardWrapper title="Basic Information">
      <InfoRow
        label="First Name"
        inputField={<EditedInput fieldName="first_name" value={firstName} />}
      />

      <InfoRow
        label="Last Name"
        inputField={<EditedInput fieldName="last_name" value={lastName} />}
        withoutBorder
      />
    </CardWrapper>
  );
};

export default BasicInformation;
