import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FIELD_IS_REQUIRED } from "../../../../../../../../../constants";
import {
  getMaxValidationMessage,
  getMinValidationMessage,
} from "../../../../../../../../../services/validation";
import {
  ENG_AND_HEBREW_AND_NUMS_REG_EXP,
  ENG_AND_HEBREW_REG_EXP,
  FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_HYPHEN,
  FIELD_MUST_CONTAIN_ONLY_LETTERS_AND_NUMS,
} from "../../../../../../../../../data/constants";
import { ENTER_VALID_EMAIL } from "../../../../../../../../auth/data/constants";
import { sss } from "../../../PickupDetails/services/validation";

export const validationSchema = yupResolver(
  yup.object().shape({
    address_to: yup.object().shape({ ...sss }, [
      ["company_ein", "company_ein"],
      ["passport", "passport"],
      ["company_name", "company_name"],
      // ['email', 'email'],
    ]),
  }),
);
