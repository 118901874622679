import { useState } from "react";
import { SelectBase } from "../SelectBase";
import {
  countriesWithStates,
  defaultCountryNameHongKong,
} from "../../constants";

interface BaseSearchInputV2Props {
  className?: string;
  label: string;
  value?: string;
  noOptionsText?: string;
  searchType: SearchType;
  useCountry?: boolean;
  disabled?: boolean;
  blurOnSelect?: boolean;
  hideAutocomplete?: boolean;
  popupIcon?: any;
  addressDetails?: any;
  disableClearable?: boolean;
  onInputChange(value: any): void;
  onChange(value: any): void;
}

export enum SearchType {
  address = "address",
  city = "city",
}

const types = {
  [SearchType.address]: ["address"],
  [SearchType.city]: ["(cities)"],
};

let isLoading = false;

export const BaseSearchInputV2 = ({
  className,
  value,
  label,
  searchType,
  noOptionsText,
  useCountry = true,
  addressDetails,
  disableClearable,
  blurOnSelect,
  hideAutocomplete,
  popupIcon,
  onInputChange,
  onChange,
}: BaseSearchInputV2Props) => {
  const [ooo, setOoo] = useState([]);

  const initService = async (searchString: any) => {
    // console.log("=initService=", searchString);
    let _ooo: any = [];

    const displaySuggestions = function (predictions: any, status: any) {
      // console.log("=displaySuggestions=", predictions, status);
      if (
        status !== google.maps.places.PlacesServiceStatus.OK ||
        !predictions
      ) {
        console.error(status);

        isLoading = false;

        return;
      }

      _ooo = predictions;
    };

    const service = new google.maps.places.AutocompleteService();

    const componentRestrictions: any = {};

    useCountry &&
      addressDetails?.country_code &&
      (componentRestrictions.country = [addressDetails.country_code]);

    await service.getPlacePredictions(
      {
        input: searchString,
        types: types[searchType],
        componentRestrictions,
      },
      displaySuggestions,
    );

    _ooo = _ooo.map((item: any) => ({
      ...item,
      label: item.description,
      // value: item.description,
    }));

    // console.log("=_ooo=", _ooo);

    return _ooo;
  };

  const handleUpdateModelValue = async (
    event: any,
    newValue: string = "",
    reason: string,
  ) => {
    // console.log("=handleUpdateModelValue=", newValue, newValue.length < 3, isLoading);

    onInputChange(newValue);

    if (isLoading || newValue.length < 3) {
      // if (newValue.length < 3) {
      // console.log("=handleUpdateModelValue EXIT=");
      // setOoo([])

      return;
    }

    const searchStringArray = [];

    // if (searchType === SearchType.address) {
    //   addressDetails?.city_name &&
    //     searchStringArray.push(addressDetails.city_name);
    //   addressDetails?.state &&
    //     searchStringArray.push(addressDetails.state);
    // }

    searchStringArray.push(newValue);

    // console.log(searchStringArray.join(", "), addressDetails);

    const _ooo: any = await initService(searchStringArray.join(", "));

    // console.log("=_ooo 1=", _ooo);

    setOoo(_ooo);
  };

  const handleSelected = async (selectedAddress: any) => {
    // console.log("=handleSelected=", selectedAddress);
    isLoading = true;

    const outgoingAddressDetails: any = {
      value: "",
      address: {
        country_code: "",
        state: "",
        country: "",
        city_name: "",
        postal_code: "",
        street_1: "",
        street_2: "",
      },
    };

    let splitValue = selectedAddress.description.split(/,/);
    if (splitValue.length === 1) {
      splitValue = selectedAddress.description.split(/\s-\s/);
    }

    if (searchType === SearchType.address) {
      outgoingAddressDetails.value = splitValue[0].trimEnd();
      outgoingAddressDetails.address.street_1 = splitValue[0].trimEnd();
    } else if (searchType === SearchType.city) {
      outgoingAddressDetails.address.city_name = splitValue[0].trimEnd();
    }

    // console.log(
    //   "=outgoingAddressDetails=",
    //   selectedAddress,
    //   selectedAddress.description,
    //   splitValue,
    //   outgoingAddressDetails,
    // );

    const geocoder = new google.maps.Geocoder();

    await geocoder.geocode(
      // { placeId: selectedAddress.place_id },
      { address: selectedAddress.description }, // Используется address, а не placeId, так как при address возврящается больше данных (в частности postal_code)
      (results: any, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          // console.log("=results=", results, results[0]);
          results[0].address_components.forEach((el: any) => {
            el.types.forEach((componentType: any) => {
              // console.log("=el.types.forEach=", el);
              switch (componentType) {
                case "country": {
                  // console.log("=el.types country=", el, componentType);
                  outgoingAddressDetails.address.country_code = el.short_name;
                  outgoingAddressDetails.address.country = el.long_name;
                  break;
                }
                case "locality":
                case "sublocality":
                case "sublocality_level_1":
                case "sublocality_level_2":
                case "sublocality_level_3":
                case "sublocality_level_4":
                case "sublocality_level_5":
                case "postal_town": {
                  outgoingAddressDetails.address.city_name ||
                    (outgoingAddressDetails.address.city_name = el.long_name);

                  break;
                }
                case "administrative_area_level_1": {
                  outgoingAddressDetails.address.state = el.short_name;
                  break;
                }
                case "postal_code": {
                  outgoingAddressDetails.address.postal_code = el.short_name;
                  break;
                }
                case "postal_code_prefix": {
                  // console.log("=postal_code_prefix=");
                  outgoingAddressDetails.address.__postal_code_prefix =
                    el.short_name;
                  break;
                }
                case "route": {
                  // if (searchType === SearchType.address) {
                  //   // outgoingAddressDetails.label =
                  //   //   `${outgoingAddressDetails.label} ${el.long_name}`.trim();
                  //   outgoingAddressDetails.value = outgoingAddressDetails.label;
                  // }
                  break;
                }
                // case "street_number": {
                //   outgoingAddressDetails.address.street_2 = el.long_name;
                //   break;
                // }
              }
            });
          });

          // console.log("=outgoingAddressDetails=", outgoingAddressDetails);

          if (
            outgoingAddressDetails.address?.country_code === "HK" &&
            !outgoingAddressDetails.address?.city_name
          ) {
            outgoingAddressDetails.address.city_name = "Hong Kong";
            outgoingAddressDetails.address.country = defaultCountryNameHongKong;
          }

          //Streetsville Memorial Park Bridge
          if (outgoingAddressDetails.address?.country_code === "CA") {
            // console.log("=outgoingAddressDetails.address=", outgoingAddressDetails.address);

            if (outgoingAddressDetails.address.__postal_code_prefix) {
              delete outgoingAddressDetails.address.__postal_code_prefix;
              outgoingAddressDetails.address.postal_code = "";
            }
          }
        }
      },
    );

    // console.log("=outgoingAddressDetails=", useCountry, outgoingAddressDetails);
    useCountry && delete outgoingAddressDetails.address.country_code;

    outgoingAddressDetails.address?.country_code &&
      !countriesWithStates.includes(
        outgoingAddressDetails.address.country_code,
      ) &&
      delete outgoingAddressDetails.address.state;

    // console.log("=outgoingAddressDetails RETURN=", outgoingAddressDetails);
    onChange(outgoingAddressDetails);

    isLoading = false;
    setOoo([]);
  };

  const onBlur = () => {
    // console.log("=onBlur=", isLoading );
    isLoading && setOoo([]);

    // isLoading = false
  };

  return (
    <SelectBase
      className={className}
      value={value}
      options={ooo}
      label={label}
      popupIcon={popupIcon}
      blurOnSelect={blurOnSelect}
      hideAutocomplete={hideAutocomplete}
      noOptionsText={noOptionsText}
      disableClearable={disableClearable}
      filterOptions={(options: any, state: any) => {
        // console.log("=filterOptions=", options, '==', state);

        return options;
      }}
      onInputChange={handleUpdateModelValue}
      onChange={handleSelected}
      // onClose={onClose}
      onBlur={onBlur}
    />
  );
};
