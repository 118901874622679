export const REDUCER_NAME = "auth";

export const SIGN_IN_FORM = "SIGN_IN_FORM";

export const SIGN_UP_FORM = "SIGN_UP_FORM";

export const SIGN_IN_TYPE = "sign-in";

export const SIGN_UP_TYPE = "sign-up";

export const ENTER_VALID_EMAIL = "Enter valid email";
