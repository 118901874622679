import { parcelTypes } from "../../../../../../home/data/constants";
import { EnvelopeSizes } from "../../../../../../home/data/constants";

export const generateDefaultFormValuesParcelEditing = (shipment: any) => {
  // console.log("=generateDefaultFormValuesParcelEditing=", shipment);

  const outgoingData: any = {
    unit_of_measurement: shipment.unit_of_measurement,
    parcelType:
      shipment?.is_customs_declarable ||
      shipment?.is_customs_declarable === undefined
        ? shipment?.is_pallet
          ? parcelTypes[2].value
          : parcelTypes[1].value // ! default package
        : parcelTypes[0].value,
    packages: shipment?.parcels
      ? shipment.parcels.map((parcel: any) => ({
          ...parcel,
          envelopeSize: EnvelopeSizes.custom,
        }))
      : [
          {
            id: Date.now(),
            envelopeSize: shipment?.envelopeSize ?? EnvelopeSizes.custom,
          },
        ],
  };

  return outgoingData;
};
