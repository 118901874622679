import API from "../../../api";
import { UnitSystem } from "../../../services/convertUnitSystemValues";
import { getErrorMessage } from "../../../services/getErrorMessage";
import { Notify } from "../../../services/notifications";
import { objectToQueryString } from "../../../services/objectToQueryString";

export const generateShipment = async (formValues: any) => {
  try {
    const getTransformedFormValues = () => {
      return {
        ...formValues,
        unit_of_measurement:
          formValues.unit_of_measurement.toString() === UnitSystem.METRIC
            ? "metric"
            : "imperial",
        packages: formValues.packages.map((packageItem: any) => ({
          width: packageItem.width,
          height: packageItem.height,
          length: packageItem.length,
          weight: packageItem.weight,
        })),
      };
    };

    const response = await API.get(
      `/rates?${objectToQueryString(getTransformedFormValues())}`,
    );

    return response.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
    throw new Error(getErrorMessage(error));
  }
};

export const chooseShipmentRate = async ({ shipmentId, rate_id }: any) => {
  try {
    const response = await API.post(`/shipments/${shipmentId}/chosen-rate`, {
      rate_id,
    });

    return response.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
    throw new Error(getErrorMessage(error));
  }
};
