export const getTransformedAddressFormValesToSubmit = ({
  methods,
  formName,
}: any) => ({
  address_type: formName,
  address: {
    ...methods.watch(`${formName}`),
    passport: methods.watch(`${formName}.is_company`)
      ? undefined
      : methods.watch(`${formName}.passport`),
    company_name: methods.watch(`${formName}.is_company`)
      ? methods.watch(`${formName}.company_name`)
      : undefined,
    company_ein: methods.watch(`${formName}.is_company`)
      ? methods.watch(`${formName}.company_ein`)
      : undefined,
    street_1:
      methods.watch(`${formName}.street_1`)?.address ||
      methods.watch(`${formName}.street_1`),
    street_2: methods.watch(`${formName}.street_2`),
    // "street_3": "string"
  },
});
