import { setStore } from "./handleBrowserStore";

export const goToVue = (path: string, event?: any, replace?: boolean) => {
  console.log("= GO TO VUE =", path, event, replace);

  process.env.REACT_APP_IS_MOBILE_APP === "true" &&
    (path.startsWith("/l") || (path = `/l${path}`));
  // console.log("= GO TO VUE 22222 path=", path);

  setStore(path);

  // console.log("=process.env.REACT_APP_IS_LOCAL_DEVELOPMENT , process.env.REACT_APP_IS_MOBILE_APP=", process.env.REACT_APP_IS_LOCAL_DEVELOPMENT === "true", process.env.REACT_APP_IS_MOBILE_APP === 'true');
  const sss =
    process.env.REACT_APP_IS_MOBILE_APP === "true" ? "/l/index.html" : "/l";
  // const sss = "/l"

  const url = `${process.env.REACT_APP_IS_LOCAL_DEVELOPMENT === "true" ? "http://localhost:3003" : ""}${sss}`;
  // const url = sss

  console.log("=go to=", event?.ctrlKey || event?.metaKey, url);
  if (replace) {
    // TODO: добавлено экспериментально
    globalThis.location.replace(url);
  } else {
    globalThis.open(url, event?.ctrlKey || event?.metaKey ? "_blank" : "_self");
  }
};
