export const FOOTER_LINKS: any = [
  {
    label: {
      true: "© 2024 Shipper Global Inc.",
      false: "© 2024 Shipper Global Inc.",
    },
    to: "/",
    noRtl: true,
  },
  {
    label: {
      true: "מדיניות הפרטיות",
      false: "Privacy Policy",
    },
    to: "/privacy-policy",
  },
  {
    label: {
      true: "תנאי שימוש",
      false: "Terms of Use",
    },
    to: "/terms-of-use",
  },
  {
    label: {
      true: "מדיניות החזרים",
      false: "Refund Policy",
    },
    to: "/refund-policy",
  },
  {
    label: {
      true: "יצירת קשר",
      false: "Contacts",
    },
    to: "/contacts",
  },
];
