import React from "react";

import {
  getConvertedSizeValue,
  getConvertedWeightValue,
} from "../../../../../../services/convertUnitSystemValues";

import { MoneyInputWithLeftLabel } from "./components/MoneyInputWithLeftLabel";
import { BaseIcon, Name } from "../../../../../../components/BaseIcon";
import {
  ParcelSizeTypes,
  MAX_HEIGHT_VALUE,
  MAX_WIDTH_VALUE,
  MAX_WEIGHT_VALUE,
  MAX_LENGTH_VALUE,
} from "../../../../data/constants";
import { useIsResponsive } from "../../../../../../services/breakpoint/useIsResponsive";
import { parcelTypes } from "../../../../data/constants";
import styles from "./ParcelSizeRow.scss";
import {
  StyledRoot,
  StyledNumber,
  StyledWrapper,
  StyledEnvelopeSizeSelect,
  StyledSizeAndWeightSeparator,
} from "./ParcelSizeRow.styled";
import { setPackageValues } from "../../services/setPackageValues";
import { EnvelopeSizes } from "../../../../data/constants";
import letterTypeEventInfobip from "../../../../../../plugins/infobip/letterTypeEventInfobip";
import inputDimensionsEventInfobip from "../../../../../../plugins/infobip/inputDimensionsEventInfobip";

const envelopeSizesOptions = [
  {
    label: "Custom Letters or Docs",
    value: EnvelopeSizes.custom,
  },
  {
    label: "Small Letters or Docs",
    value: EnvelopeSizes.small,
  },
  {
    label: "Large Letters or Docs",
    value: EnvelopeSizes.large,
  },
];

const ParcelSizeRow: React.FC<any> = ({ onChange, ...otherProps }) => {
  const _onChange = (sizeType: ParcelSizeTypes, newValue: string | number) => {
    onChange({
      fieldName: `${otherProps.arrayName}[${otherProps.index}].${sizeType}`,
      value: newValue,
    });
  };

  // const handleSIzeFieldBlur = (event: React.ChangeEvent<HTMLInputElement>, sizeType: ParcelSizeTypes, maxDefaultValue: number): void => {
  //   if (+event.target.value <= 0) {
  //     _onChange(ParcelSizeTypes[sizeType], defaultPackages[sizeType])
  //   } else {
  //     const maxValue = getConvertedSizeValue({
  //       value: maxDefaultValue,
  //       unitSystem: otherProps.unitSystem,
  //       returnOnlyNumber: true,
  //     })

  //     if (event.target.value > maxValue) {
  //       _onChange(ParcelSizeTypes[sizeType], maxValue)
  //     }
  //   }
  // }

  const isMoreThenOneElement = otherProps.fields?.length > 1;

  const { isMobile } = useIsResponsive();

  const handleEnvelopeSize = (newEnvelopeSize: EnvelopeSizes) => {
    const _sizes =
      setPackageValues[otherProps.getValues("unit_of_measurement")][
        newEnvelopeSize
      ];

    // console.log("==", _sizes);

    Object.keys(_sizes).forEach((parcelSizeTypeName: any) => {
      // console.log("==", parcelSizeTypeName, _sizes[parcelSizeTypeName]);
      _onChange(parcelSizeTypeName, _sizes[parcelSizeTypeName]);
    });
  };

  return (
    <StyledRoot>
      <StyledWrapper>
        <StyledNumber isVisible={isMoreThenOneElement}>
          {otherProps.index + 1}
        </StyledNumber>

        {otherProps.parcelType === parcelTypes[0].value && (
          <StyledEnvelopeSizeSelect
            value={envelopeSizesOptions.find(
              (item) =>
                item.value ===
                otherProps.getValues(
                  `${otherProps.arrayName}.${otherProps.index}.envelopeSize`
                )
            )}
            withoutHover={true}
            options={envelopeSizesOptions}
            onChange={(value: any) => {
              handleEnvelopeSize(value.value);
              otherProps.setValue(
                `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
                value.value
              );
              process.env.REACT_APP_INFOBIP &&
                letterTypeEventInfobip(value, otherProps.index);
            }}
          />
        )}

        <MoneyInputWithLeftLabel
          label="Width"
          type="number"
          inputMode="decimal"
          step="0.1"
          {...otherProps}
          name={`${otherProps.arrayName}.${otherProps.index}.width`}
          disabled={
            otherProps.parcelType === parcelTypes[0].value &&
            (otherProps.getValues(
              `${otherProps.arrayName}.${otherProps.index}.envelopeSize`
            ) === EnvelopeSizes.small ||
              otherProps.getValues(
                `${otherProps.arrayName}.${otherProps.index}.envelopeSize`
              ) === EnvelopeSizes.large)
          }
          placeholder="0"
          unit={getConvertedSizeValue({
            unitSystem: otherProps.unitSystem,
            returnOnlyUnit: true,
            isMobile,
          })}
          value={otherProps.getValues(`packages[${otherProps.index}].width`)}
          onBlur={() => {
            process.env.REACT_APP_INFOBIP &&
              inputDimensionsEventInfobip(
                "Width",
                otherProps.getValues(`packages[${otherProps.index}].width`),
                otherProps.index
              );
          }}
          error={
            otherProps.getFieldState(`packages[${otherProps.index}].width`)
              ?.error?.message
          }
        />

        <MoneyInputWithLeftLabel
          label="Length"
          type="number"
          inputMode="decimal"
          step="0.1"
          {...otherProps}
          name={`${otherProps.arrayName}.${otherProps.index}.length`}
          disabled={
            otherProps.parcelType === parcelTypes[0].value &&
            (otherProps.getValues(
              `${otherProps.arrayName}.${otherProps.index}.envelopeSize`
            ) === EnvelopeSizes.small ||
              otherProps.getValues(
                `${otherProps.arrayName}.${otherProps.index}.envelopeSize`
              ) === EnvelopeSizes.large)
          }
          placeholder="0"
          unit={getConvertedSizeValue({
            unitSystem: otherProps.unitSystem,
            returnOnlyUnit: true,
            isMobile,
          })}
          value={otherProps.getValues(`packages[${otherProps.index}].length`)}
          onBlur={() => {
            process.env.REACT_APP_INFOBIP &&
              inputDimensionsEventInfobip(
                "Length",
                otherProps.getValues(`packages[${otherProps.index}].length`),
                otherProps.index
              );
          }}
          error={
            otherProps.getFieldState(`packages[${otherProps.index}].length`)
              ?.error?.message
          }
        />

        <MoneyInputWithLeftLabel
          label="Height"
          type="number"
          inputMode="decimal"
          step="0.1"
          placeholder="0"
          name={`${otherProps.arrayName}.${otherProps.index}.height`}
          unit={getConvertedSizeValue({
            unitSystem: otherProps.unitSystem,
            returnOnlyUnit: true,
            isMobile,
          })}
          disabled={
            otherProps.parcelType === parcelTypes[0].value &&
            (otherProps.getValues(
              `${otherProps.arrayName}.${otherProps.index}.envelopeSize`
            ) === EnvelopeSizes.small ||
              otherProps.getValues(
                `${otherProps.arrayName}.${otherProps.index}.envelopeSize`
              ) === EnvelopeSizes.large)
          }
          value={otherProps.getValues(`packages[${otherProps.index}].height`)}
          onBlur={() => {
            process.env.REACT_APP_INFOBIP &&
              inputDimensionsEventInfobip(
                "Height",
                otherProps.getValues(`packages[${otherProps.index}].height`),
                otherProps.index
              );
          }}
          error={
            otherProps.getFieldState(`packages[${otherProps.index}].height`)
              ?.error?.message
          }
          {...otherProps}
        />

        {isMobile && (
          <StyledSizeAndWeightSeparator>-</StyledSizeAndWeightSeparator>
        )}

        <MoneyInputWithLeftLabel
          tabindex="4"
          label="Weight"
          type="number"
          inputMode="decimal"
          step="0.01"
          placeholder="0"
          {...otherProps}
          name={`${otherProps.arrayName}.${otherProps.index}.weight`}
          disabled={
            otherProps.parcelType === parcelTypes[0].value &&
            (otherProps.getValues(
              `${otherProps.arrayName}.${otherProps.index}.envelopeSize`
            ) === EnvelopeSizes.small ||
              otherProps.getValues(
                `${otherProps.arrayName}.${otherProps.index}.envelopeSize`
              ) === EnvelopeSizes.large)
          }
          unit={getConvertedWeightValue({
            unitSystem: otherProps.unitSystem,
            returnOnlyUnit: true,
          })}
          value={otherProps.getValues(`packages[${otherProps.index}].weight`)}
          onBlur={() => {
            process.env.REACT_APP_INFOBIP &&
              inputDimensionsEventInfobip(
                "Weight",
                otherProps.getValues(`packages[${otherProps.index}].weight`),
                otherProps.index
              );
          }}
          error={
            otherProps.getFieldState(`packages[${otherProps.index}].weight`)
              ?.error?.message
          }
        />

        {isMoreThenOneElement ? (
          <BaseIcon
            className={styles.removeBtn}
            name={Name.CloseInCircle}
            isButton
            onClick={() => isMoreThenOneElement && otherProps.onRemove()}
          />
        ) : (
          <div className={styles.removeBtn} />
        )}
      </StyledWrapper>
    </StyledRoot>
  );
};

export default ParcelSizeRow;
