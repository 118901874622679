import styles from "./RateClassPriceCard.scss";

const getParamsRateClass = (tags: any) => {
  if (tags?.includes("CHEAPEST")) {
    return {
      label: "Cheapest",
      class: "rate-class-price-card__economy",
    };
  }

  if (tags?.includes("FASTEST")) {
    return {
      label: "Fastest",
      class: "rate-class-price-card__express",
    };
  }

  if (tags?.includes("BESTVALUE")) {
    return {
      label: "Optimal",
      class: "rate-class-price-card__optimal",
    };
  }

  return {
    label: "",
    class: "rate-class-price-card__undefined",
  };
};

export const RateClassPriceCard = ({ tags }: any) => {
  const params = getParamsRateClass(tags);

  return (
    <div
      className={`${styles["rate-class-price-card"]} ${styles[params.class]}`}
    >
      {params.label}
    </div>
  );
};
