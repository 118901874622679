import * as React from "react";

export const ILS: React.FC = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="-100 -100 1100 1100"
    enable-background="new 0 0 1000 1000"
    xmlSpace="preserve"
  >
    <path d="M711.6,355.2v345.2c0,6.5-2.1,11.8-6.3,16c-4.2,4.2-9.5,6.3-16,6.3H578c-6.5,0-11.8-2.1-16-6.3c-4.2-4.2-6.3-9.5-6.3-16V355.2c0-52-18.6-96.5-55.7-133.6c-37.1-37.1-81.7-55.7-133.6-55.7H177v801.8c0,6.5-2.1,11.8-6.3,16c-4.2,4.2-9.5,6.3-16,6.3H43.4c-6.5,0-11.8-2.1-16-6.3c-4.2-4.2-6.3-9.5-6.3-16V32.3c0-6.5,2.1-11.8,6.3-16c4.2-4.2,9.5-6.3,16-6.3h323c62.6,0,120.4,15.4,173.3,46.3c52.9,30.9,94.8,72.7,125.6,125.6S711.6,292.6,711.6,355.2L711.6,355.2z M978.9,32.3v612.5c0,62.6-15.4,120.4-46.3,173.3c-30.9,52.9-72.7,94.8-125.6,125.6C754,974.6,696.3,990,633.6,990h-323c-6.5,0-11.8-2.1-16-6.3c-4.2-4.2-6.3-9.5-6.3-16V299.5c0-6.5,2.1-11.8,6.3-16s9.5-6.3,16-6.3H422c6.5,0,11.8,2.1,16,6.3s6.3,9.5,6.3,16v534.5h189.3c52,0,96.5-18.6,133.6-55.7c37.1-37.1,55.7-81.7,55.7-133.6V32.3c0-6.5,2.1-11.8,6.3-16c4.2-4.2,9.5-6.3,16-6.3h111.4c6.5,0,11.8,2.1,16,6.3C976.8,20.4,978.9,25.8,978.9,32.3L978.9,32.3z" />
  </svg>
);
