export const checkIsVueRoute = (goToUrl: any) => {
  // console.log("=checkIsVueRoute=", goToUrl);

  const routesOnVue = [
    "my-shipments",
    "payments",
    "password/reset",
    "tracking",
    "blog",
    "about-us",
  ];
  // const routesOnVue = [
  //   "my-shipments",
  //   "payments",
  //   "password/reset",
  //   "tracking",
  //   "about-us",
  // ];
  // routesOnVue.push(...['page1', 'page2']) // для ТЕСТА

  const isVueRout = routesOnVue.find((item) => {
    // console.log("=find=", item, goToUrl);
    return goToUrl?.includes(item);
  });

  // console.log("=isVueRout=", isVueRout);
  return isVueRout;
};
