import { TextField } from "@mui/material";
import { styled } from "@mui/system";

import brandColors from "../../css/brandColors.scss";
import { BaseIcon } from "../BaseIcon";
import { Breakpoints, media } from "../../css/breakpoints";

export const StyledRoot = styled<any>("div")`
  width: ${({ moneyInput }) => (moneyInput ? "auto" : "100%")};
`;

export const StyledErrorMessage = styled<any>("div")`
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  color: ${brandColors.error};
  margin-top: 4px;
  min-height: 16px;
  text-align: ${({ moneyInput }) => (moneyInput ? "right" : "left")};
`;

export const StyledMoneyInput = styled<any>("input")`
  outline: none;
  border: none;
  background: none;
  width: 100%;
  height: 100%;
  text-align: ${({ prefix }) => (prefix ? "left" : "center")};
  /* color: ${brandColors.mainBlue}; */

  &::placeholder {
    color: #a8b6d2;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.44);
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledMoneyInputWrapper = styled<any>("div")`
  font-size: 14px;
  line-height: 19px;
  position: relative;

  /* color: ${brandColors.mainBlue}; */
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px;
  background: ${({ error }) => (error ? "rgba(255, 92, 92, 0.08)" : "#FFFFFF")};
  border-radius: 8px;
  padding-left: ${({ prefix }) => (prefix ? "32px" : "10")};
  transition: all 0.2s ease-in;

  @media ${media(Breakpoints.Mobile)} {
    padding: 4px;
  }

  /* &:hover {
    background: rgba(40, 68, 146, 0.05);
  } */
`;

export const StyledHint = styled("div")`
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  color: ${brandColors.lightBlueColor};
  margin-top: 2px;
  text-align: right;
`;

export const StyledPrefix = styled("div")`
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  color: ${brandColors.mainBlue};
`;

export const StyledSuffix = styled("div")`
  display: flex;
  align-items: center;
  color: ${brandColors.mainBlue};
`;

export const StyledSuffixWrapper = styled("button")`
  display: flex;
  align-items: center;
`;

export const StyledSuffixV2Wrapper = styled("span")`
  display: flex;
  align-items: center;
`;

export const StyledPrefixWrapper = styled("button")`
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledMainInputWrapper = styled(
  ({ error, children, ...props }: any) => <div {...props}>{children}</div>,
)`
  position: relative;
`;

export const StyledBaseIcon = styled(BaseIcon)`
  cursor: pointer;
`;

type StyledErrorFieldWrapperProps = {
  isMoneyInput: boolean;
};

export const StyledErrorFieldWrapper = styled(
  "div",
)<StyledErrorFieldWrapperProps>`
  position: ${({ isMoneyInput }) => (isMoneyInput ? "absolute" : "initial")};
  bottom: ${({ isMoneyInput }) => (isMoneyInput ? "-20px" : "initial")};
  width: 100%;
`;

export const StyledInput = styled(TextField)(({ withoutBorder }: any) => ({
  "& label.Mui-focused": {
    color: "#0000008F",
  },

  "& label": {
    color: "#0000008F",
  },

  "& label.Mui-error": {
    color: "#FF5C5C",
  },

  "& .MuiInput-input": {
    // color: '#284492'
  },

  "& label.Mui-error + .MuiInput-underline:before": {
    borderBottomColor: "#00000070",
    borderWidth: withoutBorder ? 0 : 1,
  },

  "& .MuiInput-underline:before": {
    borderBottomColor: "#00000070",
    borderWidth: withoutBorder ? 0 : 1,
  },

  "& .MuiInput-underline": {
    // borderWidth: 0
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#284492",
    borderWidth: withoutBorder ? 0 : 2,
  },

  "& label.Mui-error + .MuiInput-underline:after": {
    borderBottomColor: "#FF5C5C",
  },

  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: "#284492 !important",
    borderWidth: withoutBorder ? 0 : 1,
  },

  "& .MuiInput-underline:hover": {
    // borderWidth: 0
  },

  "& .MuiInput-underline:hover:after": {
    // borderBottomColor: '#284492',
    // borderWidth: withoutBorder ? 0 : 1
  },

  "input:-webkit-autofill": {
    "-webkit-text-fill-color": "rgba(0, 0, 0, 0.87)",
  },

  "input::placeholder": {
    color: "#0000008F",
    opacity: "1",
    "font-weight": "normal",
  },
}));
