// import { createSelector } from 'reselect';

import { REDUCER_NAME } from "../constants";

export interface IModuleState {
  [REDUCER_NAME]: any;
}

export const selectProfileModule = (state: IModuleState) => state[REDUCER_NAME];

export const selectProfileLoading = (state: IModuleState) =>
  selectProfileModule(state).loading;
