import { eventInfobip } from "./infobip";
import { EnvelopeSizes } from "../../scenes/home/data/constants";

const envelopeSizesOptions = [
  {
    label: "Custom Letters or Docs",
    value: EnvelopeSizes.custom,
  },
  {
    label: "Small Letters or Docs",
    value: EnvelopeSizes.small,
  },
  {
    label: "Large Letters or Docs",
    value: EnvelopeSizes.large,
  },
];

export default function letterTypeEventInfobip(value: any, index: number) {
  // console.log("=letterTypeEventInfobip=", value);

  eventInfobip("LetterType", {
    type: envelopeSizesOptions.find((item) => item.value === value.value)
      ?.label,
    number: index,
  });
}
