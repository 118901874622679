import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ShipmentAPI } from "../../api";
import { handlerReceivedShipment } from "./handlerReceivedShipment";

import { BasePage } from "../../components/BasePage";
import { Form } from "./components/Form";
import { BaseLoading } from "../../components/BaseLoading";
import { AgitationBannersHomePage } from "./components/AgitationBannersHomePage/AgitationBannersHomePage";
import "./homePage.css";

const HomeRtl: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [shipment, setShipment] = useState(null);
  let shipmentId = searchParams.get("shipment_id");
  let shipmentIdInLocalStorage = "";

  useEffect(() => {
    const html = document.getElementsByTagName("html");
    html[0].setAttribute("dir", "rtl");

    (async () => {
      // console.log("=useEffect in HOME=", shipmentId, searchParams.get('step'));
      if (!shipmentId) {
        // ! Этот код нужен, чтобы при переходе назад со страницы Рейтов (по кнопке назад в браузере), страница Home знала о созданном шипменте и в поля подставлялись его данные
        await new Promise((resolve) => setTimeout(resolve, 200));

        shipmentIdInLocalStorage =
          localStorage.getItem("shipment_id_for_home") || "";

        if (shipmentIdInLocalStorage) {
          setSearchParams({ shipment_id: shipmentIdInLocalStorage, step: "1" });
          localStorage.removeItem("shipment_id_for_home");
        }
      } else {
        shipmentIdInLocalStorage =
          localStorage.getItem("shipment_id_for_home") || "";
        if (shipmentIdInLocalStorage) {
          setSearchParams({ shipment_id: shipmentIdInLocalStorage, step: "1" });
          localStorage.removeItem("shipment_id_for_home");
        }
      }

      if (shipmentId || shipmentIdInLocalStorage) {
        setLoading(true);

        try {
          const response = await ShipmentAPI.getShipment(
            shipmentId || shipmentIdInLocalStorage,
          );

          setShipment(handlerReceivedShipment(response, dispatch));
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  return (
    <BasePage withoutBottomPadding>
      {loading ? (
        <BaseLoading />
      ) : (
        <>
          <div className="top-section-homepage">
            <Form name="packages" shipment={shipment} />
          </div>

          {process.env.REACT_APP_IS_MOBILE_APP === "false" && (
            <AgitationBannersHomePage />
          )}
        </>
      )}
    </BasePage>
  );
};

export default HomeRtl;
