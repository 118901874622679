import React from "react";

import "./UiPageBackground.css";

const UiPageBackground = () => {
  return (
    <div className="ui-page-background">
      <svg
        width="1297"
        height="1655"
        viewBox="0 0 1297 1655"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1257 179C1257 182.866 1253.87 186 1250 186C1246.13 186 1243 182.866 1243 179C1243 175.134 1246.13 172 1250 172C1253.87 172 1257 175.134 1257 179"
          fill="#284492"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M542.805 445.562C534.482 443.113 532.875 441.486 530.522 433.122C530.339 432.459 529.733 432.004 529.047 432C528.358 431.996 527.747 432.45 527.558 433.105C525.109 441.435 523.483 443.044 515.122 445.395C514.461 445.578 514.004 446.184 514 446.872C513.996 447.558 514.45 448.171 515.106 448.363C523.43 450.812 525.041 452.437 527.39 460.8C527.577 461.46 528.183 461.923 528.871 461.927C529.556 461.932 530.169 461.475 530.361 460.82C532.809 452.49 534.433 450.885 542.794 448.53C543.458 448.347 543.914 447.741 543.918 447.053C543.916 446.365 543.469 445.761 542.805 445.562"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M255.256 171.064C249.692 169.427 248.618 168.339 247.044 162.75C246.922 162.307 246.517 162.003 246.059 162C245.598 161.997 245.19 162.301 245.063 162.738C243.426 168.305 242.339 169.381 236.75 170.952C236.308 171.074 236.003 171.479 236 171.939C235.997 172.397 236.301 172.807 236.74 172.935C242.304 174.572 243.381 175.658 244.951 181.247C245.076 181.688 245.481 181.997 245.941 182C246.399 182.003 246.809 181.698 246.937 181.26C248.574 175.693 249.659 174.621 255.249 173.047C255.692 172.925 255.997 172.519 256 172.06C255.999 171.6 255.699 171.196 255.256 171.064"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1058.58 13.7664C1064.19 14.3867 1065.4 15.2459 1067.87 20.3348C1068.06 20.739 1068.5 20.9609 1068.94 20.8856C1069.38 20.8098 1069.72 20.4492 1069.77 20.0082C1070.38 14.3931 1071.24 13.1767 1076.33 10.7179C1076.73 10.5254 1076.96 10.0849 1076.88 9.64378C1076.81 9.20403 1076.44 8.86314 1076 8.81512C1070.39 8.19482 1069.17 7.33754 1066.72 2.24819C1066.52 1.84724 1066.08 1.61973 1065.64 1.69528C1065.2 1.77059 1064.86 2.13279 1064.81 2.57412C1064.19 8.18919 1063.33 9.40255 1058.25 11.8641C1057.84 12.0569 1057.62 12.4973 1057.7 12.9385C1057.78 13.3789 1058.13 13.7149 1058.58 13.7664Z"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.954 44.9803C81.954 51.044 77.0389 55.9606 70.977 55.9606C64.9151 55.9606 60 51.044 60 44.9803C60 38.9166 64.9151 34 70.977 34C77.0389 34 81.954 38.9166 81.954 44.9803"
          fill="#284492"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M218.119 342.059C221.249 341.658 223.463 338.796 223.062 335.667C222.662 332.537 219.799 330.325 216.668 330.725C213.538 331.126 211.325 333.988 211.725 337.118C212.126 340.247 214.989 342.46 218.119 342.059"
          fill="#284492"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M903.218 192.577C897.954 191.028 896.937 189.999 895.449 184.71C895.333 184.29 894.95 184.003 894.517 184C894.08 183.997 893.694 184.284 893.574 184.699C892.026 189.967 890.997 190.985 885.709 192.471C885.291 192.587 885.003 192.971 885 193.406C884.997 193.839 885.284 194.227 885.7 194.348C890.964 195.898 891.983 196.925 893.468 202.215C893.587 202.632 893.97 202.924 894.405 202.927C894.838 202.93 895.226 202.641 895.347 202.227C896.896 196.959 897.923 195.944 903.211 194.454C903.63 194.339 903.919 193.955 903.922 193.52C903.92 193.085 903.637 192.703 903.218 192.577"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1242.3 486.146C1244.34 494.579 1243.74 496.784 1237.67 503.004C1237.19 503.494 1237.1 504.246 1237.44 504.842C1237.78 505.442 1238.48 505.743 1239.14 505.58C1247.58 503.535 1249.78 504.139 1256 510.205C1256.49 510.686 1257.24 510.778 1257.84 510.437C1258.43 510.098 1258.74 509.399 1258.58 508.734C1256.54 500.301 1257.14 498.093 1263.21 491.877C1263.68 491.385 1263.78 490.629 1263.44 490.031C1263.1 489.436 1262.4 489.133 1261.74 489.295C1253.3 491.339 1251.1 490.735 1244.88 484.672C1244.39 484.189 1243.63 484.097 1243.04 484.437C1242.44 484.783 1242.14 485.472 1242.3 486.146"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1093 839.65C13.6228 831.704 11.8049 830.317 3.21054 829.045C2.52902 828.947 2.00155 828.404 1.91017 827.725C1.81824 827.041 2.19128 826.378 2.81678 826.107C10.7687 822.621 12.1588 820.803 13.4288 812.211C13.5264 811.532 14.07 811.003 14.7518 810.911C15.4314 810.82 16.097 811.192 16.3707 811.819C19.8571 819.765 21.6735 821.156 30.2672 822.425C30.9449 822.527 31.4812 823.069 31.5728 823.751C31.6642 824.43 31.2892 825.096 30.664 825.369C22.7121 828.855 21.3261 830.67 20.0517 839.262C19.9544 839.943 19.4108 840.473 18.729 840.564C18.0464 840.65 17.3903 840.282 17.1093 839.65"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M438.084 33.6116C440.319 33.3255 441.9 31.2818 441.614 29.0468C441.328 26.8118 439.284 25.232 437.048 25.5181C434.812 25.8042 433.232 27.8479 433.518 30.0829C433.804 32.3179 435.848 33.8977 438.084 33.6116"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0838 1090.61C18.3194 1090.33 19.8999 1088.28 19.6138 1086.05C19.3278 1083.81 17.2836 1082.23 15.048 1082.52C12.8123 1082.8 11.2319 1084.85 11.5179 1087.08C11.8039 1089.32 13.8481 1090.9 16.0838 1090.61"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.7783 401.482C39.7592 401.101 41.8665 398.376 41.4851 395.396C41.1037 392.416 38.3782 390.309 35.3973 390.691C32.4164 391.072 30.3092 393.797 30.6905 396.777C31.0719 399.757 33.7974 401.864 36.7783 401.482Z"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M640.852 1649.97C641.605 1647.63 640.315 1645.12 637.971 1644.36C635.626 1643.61 633.116 1644.9 632.363 1647.25C631.61 1649.59 632.9 1652.1 635.245 1652.85C637.589 1653.61 640.099 1652.32 640.852 1649.97"
          fill="#284492"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1274.79 864.811C1277.15 864.117 1278.51 861.64 1277.81 859.277C1277.12 856.915 1274.64 855.563 1272.28 856.257C1269.92 856.951 1268.56 859.428 1269.26 861.79C1269.95 864.153 1272.43 865.505 1274.79 864.811"
          fill="#284492"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1287.57 1417.29C1282.59 1420.76 1275.75 1419.54 1272.28 1414.57C1268.81 1409.6 1270.03 1402.75 1275.01 1399.28C1279.98 1395.81 1286.82 1397.03 1290.29 1402C1293.76 1406.97 1292.54 1413.82 1287.57 1417.29"
          fill="#284492"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M641.565 1050.89C638.412 1058.97 638.717 1061.24 643.894 1068.22C644.307 1068.77 644.296 1069.52 643.88 1070.07C643.462 1070.62 642.729 1070.82 642.095 1070.57C634.007 1067.41 631.739 1067.72 624.766 1072.89C624.216 1073.31 623.458 1073.3 622.911 1072.88C622.366 1072.46 622.158 1071.73 622.408 1071.09C625.561 1063.01 625.261 1060.74 620.081 1053.77C619.674 1053.21 619.678 1052.45 620.095 1051.9C620.511 1051.36 621.247 1051.15 621.882 1051.4C629.97 1054.56 632.233 1054.26 639.21 1049.08C639.761 1048.67 640.519 1048.68 641.066 1049.1C641.609 1049.52 641.814 1050.24 641.565 1050.89"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M692.968 1390.18C691.607 1391.98 689.048 1392.33 687.252 1390.97C685.456 1389.61 685.103 1387.05 686.464 1385.25C687.825 1383.46 690.384 1383.1 692.18 1384.46C693.976 1385.82 694.329 1388.38 692.968 1390.18"
          fill="#FF5C5C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.1571 1490.73C44.1981 1491.69 45.0778 1494.12 44.1219 1496.16C43.166 1498.2 40.7366 1499.08 38.6955 1498.12C36.6545 1497.16 35.7749 1494.73 36.7308 1492.69C37.6867 1490.65 40.116 1489.78 42.1571 1490.73"
          fill="#FF5C5C"
        />
      </svg>
    </div>
  );
};

export default UiPageBackground;
