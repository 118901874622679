import { ProviderId } from "../scenes/checkout/data/models";

export const getProhibitedItems = (
  providerId: ProviderId,
  countryFromCode: string,
  countryToCode: string,
): string | undefined => {
  switch (providerId) {
    case ProviderId.UPS:
    case ProviderId.UPS_VELOX:
      return `https://www.ups.com/ga/CountryRegsPrint?loc=en_US&origcountry=${countryFromCode}&destcountry=${countryToCode}&cat=002&PrintRegulations=PrintRegulations  `;
    case ProviderId.FEDEX:
      return "https://www.fedex.com/en-il/shipping/international-prohibited-items.html";
    case ProviderId.DHL:
      return `https://mydhl.express.dhl/${countryToCode.toLocaleLowerCase()}/en/help-and-support/shipping-advice/what-can-i-ship/prohibited-items.html#/standard_prohibited `;
  }
};
