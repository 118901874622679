import { styled } from "@mui/system";
import { headerHeightWithMargin } from "../TheHeader/styles/TheHeader.styled";

const StyledPage: any = styled("div")(
  ({ withoutBottomPadding }: any) => `
    // min-height: calc(100vh - ${headerHeightWithMargin});
    min-height: 100vh;
    padding-top: ${headerHeightWithMargin};
    padding-bottom: ${withoutBottomPadding ? "0" : "100px"};
    position: relative;
    height: 100%;
  `,
);

const BasePage: any = ({
  children,
  className,
  paddingTop,
  marginLeft,
  blueBackground,
  withoutBottomPadding,
}: any) => {
  return (
    <StyledPage
      style={{
        paddingTop,
        marginLeft,
        background: blueBackground ? "#F6F6F9" : "none",
      }}
      className={className}
      withoutBottomPadding={withoutBottomPadding}
    >
      {children}
    </StyledPage>
  );
};

export default BasePage;
