import { styled } from "@mui/system";
import { Breakpoints, media } from "../../../../../../../css/breakpoints";

export const StyledActionFooter = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    margin: 16px 0 24px 0;
  }
`;
