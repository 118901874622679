import React from "react";
import {
  getConvertedSizeValue,
  getConvertedWeightValue,
} from "../../services/convertUnitSystemValues";
import { MoneyInputWithLeftLabel } from "./MoneyInputWithLeftLabel";
import { BaseIcon, Name } from "../BaseIcon";
import { ParcelSizeTypes } from "../../scenes/home/data/constants";
import { useIsResponsive } from "../../services/breakpoint/useIsResponsive";
import { parcelTypes } from "../../scenes/home/data/constants";
import styles from "./ParcelSizeRow.scss";
import {
  StyledRoot,
  StyledNumber,
  StyledWrapper,
  StyledEnvelopeSizeSelect,
  StyledSizeAndWeightSeparator,
} from "./ParcelSizeRow.styled";
import { setPackageValues } from "../../scenes/home/components/Form/services/setPackageValues";
import { EnvelopeSizes } from "../../scenes/home/data/constants";

const envelopeSizesOptions = [
  {
    label: "Small Letters or Docs",
    value: EnvelopeSizes.small,
  },
  {
    label: "Large Letters or Docs",
    value: EnvelopeSizes.large,
  },
  {
    label: "Custom Letters or Docs",
    value: EnvelopeSizes.custom,
  },
];

export const ParcelSizeRow: React.FC<any> = ({ onChange, ...otherProps }) => {
  // console.log("=otherProps=", otherProps);

  const { isMobile, innerWidth } = useIsResponsive();

  const isMoreThenOneElement = otherProps.fields?.length > 1;

  const _onChange = (sizeType: ParcelSizeTypes, newValue: string | number) => {
    onChange({
      fieldName: `${otherProps.arrayName}[${otherProps.index}].${sizeType}`,
      value: newValue,
    });
  };

  const handleEnvelopeSize = (newEnvelopeSize: EnvelopeSizes) => {
    const _sizes =
      setPackageValues[
        `${otherProps.getValues("unit_of_measurement") === "imperial"}`
      ][newEnvelopeSize];

    // console.log("==", _sizes);

    Object.keys(_sizes).forEach((parcelSizeTypeName: any) => {
      // console.log("==", parcelSizeTypeName, _sizes[parcelSizeTypeName]);
      _onChange(parcelSizeTypeName, _sizes[parcelSizeTypeName]);
    });
  };

  return (
    <StyledRoot>
      <StyledWrapper>
        <StyledNumber>{otherProps.index + 1}</StyledNumber>

        {otherProps.parcelType === parcelTypes[0].value && (
          <StyledEnvelopeSizeSelect
            className={styles.StyledEnvelopeSizeSelect}
            value={envelopeSizesOptions.find(
              (item) =>
                item.value ===
                otherProps.getValues(
                  `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
                ),
            )}
            withoutHover={true}
            options={envelopeSizesOptions}
            onChange={(value: any) => {
              handleEnvelopeSize(value.value);
              otherProps.setValue(
                `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
                value.value,
              );
            }}
          />
        )}

        <div className={styles.inputs}>
          <MoneyInputWithLeftLabel
            label="Width"
            type="number"
            inputMode="decimal"
            step="0.1"
            {...otherProps}
            name={`${otherProps.arrayName}.${otherProps.index}.width`}
            disabled={
              otherProps.parcelType === parcelTypes[0].value &&
              (otherProps.getValues(
                `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
              ) === EnvelopeSizes.small ||
                otherProps.getValues(
                  `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
                ) === EnvelopeSizes.large)
            }
            placeholder="0"
            unit={getConvertedSizeValue({
              unitSystem: otherProps.unitSystem,
              returnOnlyUnit: true,
              isMobile,
            })}
            value={otherProps.getValues(`packages[${otherProps.index}].width`)}
            error={
              otherProps.getFieldState(`packages[${otherProps.index}].width`)
                ?.error?.message
            }
          />

          <MoneyInputWithLeftLabel
            label="Length"
            type="number"
            inputMode="decimal"
            step="0.1"
            {...otherProps}
            name={`${otherProps.arrayName}.${otherProps.index}.length`}
            disabled={
              otherProps.parcelType === parcelTypes[0].value &&
              (otherProps.getValues(
                `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
              ) === EnvelopeSizes.small ||
                otherProps.getValues(
                  `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
                ) === EnvelopeSizes.large)
            }
            placeholder="0"
            unit={getConvertedSizeValue({
              unitSystem: otherProps.unitSystem,
              returnOnlyUnit: true,
              isMobile,
            })}
            value={otherProps.getValues(`packages[${otherProps.index}].length`)}
            error={
              otherProps.getFieldState(`packages[${otherProps.index}].length`)
                ?.error?.message
            }
          />

          <MoneyInputWithLeftLabel
            label="Height"
            type="number"
            inputMode="decimal"
            step="0.1"
            placeholder="0"
            name={`${otherProps.arrayName}.${otherProps.index}.height`}
            unit={getConvertedSizeValue({
              unitSystem: otherProps.unitSystem,
              returnOnlyUnit: true,
              isMobile,
            })}
            disabled={
              otherProps.parcelType === parcelTypes[0].value &&
              (otherProps.getValues(
                `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
              ) === EnvelopeSizes.small ||
                otherProps.getValues(
                  `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
                ) === EnvelopeSizes.large)
            }
            value={otherProps.getValues(`packages[${otherProps.index}].height`)}
            error={
              otherProps.getFieldState(`packages[${otherProps.index}].height`)
                ?.error?.message
            }
            {...otherProps}
          />

          {innerWidth > 600 && (
            <StyledSizeAndWeightSeparator>-</StyledSizeAndWeightSeparator>
          )}

          <MoneyInputWithLeftLabel
            tabindex="4"
            label="Weight"
            type="number"
            inputMode="decimal"
            step="0.01"
            placeholder="0"
            {...otherProps}
            name={`${otherProps.arrayName}.${otherProps.index}.weight`}
            disabled={
              otherProps.parcelType === parcelTypes[0].value &&
              (otherProps.getValues(
                `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
              ) === EnvelopeSizes.small ||
                otherProps.getValues(
                  `${otherProps.arrayName}.${otherProps.index}.envelopeSize`,
                ) === EnvelopeSizes.large)
            }
            unit={getConvertedWeightValue({
              unitSystem: otherProps.unitSystem,
              returnOnlyUnit: true,
            })}
            value={otherProps.getValues(`packages[${otherProps.index}].weight`)}
            error={
              otherProps.getFieldState(`packages[${otherProps.index}].weight`)
                ?.error?.message
            }
          />
        </div>

        {isMoreThenOneElement ? (
          <BaseIcon
            className={styles.removeBtn}
            name={Name.CloseInCircle}
            isButton
            onClick={() => isMoreThenOneElement && otherProps.onRemove()}
          />
        ) : (
          <div className={styles.removeBtn} />
        )}
      </StyledWrapper>
    </StyledRoot>
  );
};
