import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import { styled } from "@mui/system";

const StyledLink = styled(({ withoutRedirect, ...props }: any) =>
  withoutRedirect ? (
    <span {...props}>{props.children}</span>
  ) : (
    <Link {...props} />
  ),
)(
  () => `
`,
);

const BaseLink = (props: any) => {
  const { to, className, underline, withoutRedirect, children, ...restProps } =
    props;

  return (
    <StyledLink
      component={RouterLink}
      to={to}
      className={className}
      underline={underline}
      style={{ color: props.color }}
      withoutRedirect={withoutRedirect}
      {...restProps}
    >
      {children}
    </StyledLink>
  );
};

BaseLink.defaultProps = {
  underline: "none",
  to: "/",
};

export default BaseLink;
