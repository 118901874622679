import { styled } from "@mui/system";
import brandColors from "../../../../../../../../css/brandColors.scss";

export const paddingRightItem = "16px";

export const StyledRoot = styled("div")`
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: ${brandColors["lightGray-100"]};
  border-radius: 8px;
  padding: 16px ${paddingRightItem} 4px 16px;
  gap: 8px 20px;
`;

export const StyledUnit = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${brandColors.lightBlueColor};
`;
