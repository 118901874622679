import API from "./index";
import { getErrorMessage } from "../services/getErrorMessage";
import { Notify } from "../services/notifications";

export const getAddressBookList = async ({
  page,
  search,
  countryCode,
  cityName,
}: any) => {
  const params = new URLSearchParams();

  page && params.append("page", page);
  search && params.append("search", search);
  countryCode && params.append("filters[country_code]", countryCode);
  cityName && params.append("filters[city_name]", cityName);

  try {
    const res = await API.get("/address-book/addresses", { params });

    return res.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};

export const createAddressToBook = async (address: any) => {
  try {
    const res = await API.post("/address-book/addresses/", address);

    return res.data.data;
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
};
