import * as React from "react";

import { StyledCard } from "./styles/BaseCard.styld";

type Position = "static" | "relative" | "absolute" | "sticky" | "fixed";

type Props = {
  children: React.ReactNode;
  padding?: string;
  className?: string;
  position?: Position;
  withShadow?: boolean;
  style?: object;
};

const BaseCard: React.FC<Props> = ({
  children,
  padding,
  className,
  position,
  withShadow,
  style,
}) => {
  return (
    <StyledCard
      className={className}
      style={{ padding, position, ...style }}
      withShadow={withShadow}
    >
      {children}
    </StyledCard>
  );
};

export default BaseCard;
