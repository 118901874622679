import API from "../../../api";
import type { SocialProvidersValuesType } from "../../../constants/SocialProviders";
import { SignUpSocialProps } from "../interfaces";

class AuthRequests {
  static signIn = async (formValues: any) => {
    const response = await API.post("/login", formValues);

    return response;
  };

  static signUp = async (formValues: any) => {
    const response = await API.post("/register", formValues);

    return response;
  };

  static signOut = async () => {
    const response = await API.post("/logout");

    return response;
  };

  static resetPassword = async (email: string) => {
    const response = await API.post("/password/reset", { email });

    if (response.data?.error) {
      return response.data;
    }

    return response.data.data;
  };

  static checkResetPassword = async (formValues: any) => {
    const response = await API.post("/password/reset/check", formValues);

    if (response.data?.error) {
      return response.data;
    }

    return response.data.data;
  };

  static setNewPassword = async (formValues: any) => {
    const response = await API.post("/password/reset/set", formValues);

    if (response.data?.error) {
      return response.data;
    }

    return response.data.data;
  };

  static getUrlToLoginViaSocial = async (
    provider: SocialProvidersValuesType,
  ) => {
    const res = await API.get(`/socialite/${provider}`);

    return res.data.data;
  };

  static signUpViaSocial = async ({
    provider,
    code,
    authuser,
    scope,
    prompt,
  }: SignUpSocialProps) => {
    const body: any = {};

    provider && (body.provider = provider);
    code && (body.code = code);
    authuser && (body.authuser = authuser);
    prompt && (body.prompt = prompt);
    scope && (body.scope = scope);

    const response = await API.post(`/socialite/${provider}`, body);

    return response;
  };

  static getGEOIP = async () => {
    const response = await API.get("/customer_api/geoip");

    return response;
  };
}

export default AuthRequests;
