import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BasePage } from "../../components/BasePage";
import { WrapperCheckout } from "./WrapperCheckout";
import { useDispatch } from "react-redux";
import { getShipment } from "../../data/actions";

export const Checkout = () => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");

  const params = useParams();

  const shipmentId = params.id || "";

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getShipment(shipmentId));
  }, []);

  return (
    <BasePage blueBackground withoutBottomPadding>
      <WrapperCheckout shipmentId={shipmentId} />
    </BasePage>
  );
};
