import { IconButton } from "@mui/material";
import { BaseIcon, Name } from "../../components/BaseIcon";
import { StyledTooltip } from "../../components/TooltipBase.styled";

export const PassportTooltip = () => {
  return (
    <StyledTooltip title="For customs clearance, senders and recipients may be required to provide official identification such as a national ID, passport or driver’s license to avoid delays.">
      <IconButton style={{ color: "#757575", cursor: "default" }} disableRipple>
        <BaseIcon name={Name.Info} size={32} />
      </IconButton>
    </StyledTooltip>
  );
};
