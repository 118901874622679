import { PropsWithChildren } from "react";
import { Container, Breakpoint } from "@mui/system";
import { TheHeader } from "../../components/TheHeader";
import { BasePage } from "../../components/BasePage";
import { MenuLayoutWithLeftMenu } from "./MenuLayoutWithLeftMenu";
import { TheFooter } from "../../components/TheFooter";
import { SearchInput } from "../../components/SearchInput";
import styles from "./LayoutWithLeftMenu.scss";
import { useIsResponsive } from "../../services/breakpoint/useIsResponsive";

interface LeftMenuLayoutProps extends PropsWithChildren<{}> {
  title: string;
  searchPlaceholder?: string;
  searchValue?: string;
  maxWidthContainer?: Breakpoint;
  suffixToTop?: any;
  onChangeSearchValue?(search: string): void;
  onSearch?(): void;
}

export const LayoutWithLeftMenu = ({
  title,
  searchPlaceholder,
  searchValue,
  maxWidthContainer = "lg",
  suffixToTop,
  onChangeSearchValue,
  onSearch,
  children,
}: LeftMenuLayoutProps) => {
  const { isMobile } = useIsResponsive();
  const showSearchInput = !!onSearch && !!onChangeSearchValue;

  return (
    <>
      <TheHeader maxWidthContainer={maxWidthContainer} />

      <BasePage blueBackground>
        <Container maxWidth={maxWidthContainer}>
          <div className={styles.layoutWithLeftMenu}>
            {!isMobile && (
              <MenuLayoutWithLeftMenu
                className={styles.layoutWithLeftMenuWrapMenu}
              />
            )}

            <div className={styles.layoutWithLeftMenuContentPage}>
              <div className={styles.layoutWithLeftMenuContentPageTop}>
                <h1 className={styles.layoutWithLeftMenuContentPageTopTitle}>
                  {title}
                </h1>

                {showSearchInput && (
                  <SearchInput
                    className={styles.layoutWithLeftMenuContentPageTopSearch}
                    searchValue={searchValue}
                    placeholder={searchPlaceholder}
                    onChange={onChangeSearchValue}
                    onSearch={onSearch}
                  />
                )}

                {suffixToTop}
              </div>

              {children}
            </div>
          </div>
        </Container>
      </BasePage>

      {!isMobile && <TheFooter />}
    </>
  );
};
