import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FIELD_IS_REQUIRED } from "../../../../../../constants";
import {
  isValidPassword,
  getMinValidationMessage,
  getMaxValidationMessage,
} from "../../../../../../services/validation";
import { ENTER_VALID_EMAIL } from "../../../../data/constants";

const isPasswordsMatch = (value: any, formValues: any): boolean =>
  value === formValues.parent.password;

export const validationSchema = yupResolver(
  yup.object({
    first_name: yup
      .string()
      .required(FIELD_IS_REQUIRED)
      .min(1, getMinValidationMessage(1))
      .max(50, getMaxValidationMessage(50)),
    last_name: yup
      .string()
      .required(FIELD_IS_REQUIRED)
      .min(1, getMinValidationMessage(1))
      .max(50, getMaxValidationMessage(50)),
    email: yup.string().email(ENTER_VALID_EMAIL).required(FIELD_IS_REQUIRED),
    phone: yup.number().required(FIELD_IS_REQUIRED),
    password: yup
      .string()
      .required(FIELD_IS_REQUIRED)
      .min(6, getMinValidationMessage(6))
      .test(
        "password",
        "The password must be at least 6 characters, upper and lower case, and includes a number and a symbol.",
        (value?: string) => isValidPassword(value),
      ),
    // password_confirmation: yup
    //   .string()
    //   .oneOf([yup.ref("password"), null, "Passwords does not match")
    //   .required(FIELD_IS_REQUIRED),
    password_confirmation: yup
      .string()
      .required(FIELD_IS_REQUIRED)
      .test(
        "password",
        "Passwords does not match",
        (value: any, formValues: any): boolean =>
          isPasswordsMatch(value, formValues),
      ),
  }),
);
