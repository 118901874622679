import * as React from "react";

export const EyeOpen: React.FC = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.83 12a13.64 13.64 0 0 0 2.094 2.99c1.537 1.655 3.852 3.26 7.076 3.26 3.224 0 5.539-1.605 7.075-3.26A13.645 13.645 0 0 0 21.17 12a13.642 13.642 0 0 0-2.094-2.99C17.54 7.355 15.224 5.75 12 5.75S6.461 7.355 4.925 9.01A13.638 13.638 0 0 0 2.83 12ZM22 12l.69-.296h-.001l-.001-.003-.003-.006-.008-.02a6.186 6.186 0 0 0-.149-.311 13.135 13.135 0 0 0-.462-.841 15.13 15.13 0 0 0-1.891-2.533C18.46 6.145 15.776 4.25 12 4.25c-3.776 0-6.461 1.895-8.175 3.74a15.139 15.139 0 0 0-1.891 2.533 13.19 13.19 0 0 0-.58 1.084 4.968 4.968 0 0 0-.03.068l-.01.02-.002.006v.002H1.31c0 .001 0 .002.689.297l-.69-.295a.75.75 0 0 0 0 .59L2 12l-.69.296h.001l.001.003.003.006.009.02.03.068a13.19 13.19 0 0 0 .58 1.084c.41.69 1.035 1.61 1.891 2.533C5.54 17.855 8.224 19.75 12 19.75c3.776 0 6.461-1.895 8.175-3.74a15.126 15.126 0 0 0 1.891-2.533 13.135 13.135 0 0 0 .58-1.084l.03-.068.01-.02.002-.006v-.002h.001c0-.001 0-.002-.689-.297Zm0 0 .69.295a.75.75 0 0 0 0-.59L22 12Z"
      fill="#284492"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM8.25 12a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z"
      fill="#284492"
    />
  </svg>
);
