import getUrlAndLocation from "./getUrlAndLocation";
import goToVueInRoot from "./goToVueInRoot";

export const AppPasswordReset = () => {
  // ! Без этого при переходе по ссылке (к пимеру из почты на страницу Сброса пароля) вначале открывается страница 404 и потом только страница Сброса пароля
  // console.log("=AppPasswordReset=", );

  const { _location, url } = getUrlAndLocation;

  goToVueInRoot(url, _location);

  return <></>;
};
