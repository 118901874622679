import { takeLatest, put, call } from "redux-saga/effects";

import * as actions from "../actions";
import { hideLoader, showLoader } from "../../../../data/actions";
import { getUser } from "../../../../data/sagas";
import {
  getStore,
  removeStore,
} from "../../../../integrationWithVue/handleBrowserStore";
import { getErrorMessage } from "../../../../services/getErrorMessage";
import { Notify } from "../../../../services/notifications";
import AuthRequests from "../../api";
import { getTransformedFromValuesForApi } from "../../services/getTransformedFromValuesForApi";
import { history } from "../../../../services/history";
import { DEFAULT_ERROR_MESSAGE } from "../../../../data/constants";
import { checkFormErrors } from "../../../../services/formErrors/checkFormErrors";
import { putAuthTokenInCookie } from "../../services/putAuthTokenInCookie";
import { goToAuthError } from "../../services/goToAuthError";
import { updatePersonInfobip } from "../../../../plugins/infobip/infobip";

function* signIn(action: any): any {
  try {
    yield put(showLoader());

    if (getStore("token")) {
      yield put(actions.signOut());
    }

    const response: any = yield call(
      AuthRequests.signIn,
      getTransformedFromValuesForApi(action.payload)
    );

    if (response.status !== 200) {
      throw new Error(response.data.message);
    }

    putAuthTokenInCookie(response.data.data.access_token);

    yield call(getUser);

    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER) {
      dataLayer.push({
        event: "sign_in",
        var_uid: response.data.data.id,
      });
    }

    if (process.env.REACT_APP_INFOBIP) {
      const data: any = {
        firstName: response.data.data.first_name,
        lastName: response.data.data.last_name,
      };
      response.data.data.phone &&
        (data.contactInformation = {
          phone: [{ number: response.data.data.phone }],
        });

      updatePersonInfobip(data);
    }

    // Notify({ message: 'You successfully log in!', type: 'success' })
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

function* signUp(action: any): any {
  yield put(showLoader());

  try {
    if (getStore("token")) {
      yield put(actions.signOut());
    }

    const response: any = yield call(
      AuthRequests.signUp,
      getTransformedFromValuesForApi(action.payload)
    );

    yield checkFormErrors({ response });

    putAuthTokenInCookie(response.data.data.access_token);

    yield call(getUser);

    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER) {
      dataLayer.push({
        event: "sign_up",
        var_uid: response.data.data.id,
      });
    }

    Notify({ message: "You successfully log in!", type: "success" });
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

function* signOut(): any {
  try {
    yield call(AuthRequests.signOut);

    removeStore("token");
    localStorage.clear();

    Notify({ message: "You successfully log out!", type: "success" });
    history.push("/auth");
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  }
}

function* resetPassword(action: any): any {
  try {
    yield put(showLoader());

    const response = yield call(AuthRequests.resetPassword, action.payload);

    if (response.is_successful) {
      Notify({ message: "Please, check your mail!", type: "success" });
    } else {
      Notify({ message: getErrorMessage(response.error), type: "error" });
      throw new Error();
    }
  } catch (error: any) {
  } finally {
    yield put(hideLoader());
  }
}

function* checkResetPassword(action: any): any {
  try {
    yield put(showLoader());

    const response = yield call(
      AuthRequests.checkResetPassword,
      action.payload
    );

    console.log("response", response);

    yield put(actions.setIsResetTokenValid(response.is_token_valid));
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

function* setNewPassword(action: any): any {
  try {
    yield put(showLoader());

    const response = yield call(AuthRequests.setNewPassword, action.payload);

    if (response.is_password_changed) {
      Notify({
        message: "You have successfully changed your password!",
        type: "success",
      });
      history.push("/auth?type=sign-in");
    } else {
      throw new Error(DEFAULT_ERROR_MESSAGE);
    }
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

function* signUpViaSocial(action: any): any {
  try {
    yield put(showLoader());

    const res = yield call(AuthRequests.signUpViaSocial, action.payload);

    if (res.status === 200) {
      putAuthTokenInCookie(res.data.data.access_token);

      yield call(getUser);

      if (process.env.REACT_APP_GOOGLE_TAG_MANAGER) {
        dataLayer.push({
          event: "sign_in",
          var_uid: res.data.data.id,
        });
      }

      // Notify({ message: 'You successfully log in!', type: 'success' })

      history.push(action.payload.from ? action.payload.from : "/");
    } else {
      goToAuthError();
    }
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

function* getGEOIP(action: any): any {
  try {
    yield put(showLoader());

    const res = yield call(AuthRequests.getGEOIP);

    if (res.status === 200) {
      putAuthTokenInCookie(res.data.data.access_token);

      yield call(getUser);

      Notify({ message: "You successfully log in!", type: "success" });

      history.push(action.payload.from ? action.payload.from : "/");
    } else {
      goToAuthError();
    }
  } catch (error: any) {
    Notify({ message: getErrorMessage(error), type: "error" });
  } finally {
    yield put(hideLoader());
  }
}

export function* authSaga() {
  yield takeLatest(actions.signIn, signIn);
  yield takeLatest(actions.signUp, signUp);
  yield takeLatest(actions.signOut, signOut);
  yield takeLatest(actions.resetPassword, resetPassword);
  yield takeLatest(actions.checkResetPassword, checkResetPassword);
  yield takeLatest(actions.setNewPassword, setNewPassword);
  yield takeLatest(actions.signUpViaSocial, signUpViaSocial);
  yield takeLatest(actions.getGEOIP, getGEOIP);
}
