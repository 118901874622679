import {
  getStore,
  setStore,
} from "../../../integrationWithVue/handleBrowserStore";

export const putAuthTokenInCookie = (token: string): void => {
  // console.log("=putAuthTokenInCookie=", token);

  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  setStore(token, "token");
  // {
  //   path: '/',
  //   expires: tomorrow
  // })

  // console.log('===');
  // console.log('=getStore=', getStore('token'));
};
