import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FIELD_IS_REQUIRED } from "../../../../../../constants";
import { ENTER_VALID_EMAIL } from "../../../../data/constants";

export const validationSchema = yupResolver(
  yup.object({
    email: yup.string().email(ENTER_VALID_EMAIL).required(FIELD_IS_REQUIRED),
    password: yup.string().required(FIELD_IS_REQUIRED),
  }),
);
