import React from "react";

import { useNavigate } from "react-router";

import { BasePage } from "../../../../components/BasePage";
import { BaseZeroscreen } from "../../../../components/BaseZeroscreen";

import ErrorImage from "../../../../assets/images/man_error.svg";

export const AuthError: React.FC = () => {
  const html = document.getElementsByTagName("html");
  html[0].setAttribute("dir", "ltr");

  const navigate = useNavigate();

  return (
    <BasePage>
      <BaseZeroscreen
        img={ErrorImage}
        title="Authorisation Error"
        btnLabel="Go to authorisation"
        onClick={() => navigate("/auth")}
      />
    </BasePage>
  );
};
