import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../data/selectors";
import { verifyProfile } from "../../data/actions";
import { HintType } from "../../interfaces";
import { CardWrapper } from "../CardWrapper";
import { EditedInput } from "../EditedInput";
import { InfoRow } from "../InfoRow";

type Props = {
  className?: string;
  onClose(): void;
  onOpenSidebar(type: string): void;
};

const ContactInformation: React.FC<Props> = (props) => {
  const user = useSelector(selectUser);
  const isEmailVerified = user.is_email_verified;
  const isPhoneConfirmed = user.is_phone_verified;
  const dispatch = useDispatch();

  const getEmailHint = (): HintType => {
    if (isEmailVerified) {
      return {
        message: "Email verified",
        type: "success",
      };
    } else {
      return {
        message: "Email not verified",
        type: "error",
      };
    }
  };

  const getPhoneHint = (): HintType => {
    if (isPhoneConfirmed) {
      return {
        message: "Phone confirmed",
        type: "success",
      };
    } else {
      return {
        message: "Phone not confirmed",
        type: "error",
      };
    }
  };

  return (
    <CardWrapper className={props.className} title="Contact information">
      <InfoRow
        label="Email"
        inputField={
          <EditedInput
            value={user.email}
            fieldName="email"
            type="email"
            hint={getEmailHint()}
          />
        }
        onConfirm={() => dispatch(verifyProfile("email"))}
        isConfirmBtnDisabled={isEmailVerified}
        withActionBtn
      />

      <InfoRow
        label="Phone"
        inputField={
          <EditedInput
            value={user.phone}
            fieldName="phone"
            type="tel"
            hint={getPhoneHint()}
          />
        }
        onConfirm={() => {
          dispatch(verifyProfile("phone_sms"));
          props.onOpenSidebar("phone");
        }}
        isConfirmBtnDisabled={isPhoneConfirmed}
        withoutBorder
        withActionBtn
      />
    </CardWrapper>
  );
};

export default ContactInformation;
