import { styled } from "@mui/system";

import { BaseIcon } from "../../BaseIcon";

import brandColors from "../../../css/brandColors.scss";

export const StyledList = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  alignItems: "center",
  minHeight: "auto",
});

export const StyledListItem = styled("li")({
  padding: "0 12px",
  fontWeight: "600",
  cursor: "pointer",
});

type StyledActiveListItemProps = {
  selected: boolean;
};

export const StyledActiveListItem = styled("li")<StyledActiveListItemProps>(
  ({ selected }) => ({
    fontWeight: "600",
    color: selected ? brandColors.error : "inherit",
  }),
);

export const StyledLeftArrow = styled(BaseIcon)({});

export const StyledRightArrow = styled(BaseIcon)({
  transform: "rotate(180deg)",
});
