import * as React from "react";

export const Rocket: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#284492"
      fillRule="evenodd"
      d="M20.226 3.772a11.14 11.14 0 0 0-8.744 5.21l-.004.007-.087.138-1.478 2.956 2.039 2.04a19.873 19.873 0 0 0 3.1-1.597c4.07-2.733 5.023-6.337 5.174-8.754ZM14.98 14.317c-.793.45-1.615.849-2.46 1.194v3.145a9.42 9.42 0 0 0 .505-.167c.8-.29 1.512-.684 1.814-1.136.154-.232.257-.57.298-1.005.04-.43.014-.9-.044-1.351a10.004 10.004 0 0 0-.113-.68Zm-3.21 5.299.134.738a.75.75 0 0 1-.884-.738V15.31l-2.33-2.33H4.385a.75.75 0 0 1-.738-.884l.738.134-.738-.134v-.004l.002-.009.006-.028.02-.102a11.474 11.474 0 0 1 .426-1.49c.298-.822.826-1.956 1.713-2.549.517-.345 1.13-.49 1.7-.544a7.764 7.764 0 0 1 1.681.05 12.007 12.007 0 0 1 1.351.257A12.64 12.64 0 0 1 21.005 2.25a.75.75 0 0 1 .745.75c0 2.579-.726 7.079-5.424 10.467l.004.018c.083.335.183.801.25 1.32a7.77 7.77 0 0 1 .05 1.682c-.054.57-.2 1.182-.544 1.699-.594.887-1.728 1.415-2.55 1.713a11.447 11.447 0 0 1-1.591.447l-.029.005-.008.002h-.005l-.134-.737ZM9.702 9.025a10 10 0 0 0-.699-.116 6.281 6.281 0 0 0-1.35-.044c-.436.04-.774.143-1.006.297-.451.303-.846 1.015-1.136 1.814-.064.176-.12.347-.167.505h3.148c.35-.844.754-1.665 1.21-2.456ZM4.363 15.81a2.763 2.763 0 0 1 3.687.115l.01.008c1.028 1.016 1.018 2.652.128 3.705-.386.459-.925.803-1.455 1.062-.54.264-1.126.465-1.655.617a15.858 15.858 0 0 1-1.931.42l-.034.004-.01.001H3.1l-.1-.742-.743-.1v-.005l.002-.009.004-.034.019-.12a15.831 15.831 0 0 1 .401-1.81c.151-.53.353-1.115.617-1.655.26-.532.603-1.07 1.063-1.457ZM3 21.001l-.743-.1a.75.75 0 0 0 .843.843L3 21Zm.94-.94c.223-.05.47-.112.725-.185.48-.138.975-.31 1.41-.523.442-.216.771-.449.966-.681l.002-.002c.42-.497.393-1.238-.033-1.664a1.262 1.262 0 0 0-1.68-.047h-.001c-.233.195-.465.524-.682.967-.212.434-.385.93-.522 1.41-.073.255-.135.501-.186.725Z"
      clipRule="evenodd"
    />
  </svg>
);
