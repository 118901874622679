export const submitToGoogleAnalyticsGotoPayment = (shipment: any, res: any) => {
  const outgoingData = {
    event: "begin_checkout",
    var_step: "Go to the payment",
    ecommerce: {
      items: [
        {
          item_category: !shipment.is_customs_declarable
            ? "Letters"
            : shipment.is_pallet
              ? "Pallets"
              : "Parcels",
          item_id:
            `${shipment.chosen_rate.provider.name}_${shipment.chosen_rate.service_level.name}`
              .toLocaleLowerCase()
              .replace(/ /g, "_"),
          item_name: shipment.chosen_rate.service_level.name,
          item_brand: shipment.chosen_rate.provider.name,
          item_variant: shipment.chosen_rate.service_level.name,
          price: res.payment.total_amount,
        },
      ],
    },
  };
  // console.log(outgoingData);

  dataLayer.push(outgoingData);
};
