import { createAction } from "redux-actions";
import { REDUCER_NAME } from "./constants";

export const updateUser = createAction(`${REDUCER_NAME}/UPDATE_USER`);

export const updateProfile = createAction(`${REDUCER_NAME}/UPDATE_PROFILE`);

export const verifyProfile = createAction(`${REDUCER_NAME}/VERIFY_PROFILE`);

export const confirmVerifyProfile = createAction(
  `${REDUCER_NAME}/CONFIRM_VERIFY_PROFILE`,
);

export const updatePassword = createAction(`${REDUCER_NAME}/UPDATE_PASSWORD`);

export const showLoader = createAction(`${REDUCER_NAME}/SHOW_LOADER`);

export const hideLoader = createAction(`${REDUCER_NAME}/HIDE_LOADER`);
