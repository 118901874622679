import * as React from "react";

export const Swap: React.FC = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.8,12c-0.2,0-0.4-0.2-0.3-0.4l0.1-2c0.1-2.9,2.8-5.2,6.2-5.2h1.2c0-0.5,0.1-1,0.1-1.5L12,2.1
      c0-0.5,0.6-0.7,1.1-0.5c2,1.1,3.7,2.5,5,4.1l0.4,0.5c0.1,0.1,0.1,0.3,0,0.5l-0.4,0.5c-1.4,1.6-3.1,3-5,4.1c-0.4,0.2-0.8,0.1-1-0.2
      C12,11,12,11,12,10.9l-0.1-0.8c-0.1-0.6-0.1-1.2-0.1-1.8l-0.3,0C9.3,8,7.2,8.9,6.2,10.5l-0.9,1.3c0,0.1-0.1,0.1-0.2,0.1
      C5,12,4.9,12,4.8,12z M19.2,12c0.2,0,0.4,0.2,0.3,0.4l-0.1,2c-0.1,2.9-2.8,5.2-6.2,5.2h-1.2c0,0.5-0.1,1-0.1,1.5L12,21.9
      c0,0.5-0.6,0.7-1.1,0.5c-2-1.1-3.7-2.5-5-4.1l-0.4-0.5c-0.1-0.1-0.1-0.3,0-0.5l0.4-0.5c1.4-1.6,3.1-3,5-4.1c0.4-0.2,0.8-0.1,1,0.2
      C12,13,12,13,12,13.1l0.1,0.8c0.1,0.6,0.1,1.2,0.1,1.8l0.3,0c2.1,0.3,4.3-0.6,5.3-2.2l0.9-1.3c0-0.1,0.1-0.1,0.2-0.1
      C19,12,19.1,12,19.2,12z"
    />
  </svg>
);
