import React from "react";
import styled from "styled-components";
import brandColors from "../../css/brandColors.scss";

interface ErrorMessageProps {
  error: string;
  isAddressSearchType: boolean;
}

const StyledMessage = styled("p")`
  color: ${brandColors.error};
  position: absolute;
  bottom: -22px;
  padding-left: 18px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;

  @media (max-width: 1248px) {
    position: static;
    bottom: auto;
    margin-top: 5px;
  }
`;

export const ErrorMessage = ({
  error,
  isAddressSearchType,
}: ErrorMessageProps) => {
  return (
    <StyledMessage>
      {error ||
        `Please select ${isAddressSearchType ? "an address" : "a city"} from the list`}
    </StyledMessage>
  );
};
