import { IconButton } from "@mui/material";
import { BaseIcon, Name } from "../../../../../../../components/BaseIcon";
import { StyledTooltip } from "../../../../../../../components/TooltipBase.styled";

export const HSCodeTooltip = () => {
  return (
    <StyledTooltip title="Harmonized System (HS) Code is a standard numerical method of classifying products for import and export. If you don’t have it - click the magnifier to search for the right code. For a smoother and faster customs process we recommend that you invest the time to find the right code for your product(s).">
      <IconButton style={{ color: "#757575", cursor: "default" }} disableRipple>
        <BaseIcon name={Name.Info} size={32} />
      </IconButton>
    </StyledTooltip>
  );
};
