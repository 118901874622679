import styles from "./TopPriceCard.scss";
import LocationIcon from "../../../../../../assets/icons/tabler_map-pin.svg";
import PrinterIcon from "../../../../../../assets/icons/tabler_printer.svg";
import SmartHomeIcon from "../../../../../../assets/icons/tabler_smart-home.svg";

interface TopPriceCardProps {
  fullWidth: boolean;
}

const items = [
  {
    icon: LocationIcon,
    label: (
      <div>
        Fully
        <br />
        tracked
      </div>
    ),
  },
  {
    icon: SmartHomeIcon,
    label: (
      <div>
        Collection
        <br />
        service
      </div>
    ),
  },
  {
    icon: PrinterIcon,
    label: (
      <div>
        Printer
        <br />
        required
      </div>
    ),
  },
];

export const TopPriceCard = ({ fullWidth }: TopPriceCardProps) => {
  let rootClasses = styles["top-price-card"];
  fullWidth && (rootClasses += ` ${styles["top-price-card--full-width"]}`);

  return (
    <div className={rootClasses}>
      {items.map((item, index) => (
        <div className={styles["top-price-card__item"]} key={index}>
          {index > 0 && (
            <div className={styles["top-price-card__item__separator"]} />
          )}

          <div className={styles["top-price-card__item__content"]}>
            <img
              className={styles["top-price-card__item__content__icon"]}
              src={item.icon}
              alt="icon"
            />
            {item.label}
          </div>
        </div>
      ))}
    </div>
  );
};
