export const submitToGoogleAnalyticsOnSubmitStep = ({
  shipment,
  isCompany,
  event,
  var_step,
  var_billing,
  var_invoice,
}: any) => {
  const outgoingData: any = {
    event,
    ecommerce: {
      items: [
        {
          item_category: !shipment.is_customs_declarable
            ? "Letters"
            : shipment.is_pallet
              ? "Pallets"
              : "Parcels",
          item_id:
            `${shipment.chosen_rate.provider.name}_${shipment.chosen_rate.service_level.name}`
              .toLocaleLowerCase()
              .replace(/ /g, "_"),
          item_name: shipment.chosen_rate.service_level.name,
          item_brand: shipment.chosen_rate.provider.name,
          item_variant: shipment.chosen_rate.service_level.name,
          price: shipment.chosen_rate.amount,
        },
      ],
    },
  };

  var_step !== undefined && (outgoingData.var_step = var_step);
  var_invoice !== undefined && (outgoingData.var_invoice = var_invoice);
  var_billing !== undefined && (outgoingData.var_billing = var_billing);
  isCompany !== undefined &&
    (outgoingData.var_type = isCompany ? "Company" : "Private");
  // console.log(outgoingData);

  dataLayer.push(outgoingData);
};
