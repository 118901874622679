import { useDispatch } from "react-redux";
import { signOut } from "../scenes/auth/data/actions";
import { forgetPersonInfobip } from "../plugins/infobip/infobip";

export const Logout = () => {
  // console.log('=Logout=');
  const dispatch = useDispatch();

  dispatch(signOut());

  process.env.REACT_APP_INFOBIP && forgetPersonInfobip();

  return <></>;
};
