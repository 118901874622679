import * as React from "react";

export const LeftSmallArrow: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#284492"
      d="M9.19 12.72a.813.813 0 0 1-.19-.53v-.38a.835.835 0 0 1 .19-.53l4.452-5.132A.406.406 0 0 1 13.95 6c.115 0 .226.053.307.148l.615.71a.53.53 0 0 1 .127.35.53.53 0 0 1-.127.35L11.018 12l3.854 4.442a.541.541 0 0 1 .128.355.542.542 0 0 1-.128.355l-.615.7a.406.406 0 0 1-.307.148.406.406 0 0 1-.308-.148L9.191 12.72Z"
    />
  </svg>
);
