import { styled } from "@mui/system";

export const StyledRoot = styled("div")(
  ({ isFileSelected }: any) => `
  border: 1px dashed;
  border-color: ${isFileSelected ? "transparent" : "#A8B6D2"};
  border-radius: 8px;
  width: fit-content;
  height: 100%;
  background: ${isFileSelected ? "#F4F8FF" : "none"};
  display: flex;
  mxs-height: 71px;
  align-items: center;
  padding: 16px 16px 16px 8px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`,
);

export const StyledSelectedText = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  word-break: break-all;
  margin-left: 6px;
`;

export const StyledText = styled("div")`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #00000080;
  margin-left: 6px;
  padding-right: 24px;
`;
