import { styled } from "@mui/system";
import { BaseNewSelect } from "../BaseNewSelect";
import brandColors from "../../css/brandColors.scss";
import { Breakpoints, media } from "../../css/breakpoints";
import variablesParcels from "./_Parcels.variables.scss";

export const StyledRoot = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-width: 215px;

  @media ${media(Breakpoints.Tablet)} {
    flex-direction: column;
    border-radius: 10px;
    width: 100%;
  }
`;

export const StyledNumber = styled("div")`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  left: 0;
  top: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 0;
  color: rgba(0, 0, 0, 0.44);
  background-color: ${brandColors["lightGray-100"]};
  padding: 5px;
  border-radius: 9999px;
  min-width: 16px;
  min-height: 16px;

  @media ${media(Breakpoints.Mobile, Breakpoints.Tablet)} {
    margin-right: 0;
    width: 20px;
    height: 20px;
  }

  @media ${media(Breakpoints.Mobile)} {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const StyledWrapper = styled("div")`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  gap: 6px;
  margin-top: 36px;
  flex-wrap: wrap;

  @media (min-width: ${variablesParcels.mediaMinWidth}) {
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 22px 16px;
    width: 100%;
  }
`;
export const StyledEnvelopeSizeSelect = styled(BaseNewSelect)`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  font-size: 14px;
  line-height: 19px;
  height: 40px;
  background: #ffffff;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  margin-top: auto;
  margin-bottom: 6px;
  width: 100%;

  & > * {
    & > * {
      padding-left: 10px !important;
    }
  }

  @media (min-width: ${variablesParcels.mediaMinWidth}) {
    margin-bottom: initial;
    flex: auto;
  }
`;

export const StyledSizeAndWeightSeparator = styled("div")`
  margin-top: 44px;
  padding: 0 10px;
  color: #000000;
`;
