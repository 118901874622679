import * as React from "react";

export const EUR: React.FC = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enable-background="new 0 0 1000 1000"
    xmlSpace="preserve"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
      <path d="M5957.5,5038.7c-607.9-56-1150.3-210.4-1717.7-488.3c-546.2-268.3-939.9-555.8-1372.2-999.7c-393.7-405.3-650.4-772-897.5-1275.7c-73.3-148.6-166-366.7-204.6-480.6l-73.3-208.4h-521.1c-457.4,0-528.8-3.9-588.7-34.7c-61.8-30.9-79.1-61.8-225.8-409.2c-88.8-206.5-169.8-409.2-181.4-447.8c-42.5-133.2-56-131.2,665.8-131.2h638.8V128.8v-434.2h-467.1c-457.4,0-467.1,0-515.3-44.4c-32.8-27-110-189.2-223.9-459.4C179.1-1038.8,100-1235.7,100-1243.4c1.9-73.3,13.5-75.3,812.5-84.9l773.9-9.7l75.3-212.3c102.3-291.4,351.2-783.6,536.5-1061.5c482.5-727.6,1161.9-1322,1955.1-1708c1663.7-812.5,3655.4-621.5,5133.8,496c92.6,69.5,175.6,140.9,183.3,158.3c7.7,17.4,17.4,349.3,21.2,741.1c5.8,652.3,3.9,710.2-27,721.8c-23.2,7.7-56-15.5-119.7-92.7c-154.4-183.3-528.8-546.2-692.9-669.7c-1569.1-1185-3757.7-1015.2-5120.3,393.7c-206.5,214.2-308.8,341.6-478.6,602.2c-98.4,150.5-332,592.5-332,629.2c0,5.8,1125.2,13.5,2499.3,15.5c2470.4,5.8,2499.3,5.8,2534.1,44.4c54,56,416.9,936.1,395.7,957.3c-9.6,9.6-1225.5,19.3-2852.5,21.2l-2837.1,5.8l-15.4,204.6c-7.7,113.9-7.7,305,0,424.6l15.4,220l3034,5.8l3035.9,3.9l27,44.4c56,84.9,399.5,924.5,389.8,951.5c-9.6,23.2-482.5,27-3116.9,27H2823.2l83,179.5c179.5,387.9,426.5,737.3,754.6,1067.3c139,139,322.3,306.9,409.2,372.5c1181.2,885.9,2729,1034.5,4051,387.9c428.5-210.4,754.6-453.5,1115.6-831.8c129.3-137,231.6-229.7,241.2-218.1c21.2,23.2,401.4,930.3,418.8,999.7c11.6,50.2,0,67.5-148.6,212.3C8804.3,4670.1,7337.5,5166.1,5957.5,5038.7z" />
    </g>
  </svg>
);
