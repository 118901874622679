import { styled } from "@mui/system";
import { TransitionGroup } from "react-transition-group";
import { Breakpoints, media } from "../../../../../../css/breakpoints";
import brandColors from "../../../../../../css/brandColors.scss";

export const StyledTransitionGroup = styled(TransitionGroup)`
  display: flex;
  flex-direction: column;
  gap: calc(33px - 2 * 6px);
  margin-top: 22px;

  @media ${media(Breakpoints.Mobile)} {
    margin-top: 28px;
    gap: 16px;
  }
`;

const colorBg = "#dce0e9";

export const StyledPackageItemRow = styled("li")<{ disabled: boolean }>`
  width: 100%;
  background: ${({ disabled }) => (disabled ? colorBg : "transparent")};
  border-radius: 10px;
  padding: 6px;

  @media ${media(Breakpoints.Mobile)} {
    background: ${({ disabled }) =>
      disabled ? colorBg : brandColors.lightestBlue};
    padding: 10px 10px 30px 10px;
  }
`;
