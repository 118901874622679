import * as React from "react";

export const Info: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 3.667a8.333 8.333 0 1 0 0 16.667 8.333 8.333 0 0 0 0-16.667Zm0 15a6.667 6.667 0 1 1 0-13.334 6.667 6.667 0 0 1 0 13.334Z" />
    <path d="M12 9.5a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667ZM12 10.333a.833.833 0 0 0-.833.834v4.166a.833.833 0 0 0 1.667 0v-4.166a.833.833 0 0 0-.834-.834Z" />
  </svg>
);
