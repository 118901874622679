import { format } from "date-fns";

export const getDefaultFormValues = (data: any): any => ({
  pickup_details: {
    ready_time: data?.ready_time || new Date(Date.now()),
    close_time: data?.close_time,
  },
  time: {
    ready_time: data?.ready_time
      ? format(new Date(data?.ready_time), "HH:mm:ss")
      : format(new Date(Date.now()), "HH:mm:ss"),
    close_time:
      data?.close_time && format(new Date(data?.close_time), "HH:mm:ss"),
  },
});
