import * as React from "react";

export const Pallet: React.FC = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.1193 9.34712L9.32741 5.68203L10.0635 4.31797L16.8554 7.98305L16.1193 9.34712Z"
      fill="#FF5C5C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.6643 13.3098L24.4323 15.2341C25.1648 15.6082 25.1805 16.6494 24.4596 17.0454L14.2241 22.6678C13.6972 22.9572 13.0596 22.9603 12.53 22.676L2.72371 17.4117C1.8178 16.9254 1.83055 15.6219 2.74581 15.1534L6.34688 13.3101L7.05313 14.6899L3.91897 16.2942L13.2631 21.3103C13.3302 21.3464 13.4111 21.346 13.4778 21.3093L22.8449 16.1639L19.9593 14.6902L20.6643 13.3098Z"
      fill="#284492"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.27501 14.9902V6.75H5.72501V15.4472C5.72501 15.7292 5.87815 15.9889 6.12489 16.1254L12.9999 19.9282C13.2355 20.0585 13.5219 20.0572 13.7563 19.9247L20.6313 16.0389C20.8746 15.9014 21.025 15.6436 21.025 15.3642V6.75H19.475V14.912L13.3709 18.3621L7.27501 14.9902Z"
      fill="#284492"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0177 2.5623C13.2394 2.44705 13.5032 2.44584 13.726 2.55905L20.601 6.05181C20.8574 6.18206 21.0206 6.44355 21.0249 6.73109C21.0292 7.01862 20.874 7.28491 20.6217 7.42282L13.7467 11.1801C13.5172 11.3055 13.24 11.3067 13.0095 11.1834L6.13446 7.50584C5.88092 7.37021 5.7233 7.10536 5.72502 6.81783C5.72674 6.5303 5.88751 6.26735 6.14266 6.13477L13.0177 2.5623ZM13.379 4.12131L8.16167 6.8324L13.3711 9.61897L18.5882 6.76777L13.379 4.12131Z"
      fill="#284492"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.725 19.25L12.725 10.25L14.275 10.25L14.275 19.25L12.725 19.25Z"
      fill="#284492"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0627 2.99207C11.944 2.5461 11.4443 2.32313 11.0331 2.53261L3.16433 6.54099C2.68621 6.78454 2.42225 7.31091 2.51302 7.83976L3.93411 16.1189L2.40645 16.3811L0.985364 8.10198C0.779731 6.90398 1.37769 5.71159 2.46078 5.15986L10.3295 1.15149C11.6199 0.494146 13.1879 1.19381 13.5605 2.59324L13.7489 3.30058L12.2511 3.69942L12.0627 2.99207Z"
      fill="#284492"
    />
    <path
      d="M15 23.25C15 24.2165 14.2165 25 13.25 25C12.2835 25 11.5 24.2165 11.5 23.25C11.5 22.2835 12.2835 21.5 13.25 21.5C14.2165 21.5 15 22.2835 15 23.25Z"
      fill="#284492"
    />
    <path
      d="M25 18.25C25 19.2165 24.2165 20 23.25 20C22.2835 20 21.5 19.2165 21.5 18.25C21.5 17.2835 22.2835 16.5 23.25 16.5C24.2165 16.5 25 17.2835 25 18.25Z"
      fill="#284492"
    />
    <path
      d="M5.5 18.25C5.5 19.2165 4.7165 20 3.75 20C2.7835 20 2 19.2165 2 18.25C2 17.2835 2.7835 16.5 3.75 16.5C4.7165 16.5 5.5 17.2835 5.5 18.25Z"
      fill="#284492"
    />
  </svg>
);
