import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FIELD_IS_REQUIRED } from "../../../../../../constants";
import {
  getMinValidationMessage,
  getMaxValidationMessage,
} from "../../../../../../services/validation";
import { ENTER_VALID_EMAIL } from "../../../../../auth/data/constants";

export const validationSchema = yupResolver(
  yup.object({
    full_name: yup
      .string()
      .required(FIELD_IS_REQUIRED)
      .min(1, getMinValidationMessage(1))
      .max(50, getMaxValidationMessage(50)),
    phone: yup.number().required(FIELD_IS_REQUIRED),
    email: yup.string().email(ENTER_VALID_EMAIL).required(FIELD_IS_REQUIRED),
    company: yup.string(),
    text: yup.string().required(FIELD_IS_REQUIRED),
  }),
);
