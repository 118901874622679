import { Controller, useFormContext } from "react-hook-form";
import { BaseNewSelect } from "../../BaseNewSelect";

const HFSelect = (props: any) => {
  const { control }: any = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        // <BaseInput onChange={onChange} value={value} />
        <BaseNewSelect
          {...props}
          onChange={(value: any) => {
            onChange(value.value);
            props.onChange && props.onChange(value.value);
          }}
          value={value}
        />
      )}
    />
  );
};

export default HFSelect;
