import { useState } from "react";

import { styled } from "@mui/system";

import { BaseInput } from "../../../../components/BaseInput";
import { BaseIcon, Name } from "../../../../components/BaseIcon";

import brandColors from "../../../../css/brandColors.scss";

import styles from "./styles/EditedInput.scss";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../data/actions";
import { HintType } from "../../interfaces";
import { Box, ClickAwayListener } from "@mui/material";
import { Breakpoints, media } from "../../../../css/breakpoints";

const StyledValueWrapper = styled("div")`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${brandColors.mainBlue};
  display: flex;
  align-items: flex-start;
`;

const StyledValue = styled("div")`
  margin-right: 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type HintProps = {
  type: string;
};

const StyledHint = styled("div")<HintProps>(
  ({ type }) => `
  color: ${type === "success" ? "#4AB6BD" : "#FF5C5C"};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`,
);

const StyledBox = styled(Box)`
  @media ${media(Breakpoints.Mobile)} {
    width: 100%;
  }
`;

type Props = {
  value: string;
  type?: string;
  hint?: HintType;
  fieldName: string;
  onEditIconClick?(): void;
};

const EditedInput: React.FC<Props> = (props) => {
  const [editActive, setEditActive] = useState(false);
  const [inputValue, setInputValue] = useState(props.value);
  const dispatch = useDispatch();
  // const loading = useSelector(selectIsLoading)

  const handleSuffixClick = (): void => {
    dispatch(
      updateProfile({
        [props.fieldName]: inputValue,
      }),
    );

    hideInput();
  };

  const hideInput = (): void => {
    setEditActive(false);
  };

  const handleEditIconClick = (): void => {
    if (props.onEditIconClick) {
      props.onEditIconClick();
    } else {
      setEditActive(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => hideInput()}>
      <StyledBox>
        {editActive ? (
          <BaseInput
            value={inputValue}
            onChange={(e: any) => setInputValue(e.target.value)}
            className={styles.input}
            rightIcon={Name.Check}
            onSuffixClick={handleSuffixClick}
            type={props.type}
          />
        ) : (
          <StyledValueWrapper>
            <div>
              <StyledValue>{props.value}</StyledValue>

              {props.hint && (
                <StyledHint type={props.hint.type}>
                  {props.hint.message}
                </StyledHint>
              )}
            </div>

            <BaseIcon
              className={styles.pencilIcon}
              onClick={handleEditIconClick}
              name={Name.Pencil}
              isButton
            />
          </StyledValueWrapper>
        )}
      </StyledBox>
    </ClickAwayListener>
  );
};

export default EditedInput;
