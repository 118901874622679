import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { HF } from "../";
import { BaseButton } from "../BaseButton";
import { selectLocale, selectUser, selectRtl } from "../../data/selectors";

import styles from "./FormGetBestPricePopup.scss";
import { FormProvider, useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import API from "../../api";
import { validationSchemaFormGetBestPricePopup } from "./validationSchemaFormGetBestPricePopup";
import { getErrorMessage } from "../../services/getErrorMessage";
import { Notify } from "../../services/notifications";
import { parcelTypes } from "../../scenes/home/data/constants";
import { UnitSystem } from "../../services/convertUnitSystemValues";
import { eventInfobip } from "../../plugins/infobip/infobip";

const translations: any = {
  title: {
    en: "Get the Best Price!",
    he: "קבלו את המחיר הטוב ביותר!",
    tr: "Get the Best Price!",
  },
  subTitle: {
    en: "Even with delivery challenges between Turkey and Israel, we've found the safest and most affordable shipping method for you.",
    he: "למרות אתגרי המשלוח בין טורקיה וישראל ,מצאנו את השיטה הבטוחה והמשתלמת ביותר לשלוח את החבילות שלכם.",
    tr: "Even with delivery challenges between Turkey and Israel, we've found the safest and most affordable shipping method for you.",
  },
  input: {
    name: {
      placeholder: {
        en: "Name",
        he: "שם",
        tr: "Name",
      },
    },
    phone: {
      placeholder: {
        en: "Phone",
        he: "טלפון",
        tr: "Phone",
      },
    },
    email: {
      placeholder: {
        en: "Email",
        he: "אימייל",
        tr: "Email",
      },
    },
  },
  btn: {
    en: "Get Quote",
    he: "לקבל הצעה",
    tr: "Get Quote",
  },
  footer: {
    en: "Contact Our Team for Exclusive Shipping Discounts!",
    he: "צרו קשר עם הצוות שלנו להנחות בלעדיות!",
    tr: "Contact Our Team for Exclusive Shipping Discounts!",
  },
};

export const FormGetBestPricePopup = (props: any) => {
  // console.log("=FormGetBestPricePopup=", props);

  const locale = useSelector(selectLocale);
  const rtl = useSelector(selectRtl);
  const user = useSelector(selectUser);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const methods = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: user?.first_name,
      phone: user?.phone,
      email: user?.email,
    },
    resolver: validationSchemaFormGetBestPricePopup,
  });

  const onSubmit = useCallback(async () => {
    process.env.REACT_APP_INFOBIP && eventInfobip("GetQuoteTurkey", null);

    if (process.env.REACT_APP_IS_MOBILE_APP === "false" && !executeRecaptcha)
      return;

    setIsLoadingSubmit(true);

    let recaptchaToken = "fd64efe83df80c7f7b15a3976849a2e2"; // Сделано на время, пока не будет реализована КАПЧА в мобильном приложении

    try {
      if (process.env.REACT_APP_IS_MOBILE_APP === "false") {
        recaptchaToken = executeRecaptcha
          ? await executeRecaptcha("ShipperContactForm")
          : "";
      }
    } catch (error) {
      console.log("=executeRecaptcha ERROR=", error);
      Notify({ message: getErrorMessage(error), type: "error" });
    }

    if (!recaptchaToken) return;

    let content: any = Object.entries(methods.getValues())
      .filter(([key, value]) => value)
      .map(([key, value]) => [key, key === "phone" ? `${value}` : value])
      .map(([key, value]) => `${key} - ${value}`);

    content.push(`From country - ${props.shipment.locationFrom?.country}`);
    content.push(`To country - ${props.shipment.locationTo?.country}`);
    content.push(
      `Type - ${
        props.shipment?.is_customs_declarable ||
        props.shipment?.is_customs_declarable === undefined
          ? props.shipment?.is_pallet
            ? parcelTypes[2].value
            : parcelTypes[1].value
          : parcelTypes[0].value
      }`
    );

    const unit_of_measurement = props.shipment.unit_of_measurement;

    const dimensionSuffix =
      unit_of_measurement.toString() === UnitSystem.METRIC ||
      props.unitSystem.toString() === "metric"
        ? " cm"
        : " inch";

    const weightSuffix =
      unit_of_measurement.toString() === UnitSystem.METRIC ||
      props.unitSystem.toString() === "metric"
        ? " kg"
        : " lb";

    props.shipment.packages.forEach((item: any, index: number) => {
      content.push(
        `Package #${index + 1}: ${item.width}x${item.length}x${item.height}${dimensionSuffix} ${item.weight}${weightSuffix};<br></br>`
      );
    });

    content = content.join(" </br> ");

    console.log("=content=", content);

    //         name - 2 </br> phone - +19102222222 </br> email - expromt192@yandex.ru

    //         Name: <name>;
    //           Phone: <phone>; Email: <email><br>
    // From country: <country name>;<br>
    // To country: <country name>;<br>
    // Type: Package|Palette|Letter;<br>
    // Package #1: <width>x<length>x<height><cm|in> <width><cm|in>;<br></br>

    try {
      const res = await API.post(
        `${process.env.REACT_APP_EMAIL_SENDER_ENDPOINT}/emailsender.php`,
        {
          subject: "Get the Best Price form",
          "g-recaptcha-response": recaptchaToken,
          content,
        }
      );
      if (res.data.success) {
        props.onSuccess();
      }
      // if (res.data.success) {
      //   Notify({ message: 'Message sent!', type: 'success' })

      //   // TODO: Возможно есть более простой способ сбросить форму без передачи значений по-умолчанию
      //   // TODO: сброс телефона к +1 тоже нужно пересмотреть
      //   methods.reset({
      //     name: '',
      //     phone: '',
      //     email: '',
      //   })
      // } else {
      //   Notify({ message: res.data.message, type: 'error' })
      // }
    } catch (error: any) {
      Notify({ message: getErrorMessage(error), type: "error" });
    } finally {
      setIsLoadingSubmit(false);
    }
  }, [executeRecaptcha]);

  return (
    <FormProvider {...methods}>
      <div className={styles.content}>
        <div className={styles.title}>{translations.title[locale]}</div>

        <div className={styles.subTitle}>{translations.subTitle[locale]}</div>

        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
          <HF.Input
            className={styles.input}
            name="name"
            placeholder={translations.input.name.placeholder[locale]}
            withoutBorder
            onBlur={() => {
              process.env.REACT_APP_INFOBIP &&
                eventInfobip("GetQuoteTurkeyName", {
                  value: methods.getValues("name"),
                });
            }}
          />

          <HF.Input
            className={styles.input}
            name="phone"
            placeholder={translations.input.phone.placeholder[locale]}
            withoutBorder
            onBlur={() => {
              process.env.REACT_APP_INFOBIP &&
                eventInfobip("GetQuoteTurkeyPhone", {
                  value: methods.getValues("phone"),
                });
            }}
          />

          {/* <HF.PhoneInput
            className={styles.input}
            name="phone"
            label="Phone number"
          /> */}
          <HF.Input
            className={styles.input}
            name="email"
            placeholder={translations.input.email.placeholder[locale]}
            type="email"
            onBlur={() => {
              process.env.REACT_APP_INFOBIP &&
                eventInfobip("GetQuoteTurkeyEmail", {
                  value: methods.getValues("email"),
                });
            }}
          />

          <BaseButton
            className={
              styles.submitBtn + " " + (rtl ? styles.submitBtnRtl : "")
            }
            loading={isLoadingSubmit}
            type="submit"
          >
            {translations.btn[locale]}
          </BaseButton>

          <div className={styles.footer}>{translations.footer[locale]}</div>
        </form>
      </div>
    </FormProvider>
  );
};
