import MetricSystemBtnsStyles from "./ToggleBtn.scss";
// import { BaseIcon, Name } from './BaseIcon';

interface ToggleBtnProps {
  className?: string;
  options: any;
  selected: boolean;
  onChange: any;
}

export const ToggleBtn = ({
  className = "",
  selected,
  options,
  onChange,
}: ToggleBtnProps) => {
  return (
    <div className={`${MetricSystemBtnsStyles.MetricSystemBtns} ${className}`}>
      {options.map((item: any) => (
        <button
          className={`${MetricSystemBtnsStyles.MetricSystemBtnsBtn} ${selected === item.value ? MetricSystemBtnsStyles.MetricSystemBtnsBtnActive : ""}`}
          key={item.label}
          type="button"
          onClick={() => onChange(item.value)}
        >
          {/* {
            selected === item.value &&
              <BaseIcon
                className={MetricSystemBtnsStyles.MetricSystemBtnsBtnIcon}
                name={Name.CheckMarkElongated}
                size={18}
              />
          } */}

          <span className={MetricSystemBtnsStyles.MetricSystemBtnsBtnLabel}>
            {item.label}
          </span>
        </button>
      ))}
    </div>
  );
};
